













import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { FileToUpload } from './models'
import { SystemFile } from '../../index.types'
import NoImageDisplay from './NoImageDisplay.vue'
@Component({
  components: { NoImageDisplay }
})
export default class OptionImageDisplay extends Vue {
  @Prop()
  private readonly screenerId!: number

  @Prop()
  private readonly pageId!: number

  @Prop()
  private readonly image?: SystemFile | FileToUpload

  @Prop()
  private readonly width?: number

  @Prop()
  private readonly height?: number

  get fileType (): string {
    if (!this.image) {
      return ''
    }
    if ('file' in this.image) {
      return this.image.file.type
    } else {
      return this.image.mimeType
    }
  }

  get isImage () {
    return /image\/*/.test(this.fileType)
  }

  get url () {
    if (this.image?.url) {
      return this.image.url
    }
    return this.$store.getters['screeners/getQuestionFilePath']({ screenerId: this.screenerId, pageId: this.pageId }, this.image)
  }
}
