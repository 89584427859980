























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { v4 as uuidv4 } from 'uuid'

import { DragComponent, DraggableListChangeEvent } from '../../Draggable'
import Button from '../../Button/Button.vue'
import { ToolboxItem, ScreenerBuilderErrors } from '../layout-editor.types'
import { ScreenerQuestion } from '../models'
import ScreenerQuestionComponent from '../ScreenerQuestionComponent.vue'

type QuestionSetItem = ScreenerQuestion | ToolboxItem

@Component({
  components: { Button, DragComponent, ScreenerQuestionComponent }
})
export default class ScreenerBuilderPageComponent extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly questions!: ScreenerQuestion[]

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  @Prop({ type: Boolean, default: false })
  readonly terminatingOverview!: boolean

  @Prop({ type: Object, default: () => ({}) })
  readonly errors!: ScreenerBuilderErrors

  get internalQuestions (): QuestionSetItem[] {
    return this.questions.sort((a, b) => a.order - b.order)
  }

  set internalQuestions (value: QuestionSetItem[]) {
    if (value.some(i => !!i.isToolboxItem)) {
      return
    }
    this.$emit('update:questions', value)
  }

  edit (question: ScreenerQuestion) {
    this.$emit('selectQuestion', question)
  }

  questionsChanged (data: DraggableListChangeEvent<ToolboxItem>) {
    if (data.added) {
      this.questionAdded(data)
    }
  }

  questionAdded (data: DraggableListChangeEvent<ToolboxItem>) {
    if (data.added) {
      const newIndex = data.added?.newIndex || 0
      const question: ScreenerQuestion = {
        id: 0,
        uuid: uuidv4(),
        order: newIndex + 1,
        text: '',
        type: data.added.element.type,
        options: [],
        pathingFlows: [],
        terminations: [],
        randomiseOptions: false,
        isToolboxItem: false
      }

      const currentItem = this.internalQuestions[newIndex]
      const deleteCount = currentItem?.isToolboxItem ? 1 : 0

      const questions = [...this.internalQuestions]
      questions.splice(newIndex, deleteCount, question)
      this.internalQuestions = questions
    }
  }

  updateQuestion (question: ScreenerQuestion) {
    const questions = [...this.internalQuestions]
    const index = this.internalQuestions.findIndex(i => !i.isToolboxItem && i.id === question.id)
    questions.splice(index, 1, question)
    this.internalQuestions = questions
  }
}
