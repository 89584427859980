



import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import TemplateEditor from '../TemplateEditor/TemplateEditor.vue'

@Component({
  components: { TemplateEditor }
})
export default class EmailTemplateEditor extends Vue {
  @Prop()
  private readonly value!: string

  @Prop({ type: String })
  private readonly type?: string

  isUsingVariable = false

  buttonUrl = ''
}
