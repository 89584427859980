



import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import { TerminationItemParamError } from './termination-item.types'

@Component
export default class BaseTerminationItem extends Vue {
  public validate (): TerminationItemParamError {
    return { row: true, option: true, operator: true, value: true }
  }

  protected buildParams (params: Partial<TerminationItemParamError>): TerminationItemParamError {
    return {
      row: params.row || false,
      option: params.option || false,
      operator: params.operator || false,
      value: params.operator || false
    }
  }
}
