import { Extension, RawCommands, Command } from '@tiptap/core'
import '@tiptap/extension-text-style'

declare module '@tiptap/core' {
  interface Commands {
    color: {
      /**
       * Set the font color
       */
       setColor: (color: string) => Command;
      /**
       * Unset the font color
       */
       unsetColor: () => Command;
    };
  }
}

type FontColorOptions = {
  types: string[];
}

export const FontColor = Extension.create<FontColorOptions>({
  name: 'color',

  defaultOptions: {
    types: ['textStyle']
  },

  addGlobalAttributes () {
    return [
      {
        types: this.options.types,
        attributes: {
          color: {
            default: null,
            renderHTML: attributes => {
              if (!attributes.color) {
                return {}
              }

              return {
                style: `color: ${attributes.color}`
              }
            },
            parseHTML: element => ({
              color: element.style.color.replace(/['"]+/g, '')
            })
          }
        }
      }
    ]
  },

  addCommands () {
    const commands: Partial<RawCommands> = {
      setColor: color => ({ chain }) => {
        return chain()
          .setMark('textStyle', { color })
          .run()
      },
      unsetColor: () => ({ chain }) => {
        return chain()
          .setMark('textStyle', { color: null })
          .removeEmptyTextStyle()
          .run()
      }
    }

    return commands
  }
})
