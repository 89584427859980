



import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import Modal from '../Modal/Modal.vue'
import { SystemFile } from '../../index.types'

@Component({
  components: { Modal }
})
export default class FileRemoveConfirmModal extends Vue {
  show = false

  file: SystemFile | null = null

  get text () {
    return `Are you sure you want to remove ${this.file?.name || 'the file'}?`
  }

  open (file: SystemFile) {
    this.file = file
    this.show = true
  }

  close () {
    this.show = false
    this.file = null
  }

  remove () {
    this.$emit('confirm', this.file)
    this.close()
  }
}
