















import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import { FormFieldError, SystemFile } from '../../../index.types'
import { getInputName } from '../layout-editor.utilities'
import { AnswerManager } from '../answer-manager'
import { ScreenerQuestionAnswer, ScreenerQuestionType, ExternalLink } from '../models'
import BaseInput from './BaseInput.vue'
import { ScreenerBuilderQuestionErrors } from '../layout-editor.types'
import { deepClone, valueUnchanged } from '../../../utilities/helpers'

@Component({
  components: { BaseInput }
})
export default class RatingInput extends Vue {
  @Prop()
  readonly order!: number

  @Prop()
  readonly text!: string

  @Prop({ type: Array, default: () => [] })
  private readonly files!: SystemFile[]

  @Prop({ type: Array, default: () => [] })
  private readonly links!: ExternalLink[]

  @Prop({ type: Array, default: () => [] })
  readonly answers!: ScreenerQuestionAnswer[]

  @Prop({ type: Object, default: null })
  readonly error!: FormFieldError

  @Prop({ type: Object, default: () => ({}) })
  private readonly builderError!: ScreenerBuilderQuestionErrors

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean

  @Prop({ type: Boolean, default: false })
  readonly editorView!: boolean

  @Prop({ type: Boolean, default: false })
  readonly terminatingOverview!: boolean

  @Prop({ type: Map, default: () => new Map() })
  readonly terminationMap!: Map<string, number>

  get errorMessages () {
    if (!this.error) {
      return []
    }
    return [this.error?.message]
  }

  get inputName () {
    return getInputName(ScreenerQuestionType.RATING)
  }

  get colour () {
    if (this.error) {
      return 'error'
    } else {
      return 'primary'
    }
  }

  get backgroundColour () {
    if (this.error) {
      return 'error'
    } else {
      return 'grey'
    }
  }

  internalValue: number | null = null

  @Watch('answers', { immediate: true })
  private answersChanged (value: ScreenerQuestionAnswer[]) {
    const answer = value[0]
    this.internalValue = answer ? +answer.value : null
  }

  @Watch('internalValue')
  private internalValueChanged (value: number) {
    let answers = deepClone(this.answers)

    if (answers.length) {
      answers = [{ ...answers[0], value: value.toString() }]
    } else {
      answers = [{ value: value.toString() }]
    }

    if (!valueUnchanged(answers, this.answers)) {
      this.$emit('answer', answers)
    }
  }

  isAnswered () {
    return AnswerManager.isRatingAnswered(this.answers)
  }
}
