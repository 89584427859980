
















import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import { Card } from '../Card'
import Button from '../Button/Button.vue'

@Component({
  components: { Card, Button }
})
export default class BaseModal extends Vue {
  @Prop({ default: false })
  private readonly value!: boolean

  @Prop({ default: null })
  private readonly title!: string

  @Prop({ type: Boolean, default: false })
  private readonly filled!: boolean

  @Prop({ default: 'primary' })
  private readonly headerColor!: string

  @Prop()
  private readonly backgroundColor?: string

  @Prop({ type: Boolean, default: false })
  private readonly noClose!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly small!: boolean

  @Prop({ type: Boolean, default: true })
  private readonly persistent!: boolean

  @Prop({ type: Number, default: 0 })
  private readonly maxWidth!: number

  @Prop({ type: String })
  private readonly height?: string

  opened = false

  get calculatedMaxWidth () {
    return this.maxWidth || (!this.small ? 950 : 500)
  }

  private get cardStyles () {
    if (this.height) {
      return `height: ${this.height}; overflow-y: auto;`
    }

    return ''
  }

  @Watch('value', { immediate: true })
  private valueChanged (value: boolean) {
    this.opened = value
  }

  @Watch('opened')
  private modalVisibilityUpdated (value: boolean) {
    this.$emit('input', value)
  }

  get foregroundColor () {
    if (!this.filled) {
      return null
    }
    switch (this.headerColor) {
      case 'primary':
      case 'theme-blue':
      case 'secondary':
        return 'white'
      default:
        return null
    }
  }
}
