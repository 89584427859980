import { render, staticRenderFns } from "./ListBody.vue?vue&type=template&id=b84835c2&scoped=true&"
import script from "./ListBody.vue?vue&type=script&lang=ts&"
export * from "./ListBody.vue?vue&type=script&lang=ts&"
import style0 from "./ListBody.vue?vue&type=style&index=0&id=b84835c2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b84835c2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBanner } from 'vuetify/lib/components/VBanner';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataFooter } from 'vuetify/lib/components/VDataIterator';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBadge,VBanner,VBtn,VCheckbox,VDataFooter,VDataTable,VIcon,VList,VListItem,VListItemContent,VListItemTitle,VMenu,VProgressLinear,VSelect,VSpacer})
