








import Vue from 'vue'
import Component from 'vue-class-component'
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-2'

@Component({
  components: {
    NodeViewContent,
    NodeViewWrapper
  },
  props: nodeViewProps
})
export default class EmailButton extends Vue {}
