



import { Component, Prop } from 'vue-property-decorator'

import {
  ScreenerQuestion,
  ScreenerQuestionLoopAnswerType,
  ScreenerQuestionOption,
  ScreenerQuestionOptionType,
  ScreenerQuestionTermination,
  ScreenerQuestionType,
  TerminationOperator
} from '../../../models'

import { TerminationItemError, TerminationItemParamError } from './termination-item.types'
import BaseTerminationItem from './BaseItem.vue'
import MatrixItem from './MatrixItem.vue'
import OneDimMultiSelectItem from './OneDimMultiSelectItem.vue'
import OneDimSingleSelectItem from './OneDimSingleSelectItem.vue'
import OptionAndRawItem from './OptionAndRawItem.vue'
import RawItem from './RawItem.vue'

@Component
export default class LoopTerminationItem extends BaseTerminationItem {
  @Prop()
  private readonly question!: ScreenerQuestion

  @Prop()
  private readonly parent!: ScreenerQuestion

  @Prop()
  private readonly value!: ScreenerQuestionTermination

  @Prop({ type: Array, default: () => [] })
  private readonly operators!: { text: string; value: TerminationOperator }[]

  @Prop({ type: Object, required: true })
  private readonly error!: TerminationItemError

  @Prop({ type: Boolean, default: false })
  private readonly locked!: boolean

  private get parentOptions (): ScreenerQuestionOption[] {
    return this.parent.options
  }

  private get questionOptions (): ScreenerQuestionOption[] {
    if (this.question.loopAnswerType === ScreenerQuestionLoopAnswerType.QUESTIONS) {
      return this.question.options
    }
    return this.parentOptions
  }

  private get rows (): ScreenerQuestionOption[] {
    if (this.question.loopAnswerType === ScreenerQuestionLoopAnswerType.OPTIONS) {
      return this.parentOptions
    }
    return this.questionOptions.filter(i => i.type === ScreenerQuestionOptionType.ROW)
  }

  private get columns (): ScreenerQuestionOption[] {
    return this.question.options.filter(i => i.type === ScreenerQuestionOptionType.COLUMN)
  }

  get itemComponentType (): typeof BaseTerminationItem {
    switch (this.question.loopType) {
      case ScreenerQuestionType.MATRIX_MULTI:
      case ScreenerQuestionType.MATRIX:
      case ScreenerQuestionType.MATRIX_DYNAMIC:
        return MatrixItem
      case ScreenerQuestionType.CHECKBOX:
      case ScreenerQuestionType.IMAGE:
        return OneDimMultiSelectItem
      case ScreenerQuestionType.RADIO:
      case ScreenerQuestionType.SELECT:
      case ScreenerQuestionType.IMAGE_SINGLE:
        return OneDimSingleSelectItem
      case ScreenerQuestionType.RANKING:
        return OptionAndRawItem
      case ScreenerQuestionType.TEXTAREA:
      case ScreenerQuestionType.TEXTFIELD:
      case ScreenerQuestionType.RATING:
        return RawItem
      default:
        return BaseTerminationItem
    }
  }

  get itemComponent (): BaseTerminationItem {
    return this.$refs.itemComponent as BaseTerminationItem
  }

  public validate (): TerminationItemParamError {
    return this.itemComponent.validate()
  }
}
