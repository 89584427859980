
























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class NotFoundComponent extends Vue {
  @Prop({ type: Boolean, default: false })
  private readonly value!: boolean

  @Prop({ type: String, default: 'Not Found' })
  private readonly title!: string

  @Prop({ type: String, default: 'The resource you were looking for could not be found.' })
  private readonly text!: string

  @Prop({ type: String, default: 'dashboard' })
  private readonly routeName!: string

  @Prop({ type: String, default: 'Return to Dashboard' })
  private readonly returnText!: string

  @Prop({ type: Boolean, default: false })
  private readonly noRouting!: boolean

  get dashboard () {
    return { name: this.routeName }
  }
}
