


































import { FormFieldError, PathParams } from '@focus/components'
import Vue from 'vue'
import { v4 as uuid } from 'uuid'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class Autocomplete<T> extends Vue {
  @Prop()
  readonly value!: string | T

  @Prop({ required: true })
  readonly searchAction!: string

  @Prop({ default: null })
  readonly searchParams!: PathParams

  @Prop()
  readonly label!: string

  @Prop()
  readonly color!: string

  @Prop({ default: 'text' })
  readonly itemText!: keyof T | ((item: T) => string)

  @Prop({ default: 'id' })
  readonly itemValue!: keyof T

  @Prop({ type: Boolean, default: () => false })
  readonly returnObject!: boolean

  @Prop({ type: Boolean, default: () => false })
  readonly hideDetails!: boolean

  @Prop({ type: Boolean, default: false })
  readonly fetching!: boolean

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  @Prop({ type: Boolean, default: false })
  readonly clearable!: boolean

  @Prop({ type: Number, default: 1 })
  readonly tabIndex!: number

  @Prop({ type: Boolean, default: false })
  readonly required!: boolean

  @Prop({ type: Array, default: () => [] })
  readonly rules!: ((val: string) => string | true)[]

  @Prop({ type: Object, default: null })
  readonly error!: FormFieldError

  search: string | null = null

  searchDebounce = 0

  searching = false

  items: T[] = []

  get fieldId () {
    return uuid()
  }

  get fieldRules () {
    if (this.required) {
      return [this.requiredRule, ...this.rules]
    }

    return this.rules
  }

  get errorMessages () {
    if (!this.error) {
      return []
    }
    return [this.error?.message]
  }

  @Watch('search')
  private searchChanged (searchValue: string) {
    clearTimeout(this.searchDebounce)
    if (!searchValue && this.search !== '') {
      return
    }
    this.searching = true
    this.searchDebounce = window.setTimeout(async () => {
      const results = await this.$store.dispatch(this.searchAction, { searchTerm: searchValue.toLowerCase(), params: this.searchParams })
      this.items = results
      this.searching = false
    }, 300)
  }

  setValue (val: T) {
    this.items = [...this.items, val]
    if (this.returnObject) {
      this.updateValue(val)
    } else {
      const value = val[this.itemValue] as unknown as string | number
      this.updateValue(value)
    }
  }

  private requiredRule (val: string) {
    return (val !== null && val !== undefined) || 'Field is required'
  }

  private updateValue (value: string | number | T) {
    this.$emit('input', value)
  }

  private displayValue (item: T) {
    if (typeof this.itemText === 'function') {
      return this.itemText(item)
    } else {
      return item[this.itemText]
    }
  }

  public addItem (item: T) {
    this.items.push(item)
  }
}
