



























import Vue from 'vue'
import { Prop, Component, Watch } from 'vue-property-decorator'

import ListHeader from './ListHeader/ListHeader.vue'
import ListBody from './ListBody/ListBody.vue'
import BadgeKey from './BadgeKey.vue'
import { Column, ListCustomiseOptions, Favourite, ListAction, ListExportData, StoredListFilter, operators, dateOperators, StoredListSettings, BulkListAction, ListBadge } from './List.types'
import { Card } from '../Card'
import { PathParams } from '../../utilities/api.utilities'
import { deepClone } from '../../utilities/helpers'
import { ApiError } from '../../utilities/base.api.utilities'
import { getDisplayValueFromStored } from './List.utilities'

@Component({
  components: { BadgeKey, Card, ListHeader, ListBody }
})
export default class ListComponent<T> extends Vue {
  @Prop({ default: () => [] })
  readonly headers!: Column<T>[]

  @Prop({ default: () => [] })
  readonly badges!: ListBadge<T>[]

  @Prop({ type: String, default: null })
  private readonly colourKey?: keyof T

  @Prop({ default: () => null })
  readonly title!: string

  @Prop({ required: true })
  readonly resourceName!: string

  @Prop({ default: () => [] })
  readonly actions!: ListAction<T>[]

  @Prop({ default: () => [] })
  readonly bulkActions!: BulkListAction<T>[]

  @Prop({ type: Boolean, default: false })
  readonly noUpdate!: boolean

  @Prop({ type: Boolean, default: false })
  readonly noFavourites!: boolean

  @Prop({ type: Boolean, default: false })
  readonly noHeader!: boolean

  @Prop({ type: Array, default: () => [] })
  readonly defaultFilters!: StoredListFilter<T>[]

  @Prop({ type: Object, default: () => ({}) })
  readonly pathParams?: PathParams

  @Prop({ type: Boolean, default: false })
  hideBadgeKey!: boolean

  favourites: Favourite<T>[] = []

  selectedFavourite: Favourite<T> | null = null

  customiseOptions: Partial<ListCustomiseOptions<T>> = {}

  get items (): T[] {
    return this.$store.getters[`${this.resourceName}/activeListItems`]
  }

  get total (): number {
    return this.$store.state[`${this.resourceName}`].listOptions.total
  }

  loading = false

  get activeHeaders () {
    if (!this.customiseOptions.selectedColumns) {
      return []
    }
    return this.headers.filter(header => this.customiseOptions.selectedColumns?.includes(header.value))
  }

  get listBody () {
    return this.$refs.listBody as ListBody<T>
  }

  @Watch('customiseOptions', { deep: true, immediate: false })
  async customiseOptionsChanged (newValue: ListCustomiseOptions<T>) {
    const data = this.convertToStoredSettings(newValue)

    await this.$store.dispatch(`${this.resourceName}/updateListOptions`, data)

    if (newValue.favouriteName) {
      try {
        const favourite: Favourite<T> = await this.$store.dispatch(`${this.resourceName}/saveFavourite`, { favourite: deepClone(data), params: this.pathParams })
        this.favourites.push(favourite)
        this.selectedFavourite = deepClone(favourite)
      } catch (error) {
        if (error instanceof ApiError) {
          await this.$store.dispatch('showError', error)
        } else {
          throw error
        }
      }
    }

    if (!this.noUpdate || !this.items.length) {
      this.updateListData()
    }
  }

  @Watch('defaultFilters', { deep: true })
  async defaultFiltersChanged (newValue: StoredListFilter<T>[]) {
    await this.fetchItems(newValue)
  }

  created () {
    if (!this.$store.hasModule(this.resourceName)) {
      throw new Error(`Module ${this.resourceName} has not been registered.`)
    }
  }

  async mounted () {
    this.initListOptions()
    this.$store.dispatch(`${this.resourceName}/clearList`)
  }

  initListOptions () {
    const settings: StoredListSettings<T> = this.$store.getters[`${this.resourceName}/listOptions`]
    if (settings) {
      this.setCustomSettings(settings)
    } else {
      this.setDefaultSettings()
    }

    if (!this.noFavourites && !this.noHeader) {
      this.getFavourites()
    }
  }

  async getFavourites () {
    try {
      this.favourites = await this.$store.dispatch(`${this.resourceName}/getFavourites`, { name: this.resourceName, params: this.pathParams })
    } catch (error) {
      if (error instanceof ApiError) {
        await this.$store.dispatch('showError', error)
      } else {
        throw error
      }
    }
  }

  setDefaultSettings () {
    this.customiseOptions = {
      selectedColumns: this.headers.filter(i => i.initiallyVisible).map(i => i.value),
      filters: [],
      pagination: {
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
        sortDesc: []
      }
    }
  }

  setCustomSettings (settings: StoredListSettings<T>) {
    this.customiseOptions = {
      selectedColumns: settings.selectedColumns,
      filters: settings.filters.map(filter => {
        const field: Column<T> | undefined = this.headers.find(header => header.value === filter.field)
        const operatorSet = field?.type === 'date' ? dateOperators : operators
        return {
          field,
          operator: operatorSet.find(o => o.value === filter.operator),
          value: getDisplayValueFromStored(filter),
          rawValue: filter.value
        }
      }),
      pagination: settings.pagination
    }
  }

  async refresh () {
    this.$emit('onRefresh')
    await this.updateListData()
  }

  resetOptions () {
    this.setDefaultSettings()
  }

  private convertToStoredSettings (value: ListCustomiseOptions<T>) {
    const data: StoredListSettings<T> = {
      name: value.favouriteName,
      filters: value.filters.map(i => ({ field: i.field?.value, operator: i.operator?.value, value: i.rawValue })),
      selectedColumns: deepClone(value.selectedColumns),
      pagination: {
        itemsPerPage: value.pagination.itemsPerPage,
        page: value.pagination.page,
        sortBy: deepClone(value.pagination.sortBy || []),
        sortDesc: deepClone(value.pagination.sortDesc || [])
      }
    }
    return data
  }

  async updateListData () {
    this.loading = true
    this.listBody.clearSelected()
    await this.fetchItems()
    this.loading = false
  }

  public clearSelected () {
    this.listBody.clearSelected()
  }

  async fetchItems (filters?: StoredListFilter<T>[]) {
    try {
      await this.$store.dispatch(`${this.resourceName}/getMany`, { filters: filters || this.defaultFilters, params: this.pathParams })
    } catch (error) {
      if (error instanceof ApiError) {
        await this.$store.dispatch('showError', error)
      } else {
        throw error
      }
    }
  }

  async exportData (data: ListExportData) {
    try {
      await this.$store.dispatch(`${this.resourceName}/export`, { exportDetails: data, filters: this.defaultFilters, params: this.pathParams })
    } catch (error) {
      if (error instanceof ApiError) {
        await this.$store.dispatch('showError', error)
      } else {
        throw error
      }
    }
  }

  selectFavourite (favourite: Favourite<T>) {
    this.selectedFavourite = deepClone(favourite)
    this.setCustomSettings(favourite)
  }
}
