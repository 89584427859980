































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { v4 as uuid } from 'uuid'
import { FormFieldError } from '../../index.types'

@Component
export default class Select<T = any> extends Vue { // eslint-disable-line @typescript-eslint/no-explicit-any
  @Prop()
  value: any // eslint-disable-line

  @Prop({ default: () => [] })
  readonly items!: T[] // eslint-disable-line

  @Prop()
  readonly label!: string

  @Prop()
  readonly placeholder!: string

  @Prop({ default: 'text' })
  readonly itemText!: string | ((item: T) => string)

  @Prop({ default: 'value' })
  readonly itemValue!: string | ((item: T) => string)

  @Prop({ type: Boolean, default: () => false })
  readonly returnObject!: boolean

  @Prop({ type: Boolean, default: () => false })
  readonly hideDetails!: boolean

  @Prop({ type: Boolean, default: false })
  readonly fetching!: boolean

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  @Prop({ type: Boolean, default: false })
  readonly solo!: boolean

  @Prop({ type: Boolean, default: false })
  readonly clearable!: boolean

  @Prop({ type: Number, default: 1 })
  readonly tabIndex!: number

  @Prop({ type: Boolean, default: false })
  readonly required!: boolean

  @Prop({ type: Boolean, default: false })
  readonly multiple!: boolean

  @Prop({ type: Object, default: null })
  readonly error!: FormFieldError

  @Prop({ type: Array, default: () => [] })
  readonly rules!: ((val: string) => string | true)[]

  selectValue: any = null // eslint-disable-line

  get fieldId () {
    return uuid()
  }

  get fieldRules () {
    if (this.required) {
      return [this.requiredRule, ...this.rules]
    }

    return this.rules
  }

  get errorMessages () {
    if (!this.error) {
      return []
    }
    return [this.error?.message]
  }

  @Watch('value', { immediate: true })
  valueChanged (value: any) { // eslint-disable-line
    this.selectValue = value
  }

  @Watch('selectValue')
  selectValueChanged (value: any) { // eslint-disable-line
    this.$emit('input', value)
  }

  private requiredRule (val: string) {
    return (val !== null && val !== undefined) || 'Field is required'
  }
}
