export const FILE_SIZE_UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

export const FILE_UNIT_FACTOR = 1024

export const getHumanFileSize = (bytes: number, significantDigits = 0): string => {
  if (bytes === 0) {
    return '0B'
  }

  const factorIndex = Math.floor(Math.log(bytes) / Math.log(FILE_UNIT_FACTOR))
  const selectedUnit = FILE_SIZE_UNITS[factorIndex]
  const sizeInSelectedUnit = bytes / (Math.pow(FILE_UNIT_FACTOR, factorIndex))

  return sizeInSelectedUnit.toFixed(significantDigits) + selectedUnit
}

export function downloadFile (path: string) {
  const anchorElement = document.createElement('a')
  anchorElement.href = path
  anchorElement.setAttribute('target', '_blank')
  anchorElement.click()
}
