



import { ListFilter } from '../List.types'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { getDisplayValue } from '../List.utilities'

@Component
export default class FilterDisplay<T> extends Vue {
  @Prop({ required: true })
  readonly filter!: ListFilter<T>

  displayValue (filter: ListFilter<T>) {
    return getDisplayValue(filter)
  }

  get fieldText () {
    return this.filter.field?.text
  }

  get operatorText () {
    return this.filter.operator?.text
  }

  removeFilter () {
    this.$emit('onRemove')
  }
}
