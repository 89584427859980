























































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import { FormFieldError, SystemFile } from '../../../index.types'
import BaseInput from './BaseInput.vue'
import { getInputName } from '../layout-editor.utilities'
import { AnswerManager } from '../answer-manager'
import { ScreenerQuestionType, ScreenerQuestionOption, ScreenerQuestionAnswer, ExternalLink } from '../models'
import { MultiMatrixInputDataMap, ScreenerBuilderQuestionErrors } from '../layout-editor.types'
import { valueUnchanged, deepClone } from '../../../utilities/helpers'
import { overviewTextClass } from './views.constants'

@Component({
  components: { BaseInput }
})
export default class MatrixInput<T> extends Vue {
  @Prop()
  readonly order!: number

  @Prop()
  readonly text!: string

  @Prop({ type: Array, default: () => [] })
  private readonly files!: SystemFile[]

  @Prop({ type: Array, default: () => [] })
  private readonly links!: ExternalLink[]

  @Prop({ type: Array, default: () => [] })
  readonly rows!: ScreenerQuestionOption[]

  @Prop({ type: Array, default: () => [] })
  readonly columns!: ScreenerQuestionOption[]

  @Prop({ type: Array, default: () => [] })
  readonly answers!: ScreenerQuestionAnswer[]

  @Prop({ type: Object, default: null })
  readonly error!: FormFieldError

  @Prop({ type: Object, default: () => ({}) })
  private readonly builderError!: ScreenerBuilderQuestionErrors

  @Prop({ type: Boolean, default: false })
  readonly footer!: boolean

  @Prop({ type: Boolean, default: false })
  readonly clearable!: boolean

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean

  @Prop({ type: Boolean, default: false })
  readonly editorView!: boolean

  @Prop({ type: Boolean, default: false })
  readonly terminatingOverview!: boolean

  @Prop({ type: Map, default: () => new Map() })
  readonly terminationMap!: Map<string, number>

  get lockEdit () {
    return this.readonly || this.editorView
  }

  get overviewTextClass () {
    return overviewTextClass
  }

  get errorMessages () {
    if (!this.error) {
      return []
    }
    return [this.error?.message]
  }

  get inputName () {
    return getInputName(ScreenerQuestionType.MATRIX_MULTI)
  }

  get columnCount () {
    if (this.clearable) {
      return this.columns.length + 2
    }
    return this.columns.length + 1
  }

  get dataLoaded () {
    return Object.keys(this.rowValueMap).length > 0
  }

  get checkboxValueMap (): MultiMatrixInputDataMap {
    return Object.keys(this.rowValueMap).reduce((obj, rKey) => {
      const rowKey = +rKey || 0
      return {
        ...obj,
        [rowKey]: Object.keys(this.rowValueMap[rowKey]).reduce((row: object, cKey) => {
          const colKey = +cKey || 0
          return {
            ...row,
            [colKey]: this.rowValueMap[rowKey][colKey]
          }
        }, {})
      }
    }, {})
  }

  rowValueMap: MultiMatrixInputDataMap = {}

  @Watch('answers', { immediate: true })
  private answersChanged (value: ScreenerQuestionAnswer[]) {
    this.rebuildValueMap(value)
  }

  @Watch('rowValueMap', { deep: true })
  private rowValueMapChanged (value: MultiMatrixInputDataMap, oldValue: MultiMatrixInputDataMap) {
    if (valueUnchanged(value, oldValue)) {
      return
    }

    if (this.editorView) {
      return
    }

    const answers = deepClone(this.answers)
    for (const rowKey in value) {
      for (const colKey in value[rowKey]) {
        const answerIndex = answers.findIndex(i => i.optionId === +colKey && i.rowId === +rowKey)
        if (answerIndex > -1) {
          answers.splice(answerIndex, 1, { ...answers[answerIndex], value: value[rowKey][colKey] })
        } else {
          if (value[rowKey][colKey] !== null) {
            answers.push({
              optionId: +colKey,
              rowId: +rowKey,
              value: value[rowKey][colKey]
            })
          }
        }
      }
    }
    this.$emit('answer', answers)
  }

  @Watch('rows')
  private rowsChanged (value: ScreenerQuestionOption[], oldValue: ScreenerQuestionOption[]) {
    if (!valueUnchanged(value, oldValue)) {
      this.rebuildValueMap()
    }
  }

  @Watch('columns')
  private columnsChanged (value: ScreenerQuestionOption[], oldValue: ScreenerQuestionOption[]) {
    if (!valueUnchanged(value, oldValue)) {
      this.rebuildValueMap()
    }
  }

  created () {
    this.rebuildValueMap()
  }

  optionTicked (rowId: number, columnId: number, value: 'true' | 'false') {
    this.rowValueMap = {
      ...this.rowValueMap,
      [rowId]: {
        ...this.rowValueMap[rowId],
        [columnId]: value
      }
    }
  }

  rebuildValueMap (value?: ScreenerQuestionAnswer[]) {
    const answers = value || this.answers || []
    this.rowValueMap = this.rows.reduce((map, row) => {
      return {
        ...map,
        [row.id || 0]: {
          ...this.columns.reduce((obj, column) => {
            const answer = answers.find(i => i.optionId === column.id && i.rowId === row.id)
            const value = answer ? answer.value : null
            return {
              ...obj,
              [column.id || 0]: value
            }
          }, {})
        }
      }
    }, {})
  }

  isAnswered () {
    return AnswerManager.isMatrixAnswered(this.rows, this.answers)
  }

  terminationCount (rowId: number, columnId: number) {
    return this.terminationMap.get(`${rowId}-${columnId}`) || 0
  }
}
