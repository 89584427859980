import { ActionContext } from 'vuex'
import { RootState } from '@/store/store.types'

import axios from 'axios'
import { ProfileStoreState, RespondentProfile } from './profile.types'
import { BaseApiResourceModule } from '@focus/components'
import { RespondentProfileData } from '../auth'

class ProfileModule extends BaseApiResourceModule<RespondentProfile, RootState> {
  namespaced = true

  get actions () {
    return {
      'my-profile': this.getMyProfile.bind(this),
      'update-profile': this.updateMyProfile.bind(this),
      updateContact: this.updateContactDetails.bind(this),
      upgrade: this.upgradeProfile.bind(this),
      unsubscribe: this.unsubscribe.bind(this)
    }
  }

  async getMyProfile (context: ActionContext<ProfileStoreState, RootState>) {
    try {
      context.commit('setLoading', true)
      const response = await axios.get('/people/my-profile')
      context.commit('setLoading', false)
      return response.data
    } catch (error) {
      context.commit('setLoading', false)
      throw this.formatApiError(error)
    }
  }

  async updateMyProfile (context: ActionContext<ProfileStoreState, RootState>, payload: RespondentProfile) {
    try {
      context.commit('setLoading', true)
      await axios.patch('/people/my-profile', payload)
      context.commit('setLoading', false)
    } catch (error) {
      context.commit('setLoading', false)
      throw this.formatApiError(error)
    }
  }

  async updateContactDetails (context: ActionContext<ProfileStoreState, RootState>, payload: { email?: string; mobile?: string }) {
    try {
      context.commit('setLoading', true)
      await axios.patch('/people/my-profile/contact', payload)
      context.commit('setLoading', false)
    } catch (error) {
      context.commit('setLoading', false)
      throw this.formatApiError(error)
    }
  }

  async upgradeProfile (context: ActionContext<ProfileStoreState, RootState>, payload: RespondentProfileData) {
    try {
      context.commit('setLoading', true)
      await axios.post('/people/my-profile/upgrade', payload)
      await context.dispatch('auth/me', {}, { root: true })
      context.commit('setLoading', false)
    } catch (error) {
      context.commit('setLoading', false)
      throw this.formatApiError(error)
    }
  }

  async unsubscribe (context: ActionContext<ProfileStoreState, RootState>, payload: { identity: string; signature: string }) {
    try {
      await axios.post('/people/my-profile/unsubscribe', payload)
    } catch (error) {
      throw this.formatApiError(error)
    }
  }
}

export default new ProfileModule()
