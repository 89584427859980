






import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class NoImageDisplayComponent extends Vue {
  @Prop()
  readonly width?: number

  @Prop()
  readonly height?: number

  get cardStyles () {
    const styles: string[] = []

    if (this.width) {
      styles.push(`width: ${this.width}px`)
    }

    if (this.height) {
      styles.push(`height: ${this.height}px`)
    }

    return styles.join('; ')
  }
}
