




















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import Button from '../../Button/Button.vue'
import BaseModal from '../../BaseModal/BaseModal.vue'
import Select from '../../Select/Select.vue'
import OptionListModal from './OptionListModal/OptionListModal.vue'
import { RankingConfigurationOptions } from '../layout-editor.types'
import { ScreenerQuestionOption, ScreenerQuestion } from '../models'

@Component({
  components: { Button, BaseModal, OptionListModal, Select }
})
export default class RankingConfigurationOptionsComponent extends Vue {
  @Prop()
  private readonly value!: RankingConfigurationOptions

  @Prop({ type: Object, required: true })
  readonly question!: ScreenerQuestion

  @Prop({ type: Boolean, default: false })
  private readonly locked!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly disabled!: boolean

  @Prop()
  private readonly title!: string

  @Prop({ type: Boolean, default: false })
  readonly loop!: boolean

  get modalTitle () {
    return this.locked ? 'View Ranking Options' : 'Edit Ranking Options'
  }

  get optionsModal () {
    return this.$refs.optionsModal as OptionListModal
  }

  get requiredCount () {
    return this.value.requiredOptionCount
  }

  set requiredCount (value: number) {
    this.$emit('input', { ...this.value, requiredOptionCount: value })
  }

  get rankingCountOptions () {
    return [...new Array(this.question.options.length + 1).keys()].map(count => ({ text: +count || 'All', value: +count || null }))
  }

  openOptionsModal () {
    this.optionsModal.open(this.question.options)
  }

  saveOptions (options: ScreenerQuestionOption[]) {
    this.$emit('input', { ...this.value, options })
  }
}
