





































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import { FormFieldError, SystemFile } from '../../../index.types'
import { valueUnchanged, deepClone } from '../../../utilities/helpers'
import BaseInput from './BaseInput.vue'
import Select from '../../Select/Select.vue'
import { getInputName } from '../layout-editor.utilities'
import { AnswerManager } from '../answer-manager'
import { ScreenerQuestionType, ScreenerQuestionAnswer, ScreenerQuestionOption, ExternalLink } from '../models'
import { ScreenerBuilderQuestionErrors } from '../layout-editor.types'
import { overviewTextClass } from './views.constants'

@Component({
  components: { BaseInput, Select }
})
export default class RankingInput extends Vue {
  @Prop()
  private readonly order!: number

  @Prop()
  private readonly text!: string

  @Prop({ type: Array, default: () => [] })
  private readonly files!: SystemFile[]

  @Prop({ type: Array, default: () => [] })
  private readonly links!: ExternalLink[]

  @Prop({ type: Number, default: 0 })
  private readonly requiredOptionCount!: number

  @Prop()
  private readonly options!: ScreenerQuestionOption[]

  @Prop({ type: Array, default: () => [] })
  private readonly answers!: ScreenerQuestionAnswer[]

  @Prop({ type: Object, default: null })
  private readonly error!: FormFieldError

  @Prop({ type: Object, default: () => ({}) })
  private readonly builderError!: ScreenerBuilderQuestionErrors

  @Prop({ type: Boolean, default: false })
  private readonly readonly!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly editorView!: boolean

  @Prop({ type: Boolean, default: false })
  readonly terminatingOverview!: boolean

  @Prop({ type: Map, default: () => new Map() })
  readonly terminationMap!: Map<string, number>

  get overviewTextClass () {
    return overviewTextClass
  }

  private get errorMessages () {
    if (!this.error) {
      return []
    }
    return [this.error?.message]
  }

  private get inputName () {
    return getInputName(ScreenerQuestionType.RANKING)
  }

  private get rankingOptions () {
    const rankCount = this.requiredOptionCount || this.options.length
    return [...new Array(rankCount + 1).keys()]
  }

  optionRankingMap: Map<number, number> = new Map()

  rankingUsed: { [key: number]: boolean } = {}

  internalValue: ScreenerQuestionOption[] = []

  @Watch('options', { deep: true, immediate: true })
  private optionsChanged (value: ScreenerQuestionOption[], oldValue: ScreenerQuestionOption[]) {
    if (!valueUnchanged(value, oldValue)) {
      this.internalValue = deepClone(value)
    }
  }

  @Watch('answers', { deep: true, immediate: true })
  private answersChanged (value: ScreenerQuestionAnswer[], oldValue: ScreenerQuestionAnswer[]) {
    if (!valueUnchanged(value, oldValue)) {
      this.setRankingMap()
    }
  }

  setRankingMap () {
    this.optionRankingMap.clear()
    this.answers.forEach(answer => {
      if (answer.optionId && answer.value) {
        this.optionRankingMap.set(answer.optionId, +answer.value)
      }
    })
    this.$forceUpdate()
  }

  private getOptionRank (option: ScreenerQuestionOption) {
    const rank = this.optionRankingMap.get(option.id)

    if (!rank) {
      return '-'
    }

    return rank
  }

  private setOptionRank (option: ScreenerQuestionOption, rank: number) {
    let existingIds: number[] = []

    const previousRank = this.optionRankingMap.get(option.id)
    if (previousRank) {
      this.rankingUsed[previousRank] = false
    }

    if (!rank) {
      this.optionRankingMap.delete(option.id)
    } else {
      this.rankingUsed[rank] = true
      existingIds = Array.from(this.optionRankingMap.entries()).filter(([_, value]) => value === rank).map(([key]) => key) // eslint-disable-line @typescript-eslint/no-unused-vars
      existingIds.forEach(id => this.optionRankingMap.delete(id))
      this.optionRankingMap.set(option.id, rank)
    }

    const existing: ScreenerQuestionOption[] = []
    existingIds.forEach(id => {
      const option = this.options.find(option => option.id === id)
      if (option) {
        existing.push(option)
      }
    })

    this.updateAnswers(option, rank, existing)
    this.$forceUpdate()
  }

  private updateAnswers (option: ScreenerQuestionOption, rank: number, existing: ScreenerQuestionOption[]) {
    const answers = deepClone(this.answers)
    this.updateAnswer(option, rank, answers)

    if (existing.length) {
      existing.forEach(existingOption => this.updateAnswer(existingOption, 0, answers))
    }

    this.$emit('answer', answers)
  }

  private updateAnswer (option: ScreenerQuestionOption, rank: number, answers: ScreenerQuestionAnswer[]) {
    const index = answers.findIndex(a => a.optionId === option.id)

    if (index > -1) {
      answers[index] = { ...answers[index], value: (rank || '').toString() }
    } else {
      answers.push({
        optionId: option.id,
        value: (rank || '').toString()
      })
    }
  }

  private unselectedRankingOptions (option: ScreenerQuestionOption) {
    const selectedRanks = Array.from(this.optionRankingMap.values())
    return this.rankingOptions.filter(rankOption => {
      return !selectedRanks.includes(rankOption) || rankOption === this.optionRankingMap.get(option.id)
    })
  }

  isAnswered () {
    return AnswerManager.isRankingAnswered(this.answers, this.options, this.requiredOptionCount)
  }
}
