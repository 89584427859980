






























































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { v4 as uuidv4 } from 'uuid'

import Button from '../../Button/Button.vue'
import Modal from '../../Modal/Modal.vue'

import ScreenerBuilderHeader from './ScreenerBuilderHeader.vue'
import ScreenerEditorToolbox from './Toolbox/ScreenerEditorToolbox.vue'
import ScreenerBuilderPageComponent from './ScreenerBuilderPageComponent.vue'
import ScreenerQuestionConfigurationComponent from '../ScreenerQuestionConfiguration/ScreenerQuestionConfiguration.vue'
import {
  ScreenerPage,
  ScreenerQuestion,
  ScreenerQuestionOption,
  ScreenerQuestionSchema,
  ScreenerQuestionOptionSchema,
  ScreenerQuestionTermination,
  ScreenerQuestionTerminationSchema
} from '../models'
import { valueUnchanged } from '../../../utilities/helpers'
import { ScreenerBuilderErrors, ScreenerBuilderQuestionErrors } from '../layout-editor.types'
import { SystemFile } from '../../../index.types'
import { getDependantQuestions } from '../layout-editor.utilities'

@Component({
  components: { Button, Modal, ScreenerEditorToolbox, ScreenerBuilderHeader, ScreenerBuilderPageComponent, ScreenerQuestionConfigurationComponent }
})
export default class ScreenerBuilder extends Vue {
  @Prop({ type: Array, default: () => [] })
  private readonly value!: Partial<ScreenerPage>[]

  @Prop({ type: Number, default: () => 0 })
  private readonly jobId!: number

  @Prop({ type: Boolean, default: false })
  private readonly locked!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly loading!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly preview!: boolean

  private pages: Partial<ScreenerPage>[] = []

  private selectedPageIndex = 0

  private selectedQuestionIndex: number | null = null

  private showPageRemoveConfirm = false

  private errors: ScreenerBuilderErrors = {}

  private get selectedPage () {
    return this.pages[this.selectedPageIndex]
  }

  private get selectedQuestion (): ScreenerQuestion | null {
    if (this.selectedQuestionIndex === null || this.selectedQuestionIndex === undefined) {
      return null
    }

    if (this.selectedPage && this.selectedPage.questions) {
      return this.selectedPage.questions[this.selectedQuestionIndex]
    }

    return null
  }

  private get questions () {
    return this.selectedPage?.questions || []
  }

  private get toolbox () {
    return this.$refs.toolbox as ScreenerEditorToolbox
  }

  private get allQuestions () {
    return this.pages.reduce((array, page) => {
      if (page.questions) {
        return [...array, ...page.questions]
      }
      return array
    }, [] as ScreenerQuestion[])
  }

  @Watch('value', { immediate: true, deep: true })
  private valueChanged (value: Partial<ScreenerPage>[], oldValue: Partial<ScreenerPage>[]) {
    if (!valueUnchanged(value, oldValue)) {
      this.pages = [...value]
    }
  }

  @Watch('pages', { deep: true })
  private pagesUpdated (value: Partial<ScreenerPage>[]) {
    this.$emit('input', value)
  }

  @Watch('preview')
  private previewChanged (value: boolean) {
    if (value && this.selectedQuestionIndex !== null) {
      this.selectedQuestionIndex = null
    }
  }

  @Watch('selectedPageIndex')
  private pageIndexChanged () {
    this.selectedQuestionIndex = null
  }

  private updateQuestions (questions: ScreenerQuestion[]) {
    if (this.selectedPage) {
      this.selectedPage.questions = questions
      this.updateOrders()
    }
  }

  private updateOrders () {
    let order = 0
    this.pages = this.pages.map((page) => {
      return {
        ...page,
        questions: page.questions?.map((question) => {
          return {
            ...question,
            order: ++order
          }
        })
      }
    })
  }

  private selectQuestion (question: ScreenerQuestion) {
    this.selectedQuestionIndex = this.questions.findIndex(q => q === question)
  }

  private addPage () {
    this.pages.push({
      order: this.pages.length + 1,
      questions: []
    })
  }

  private removePage () {
    this.pages.splice(this.selectedPageIndex, 1)
    this.showPageRemoveConfirm = false
  }

  private removeSelectedQuestion () {
    if (this.selectedQuestionIndex === null || this.selectedQuestionIndex === undefined) {
      return
    }
    const questions = this.selectedPage?.questions || []
    const dependants = getDependantQuestions(questions[this.selectedQuestionIndex], questions)

    const identifier = this.selectedQuestion!.id || this.selectedQuestion!.uuid! // eslint-disable-line
    dependants.forEach(q => {
      const index = questions.findIndex(i => i.id === q.id && i.uuid === q.uuid)
      questions[index].pathingFlows = questions[index].pathingFlows.filter(i => i.targetQuestionId !== identifier)
    })

    questions.splice(this.selectedQuestionIndex, 1)
    this.selectedQuestionIndex = null
    this.updateOrders()
  }

  private updateQuestion (question: ScreenerQuestion) {
    if (this.selectedQuestionIndex === null || this.selectedQuestionIndex === undefined) {
      return
    }

    const questions = this.selectedPage?.questions || []
    this.$set(questions, this.selectedQuestionIndex, question)
  }

  public validate () {
    let valid = true
    this.errors = {}
    this.questions.forEach((question) => {
      if (question.order && question.text) {
        return
      }

      const errors: ScreenerBuilderQuestionErrors = {}

      if (!question.order) {
        errors.order = 'Order is required'
      }

      if (!question.text) {
        errors.text = 'Text is required'
      }

      const key = question.id || question.uuid || ''

      this.$set(this.errors, key, errors)
      valid = false
    })
    return valid
  }

  private removeFile (data: { questionId: number; optionId: number; file: SystemFile }) {
    this.$emit('removeFile', { pageId: this.selectedPage.id, questionId: data.questionId, optionId: data.optionId, file: data.file })
  }

  public getTemplateQuestions () {
    this.toolbox.getTemplateQuestions()
  }

  private addQuestionFromTemplate (schema: ScreenerQuestionSchema) {
    if (!this.selectedPage.questions) {
      return
    }
    const map = new Map<number, string>()
    const data: ScreenerQuestion = {
      id: 0,
      uuid: uuidv4(),
      code: schema.code,
      height: schema.height,
      width: schema.width,
      order: this.selectedPage.questions.length + 1,
      text: schema.text,
      type: schema.type,
      options: schema.options.map(i => this.formatTemplateOption(i, map)),
      terminations: schema.terminations.map(i => this.formatTermination(i, map)),
      pathingFlows: [],
      randomiseOptions: schema.randomiseOptions,
      downloadFiles: schema.downloadFiles.map(i => this.formatDownloadFiles(i)),
      isToolboxItem: false
    }
    this.selectedPage.questions.push(data)
  }

  private formatDownloadFiles (file: SystemFile): SystemFile {
    return {
      ...file
    }
  }

  private formatTemplateOption (option: ScreenerQuestionOptionSchema, map: Map<number, string>): ScreenerQuestionOption {
    const uuid = uuidv4()
    map.set(option.id, uuid)
    return {
      id: 0,
      questionId: 0,
      screenerId: 0,
      uuid,
      text: option.text,
      code: option.code,
      order: option.order,
      image: option.image,
      type: option.type
    }
  }

  private formatTermination (termination: ScreenerQuestionTerminationSchema, map: Map<number, string>): ScreenerQuestionTermination {
    return {
      id: 0,
      questionId: 0,
      type: termination.type,
      operator: termination.operator,
      optionId: map.get(termination.optionId || 0),
      rowId: map.get(termination.rowId || 0),
      value: termination.value
    }
  }
}
