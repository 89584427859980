




import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { v4 as uuid } from 'uuid'

import TemplateEditor from '../TemplateEditor/TemplateEditor.vue'

interface ButtonColorOption { code: string | null; name: string; font?: string }

@Component({
  components: { TemplateEditor }
})
export default class PageTemplateEditor extends Vue {
  @Prop({ type: String, default: '' })
  value!: string

  buttonData: { url: string; color?: ButtonColorOption } = { url: '' }

  private buttonColors = [
    { code: null, name: 'Default' },
    { code: '#3FBF6B', name: 'Green Theme', font: '#ffffff' },
    { code: '#4690F1', name: 'Blue Theme', font: '#ffffff' },
    { code: '#29348f', name: 'Purple', font: '#ffffff' },
    { code: '#11b4e4', name: 'Blue', font: '#ffffff' },
    { code: '#e21c90', name: 'Pink', font: '#ffffff' },
    { code: '#ffffff', name: 'White' }
  ]

  private setButtonColor (color: ButtonColorOption) {
    this.buttonData.color = color
  }

  private createButtonParams () {
    const params = { isVariable: false, url: this.buttonData.url, uuid: this.generateUuid(), color: this.buttonData.color?.code || '', textColor: this.buttonData.color?.font }
    this.buttonData = { url: '' }
    return params
  }

  private generateUuid () {
    return uuid()
  }
}
