










import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { ModalOption } from '../../OptionsModal/OptionsModal.types'
import { PageTab } from './tabs.types'

@Component
export default class HeaderTabsComponent extends Vue {
  @Prop()
  readonly value!: string

  @Prop({ type: Array, default: () => [] })
  readonly tabs!: PageTab[]

  @Prop({ default: 'Options' })
  readonly optionsTitle!: string

  @Prop({ type: Boolean, default: false })
  readonly noOptions!: boolean

  @Prop({ type: Boolean, default: false })
  readonly extraTabs!: boolean

  @Prop({ type: Array, default: () => [] })
  readonly statusOptions!: ModalOption[]

  @Prop({ type: String, default: 'Unknown' })
  readonly statusText!: string

  get optionContainer () {
    return this.$refs.optionContainer as Element
  }

  updateTab (value: number) {
    this.$emit('input', value)
  }
}
