








import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class ErrorBanner extends Vue {
  @Prop()
  readonly show!: boolean
}
