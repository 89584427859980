







import { ScreenerQuestion } from '../models'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import Modal from '../../Modal/Modal.vue'
import Form from '../../Form/Form.vue'
import TextField from '../../TextField/TextField.vue'

@Component({
  components: { Form, Modal, TextField }
})
export default class RemoveQuestionModal extends Vue {
  private question: ScreenerQuestion | null = null

  private show = false

  private name: string | null = null

  private get form () {
    return this.$refs.form as Form
  }

  private confirm () {
    if (this.form.validate()) {
      this.$emit('confirm', { name: this.name, question: this.question })
      this.close()
    }
  }

  open (question: ScreenerQuestion) {
    this.question = question
    this.show = true
  }

  close () {
    this.question = null
    this.form.reset()
    this.show = false
  }
}
