























































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import { FormFieldError, SystemFile } from '../../../index.types'
import BaseInput from './BaseInput.vue'
import { getInputName } from '../layout-editor.utilities'
import { AnswerManager } from '../answer-manager'
import { ScreenerQuestionType, ScreenerQuestionOption, ScreenerQuestionAnswer, ExternalLink } from '../models'
import { ScreenerBuilderQuestionErrors } from '../layout-editor.types'
import { deepClone, valueUnchanged } from '../../../utilities/helpers'
import { overviewTextClass } from './views.constants'

@Component({
  components: { BaseInput }
})
export default class MatrixInput<T> extends Vue {
  @Prop()
  readonly order!: number

  @Prop()
  readonly text!: string

  @Prop({ type: Array, default: () => [] })
  private readonly files!: SystemFile[]

  @Prop({ type: Array, default: () => [] })
  private readonly links!: ExternalLink[]

  @Prop({ type: Array, default: () => [] })
  readonly rows!: ScreenerQuestionOption[]

  @Prop({ type: Array, default: () => [] })
  readonly columns!: ScreenerQuestionOption[]

  @Prop({ type: Array, default: () => [] })
  readonly answers!: ScreenerQuestionAnswer[]

  @Prop({ type: Object, default: null })
  readonly error!: FormFieldError

  @Prop({ type: Object, default: () => ({}) })
  private readonly builderError!: ScreenerBuilderQuestionErrors

  @Prop({ type: Boolean, default: false })
  readonly footer!: boolean

  @Prop({ type: Boolean, default: false })
  readonly clearable!: boolean

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean

  @Prop({ type: Boolean, default: false })
  readonly editorView!: boolean

  @Prop({ type: Boolean, default: false })
  readonly terminatingOverview!: boolean

  @Prop({ type: Map, default: () => new Map() })
  readonly terminationMap!: Map<string, number>

  get overviewTextClass () {
    return overviewTextClass
  }

  get lockEdit () {
    return this.readonly || this.editorView
  }

  get errorMessages () {
    if (!this.error) {
      return []
    }
    return [this.error?.message]
  }

  get inputName () {
    return getInputName(ScreenerQuestionType.MATRIX)
  }

  get columnCount () {
    if (this.clearable) {
      return this.columns.length + 2
    }
    return this.columns.length + 1
  }

  rowValueMap: { [key: number]: string } = {}

  @Watch('answers', { immediate: true })
  private answersChanged (value: ScreenerQuestionAnswer[], oldValue: ScreenerQuestionAnswer[]) {
    if (!valueUnchanged(value, oldValue)) {
      this.rowValueMap = value.reduce((map, answer) => {
        if (answer.rowId) {
          return {
            ...map,
            [answer.rowId]: answer.value
          }
        }
        return map
      }, {})
    }
  }

  @Watch('rowValueMap', { deep: true })
  private rowValueMapChanged (value: { [key: number]: string }) {
    const answers = deepClone(this.answers)
    for (const key in value) {
      const index = answers.findIndex(i => i.rowId === +key)
      if (index === -1) {
        answers.push({
          rowId: +key,
          optionId: +value[key],
          value: value[key]
        })
      } else if (answers[index].value !== value[key]) {
        answers[index] = { ...answers[index], optionId: +value[key], value: value[key] }
      }
    }

    this.$emit('answer', answers)
  }

  terminationCount (rowId: number, columnId: number) {
    return this.terminationMap.get(`${rowId}-${columnId}`) || 0
  }

  isAnswered () {
    return AnswerManager.isMatrixAnswered(this.rows, this.answers)
  }
}
