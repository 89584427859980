





import { VuetifyForm } from '@focus/components'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class FormComponent extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  @Prop({ type: Boolean, default: false })
  readonly fetching!: boolean

  @Prop({ type: Boolean, default: false })
  readonly submitting!: boolean

  reset () {
    const form = this.$refs.form as VuetifyForm
    return form.reset()
  }

  validate () {
    const form = this.$refs.form as VuetifyForm
    return form.validate()
  }
}
