





















































































































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import { Column, BulkListAction, ListActionChild, ListAction, ListTableOptions, ListBadge } from '../List.types'

type SelectedBulkListAction<T> = BulkListAction<T> & { selectedChild?: ListActionChild<T> }

@Component
export default class ListBody<T> extends Vue {
  @Prop({ default: () => [] })
  readonly columns!: Column<T>[]

  @Prop({ default: () => [] })
  readonly data!: T[]

  @Prop({ default: () => [] })
  readonly badges!: ListBadge<T>[]

  @Prop({ required: true })
  readonly totalItemCount!: number

  @Prop({ default: () => [] })
  readonly actions!: ListAction<T>[]

  @Prop({ default: () => [] })
  readonly bulkActions!: BulkListAction<T>[]

  @Prop({ type: Boolean, default: false })
  readonly loading!: boolean

  @Prop({ type: String, default: null })
  private readonly viewLink?: keyof T | ((item: T) => string)

  @Prop({ type: String, default: null })
  private readonly colourKey?: keyof T | ((item: T) => string)

  @Prop()
  readonly options!: ListTableOptions<T>

  get hasColorDisplay () {
    return !!this.badges.length || !!this.colourKey
  }

  get bulkSelectInput () {
    return this.$refs.bulkSelectInput as any as { blur: () => void; isMenuActive: boolean } // eslint-disable-line @typescript-eslint/no-explicit-any
  }

  get showSelect () {
    return !!this.bulkActions.length
  }

  get itemsPerPageOptions () {
    return [5, 10, 15, 25, 50]
  }

  get itemsPerPageText () {
    return 'Items per page:'
  }

  get invalidSelectedItems () {
    if (!this.selected.length) {
      return true
    }

    if (!this.selectedAction?.enabled) {
      return false
    }

    return !this.selectedAction.enabled(this.selected)
  }

  get childText () {
    return this.additionalData?.text || null
  }

  selected: T[] = []

  selectedAction: SelectedBulkListAction<T> | null = null

  additionalData: ListActionChild<T> | null = null

  get displayColumns () {
    const columns = Array.from(this.columns)
    if (this.hasColorDisplay) {
      columns.unshift({ text: '', value: '' as keyof T, type: 'string', sortable: false, filterable: false })
    }

    if (this.actions.length) {
      columns.push({ text: 'Actions', value: 'actions' as keyof T, type: 'action', sortable: false, filterable: false })
    }
    return columns
  }

  private renderValue (item: T, column: Column<T>) {
    if (column.display) {
      return column.display(item[column.value])
    }
    return item[column.value] || '-'
  }

  private badgeColor (item: T) {
    if (this.badges.length) {
      return this.badges.find(badge => badge.show(item))?.color
    } else if (this.colourKey) {
      if (typeof this.colourKey !== 'function') {
        return item[this.colourKey]
      } else {
        return this.colourKey(item)
      }
    }

    return null
  }

  private validActions (item: T) {
    return this.actions.filter(action => {
      if (action.enabled === false) {
        return false
      }
      return !action.enabled || action.enabled(item)
    })
  }

  private itemEvents (item: T) {
    const foo = true
    if (foo) {
      return {
        click: () => this.viewItem(item)
      }
    } else {
      return null
    }
  }

  private viewItem (item: T) {
    this.$emit('view', item)
  }

  async applyBulkAction () {
    await this.selectedAction?.method(this.selected, this.selectedAction.selectedChild?.value || null)
    this.selectedAction = null
  }

  public clearSelected () {
    this.selected = []
  }

  private setSelectAction (bulkAction: SelectedBulkListAction<T>, child?: ListActionChild<T>) {
    bulkAction.selectedChild = child
    this.selectedAction = bulkAction
  }

  private displayBulkAction (item: SelectedBulkListAction<T>) {
    if (item.selectedChild) {
      return `${item.text} (${item.selectedChild.text})`
    }
    return item.text
  }
}
