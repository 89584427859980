




















import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import { Card } from '../../../Card'
import Button from '../../../Button/Button.vue'
import Select from '../../../Select/Select.vue'
import TextField from '../../../TextField/TextField.vue'
import Datepicker from '../../../Datepicker/Datepicker.vue'
import FilterDisplay from '../../FilterDisplay/FilterDisplay.vue'

import { Column, booleanOptions, dateOperators, operators, ColumnOperator, ColumnOption, ListFilter, ListFilterValueType, ListFilterRawValueType } from '../../List.types'

@Component({
  components: { Card, Button, Select, TextField, Datepicker, FilterDisplay }
})
export default class FilterEdit<T> extends Vue {
  @Prop()
  readonly filters!: ListFilter<T>[]

  @Prop({ default: () => [] })
  readonly columns!: Column<T>[]

  filterColumn: Column<T> | null = null

  filterOperator: ColumnOperator | null = null

  filterValue: ListFilterValueType | null = null

  get booleanOptions (): ColumnOption[] {
    return booleanOptions
  }

  get incompleteInput () {
    return !this.filterColumn || !this.filterOperator || this.filterValue === null
  }

  get validOperators (): ColumnOperator[] {
    if (!this.filterColumn) {
      return []
    } else if (this.filterColumn.type === 'date') {
      return dateOperators
    }
    const column: Column<T> = this.filterColumn as Column<T>
    return operators.filter(operator => operator.valid(column.type))
  }

  get allowMultiple () {
    return this.filterOperator?.value === 'in'
  }

  @Watch('filterColumn')
  columnChanged () {
    this.filterOperator = this.validOperators.find(i => i.value === 'eq') || null
    this.filterValue = null
  }

  addFilter () {
    let rawValue: ListFilterRawValueType
    if (this.incompleteInput) {
      return
    }

    const filterValue = this.filterValue

    const column: Column<T> = this.filterColumn as Column<T>

    switch (column.type) {
      case 'date':
        rawValue = new Date(this.filterValue as string).toISOString()
        break
      case 'enum':
      case 'boolean': {
        if (Array.isArray(filterValue)) {
          rawValue = filterValue.map(i => (i as ColumnOption<string>).value)
        } else {
          rawValue = (this.filterValue as ColumnOption<string>).value
        }
        break
      }
      default:
        rawValue = (this.filterValue as string | number)
    }

    if (this.filterColumn && this.filterOperator && this.filterValue) {
      const filter: ListFilter<T> = {
        field: this.filterColumn,
        operator: this.filterOperator,
        value: this.filterValue,
        rawValue
      }
      this.$emit('update:filters', [...this.filters, filter])
    }
    this.filterColumn = null
    this.filterOperator = null
    this.filterValue = null
  }

  removeFilter (index: number) {
    const filters = Array.from(this.filters)
    filters.splice(index, 1)
    this.$emit('update:filters', filters)
  }
}
