import { buildToolboxGroup } from '../../layout-editor.utilities'
import { ScreenerQuestionType } from '../../models'

export const toolboxItems = [
  buildToolboxGroup({
    name: 'Simple',
    icon: 'short_text',
    items: [
      { type: ScreenerQuestionType.TEXTAREA },
      { type: ScreenerQuestionType.RATING },
      { type: ScreenerQuestionType.CHECKBOX },
      { type: ScreenerQuestionType.RADIO },
      { type: ScreenerQuestionType.SELECT },
      { type: ScreenerQuestionType.RANKING },
      { type: ScreenerQuestionType.SLIDER }
    ]
  }),
  buildToolboxGroup({
    name: 'Image',
    icon: 'perm_media',
    items: [
      { type: ScreenerQuestionType.IMAGE_SINGLE },
      { type: ScreenerQuestionType.IMAGE }
    ]
  }),
  buildToolboxGroup({
    name: 'Table',
    icon: 'table_chart',
    items: [
      { type: ScreenerQuestionType.MATRIX },
      { type: ScreenerQuestionType.MATRIX_MULTI },
      { type: ScreenerQuestionType.MATRIX_DYNAMIC }
    ]
  }),
  buildToolboxGroup({
    name: 'Special',
    icon: 'folder_special',
    items: [
      { type: ScreenerQuestionType.FILE },
      { type: ScreenerQuestionType.LOOP }
    ]
  })
]
