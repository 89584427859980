











































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import { FormFieldError, SystemFile } from '../../../index.types'
import BaseInput from './BaseInput.vue'
import FileUpload from '../../FileUpload/FileUpload.vue'
import FileRemoveConfirmModal from '../FileRemoveConfirmModal.vue'
import { getInputName } from '../layout-editor.utilities'
import { ScreenerQuestionType, ScreenerQuestion, ExternalLink } from '../models'
import { ScreenerBuilderQuestionErrors } from '../layout-editor.types'

@Component({
  components: { BaseInput, FileUpload, FileRemoveConfirmModal }
})
export default class FileInput extends Vue {
  @Prop()
  readonly question!: ScreenerQuestion

  @Prop()
  readonly order!: number

  @Prop()
  readonly text!: string

  @Prop({ type: Array, default: () => [] })
  private readonly files!: SystemFile[]

  @Prop({ type: Array, default: () => [] })
  private readonly links!: ExternalLink[]

  @Prop({ type: Array, default: () => [] })
  readonly value!: SystemFile[]

  @Prop({ type: Object, default: null })
  readonly error!: FormFieldError

  @Prop({ type: Object, default: () => ({}) })
  private readonly builderError!: ScreenerBuilderQuestionErrors

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean

  @Prop({ type: Boolean, default: false })
  readonly editorView!: boolean

  get errorMessages () {
    if (!this.error) {
      return []
    }
    return [this.error?.message]
  }

  get inputName () {
    return getInputName(ScreenerQuestionType.FILE)
  }

  get lockEdit () {
    return this.readonly || this.editorView
  }

  get removeFileConfirm () {
    return this.$refs.removeFileConfirm as FileRemoveConfirmModal
  }

  internalValue: SystemFile[] = []

  selectedFiles: File[] = []

  showConfirmRemoval = false

  valueDebounce = 0

  @Watch('value', { immediate: true })
  private valueChanged (value: SystemFile[]) {
    this.internalValue = value
  }

  @Watch('internalValue')
  private internalValueChanged (value: SystemFile[]) {
    clearTimeout(this.valueDebounce)

    this.valueDebounce = window.setTimeout(() => {
      this.$emit('input', value)
    }, 30)
  }

  imageUrl (file: SystemFile) {
    if (file.url) {
      return file.url
    }

    const { screenerId, pageId, id } = this.question

    return this.$store.getters['screeners/getQuestionFilePath']({ screenerId, pageId, questionId: id }, file)
  }

  uploadFiles () {
    this.$emit('upload', this.selectedFiles)
  }

  confirmFileRemoval (file: SystemFile) {
    this.removeFileConfirm.open(file)
  }

  removeFile (file: SystemFile) {
    this.$emit('removeFile', file)
  }

  isAnswered () {
    return this.internalValue && this.internalValue.length > 0
  }
}
