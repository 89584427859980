























































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { v4 as uuid } from 'uuid'

import Button from '../Button/Button.vue'
import Card from '../Card/Card.vue'
import TextField from '../TextField/TextField.vue'
import Select from '../Select/Select.vue'
import Checkbox from '../Checkbox/Checkbox.vue'
import DataOptionSelect from './DataOptionSelect.vue'
import { getValidButtonVariables } from './DataOptionSelect.utilities'

import { Editor, EditorContent } from '@tiptap/vue-2'
import { Bold } from '@tiptap/extension-bold'
import { Blockquote } from '@tiptap/extension-blockquote'
import { HardBreak } from '@tiptap/extension-hard-break'
import { Heading } from '@tiptap/extension-heading'
import { OrderedList } from '@tiptap/extension-ordered-list'
import { BulletList } from '@tiptap/extension-bullet-list'
import { ListItem } from '@tiptap/extension-list-item'
import { Italic } from '@tiptap/extension-italic'
import { Link } from '@tiptap/extension-link'
import { Strike } from '@tiptap/extension-strike'
import { Underline } from '@tiptap/extension-underline'
import { History } from '@tiptap/extension-history'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Image from '@tiptap/extension-image'
import { TextStyle } from '@tiptap/extension-text-style'
import { FontColor } from './FontColorExtension'
import { EmailButtonNode } from './EmailButtonNode'
import { LinkButtonNode } from './LinkButtonNode'
import { DataOptionNode } from './DataOptionNode'
import { DocumentNode } from './DocumentNode'
import { EnumProp } from '../../utilities/prop.utilities'

enum EditorType {
  EMAIL = 'email',
  SCREENER_TEMPLATE = 'screener-template',
  GENERIC = 'generic'
}

interface ButtonColorOption { code: string | null; name: string; font?: string }

function getExtensions (singleLine: boolean) {
  const paragraph = !singleLine ? Paragraph : Paragraph.configure({ HTMLAttributes: { style: 'display: inline;' } })
  const doc = !singleLine ? Document : DocumentNode
  return [
    doc,
    paragraph,
    Text,
    Blockquote,
    HardBreak,
    Heading.configure({ levels: [1, 2, 3] }),
    OrderedList,
    BulletList,
    ListItem,
    Bold,
    Italic,
    Link,
    Strike,
    Underline,
    Image,
    History,
    FontColor,
    TextStyle,
    LinkButtonNode,
    EmailButtonNode,
    DataOptionNode
  ]
}

@Component({
  components: { Button, Card, Checkbox, DataOptionSelect, EditorContent, Select, TextField }
})
export default class TemplateEditor extends Vue {
  @Prop({ type: String, default: '' })
  private readonly value!: string

  @Prop(EnumProp(EditorType, EditorType.GENERIC))
  private readonly editorType!: EditorType

  @Prop({ type: Boolean, default: false })
  private readonly singleLine!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly tall!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly disabled!: boolean

  @Prop({ type: String })
  private readonly type?: string

  @Prop({ type: Boolean, default: false })
  private readonly required!: boolean

  private buttonData: { url: string; isVariable: boolean; color?: ButtonColorOption; uuid?: string } = { url: '', isVariable: false }

  private imageData: { url: string; height?: number; width?: number } = { url: '' }

  private editor!: Editor

  private colors = [
    { code: 'rgba(0, 0, 0, 0.87)', name: 'Default' },
    { code: '#94BB20', name: 'Green' },
    { code: '#0081C6', name: 'Blue' },
    { code: '#4628C4', name: 'Purple' },
    { code: '#C4238F', name: 'Pink' },
    { code: '#EF9D2F', name: 'Yellow' },
    { code: '#A50C32', name: 'Red' },
    { code: '#ffffff', name: 'White' }
  ]

  private buttonColors = [
    { code: null, name: 'Default' },
    { code: '#94BB20', name: 'Green', font: '#ffffff' },
    { code: '#0081C6', name: 'Blue ', font: '#ffffff' },
    { code: '#4628C4', name: 'Purple', font: '#ffffff' },
    { code: '#C4238F', name: 'Pink', font: '#ffffff' },
    { code: '#EF9D2F', name: 'Yellow' },
    { code: '#A50C32', name: 'Red', font: '#ffffff' },
    { code: '#ffffff', name: 'White' }
  ]

  private get isEmailTemplate () {
    return this.editorType === EditorType.EMAIL
  }

  private get isScreenerTemplate () {
    return this.editorType === EditorType.SCREENER_TEMPLATE
  }

  private get isEditable () {
    return this.editor.isEditable
  }

  private get buttonVariables () {
    if (!this.type) {
      return []
    }
    console.log(getValidButtonVariables(this.type))
    return getValidButtonVariables(this.type)
  }

  @Watch('value', { immediate: true })
  private valueUpdated (newValue: string) {
    if (this.editor && newValue !== this.editor.getHTML()) {
      this.editor.chain().focus().setContent(newValue).run()
    }
  }

  @Watch('disabled', { immediate: true })
  private disabledUpdated (newValue: boolean) {
    if (this.editor) {
      this.editor.setEditable(!newValue)
    }
  }

  created () {
    this.editor = new Editor({
      content: this.value,
      extensions: getExtensions(this.singleLine),
      editable: !this.disabled,

      onUpdate: () => {
        if (this.editor.isEmpty) {
          this.$emit('input', '')
        } else {
          this.$emit('input', this.editor.getHTML())
        }
      }
    })
  }

  private beforeDestroy () {
    if (this.editor) {
      this.editor.destroy()
    }
  }

  private createImageParams () {
    const params = { src: this.imageData.url }
    this.imageData = { url: '' }
    return params
  }

  private setButtonColor (color: ButtonColorOption) {
    this.buttonData.color = color
  }

  private createButtonParams () {
    const params = { isVariable: false, url: this.buttonData.url, uuid: this.generateUuid(), color: this.buttonData.color?.code || '', textColor: this.buttonData.color?.font }
    this.buttonData = { url: '', isVariable: false }
    return params
  }

  private generateUuid () {
    return uuid()
  }
}
