import { SystemFile } from '../../../index.types'
import { ExternalLink } from './external-link.model'
import { ScreenerPathingConfigurationItem } from './screener-pathing-config.model'
import { ScreenerQuestionAnswer } from './screener-question-answer.model'
import { FileToUpload, ScreenerQuestionOption } from './screener-question-option.model'
import { ScreenerQuestionTermination } from './screener-question-termination.model'

export enum ScreenerQuestionType {
  TEXTFIELD = 'text-field',
  TEXTAREA = 'textarea',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  SELECT = 'select',
  RATING = 'rating',
  IMAGE = 'image',
  IMAGE_SINGLE = 'image-single',
  FILE = 'file',
  MATRIX = 'matrix',
  MATRIX_MULTI = 'matrix-multi',
  MATRIX_DYNAMIC = 'matrix-dynamic',
  LOOP = 'loop',
  RANKING = 'ranking',
  SLIDER = 'slider'
}

export enum ScreenerQuestionLoopAnswerType {
  QUESTIONS = 'questions',
  OPTIONS = 'options'
}

export type ScreenerQuestion = {
  id: number;
  uuid?: string;
  screenerId?: number;
  pageId?: number;
  order: number;
  text: string;
  code?: string;
  type: ScreenerQuestionType;
  height?: number;
  width?: number;
  uniqueAnswers?: boolean;
  stepCount?: number;
  options: ScreenerQuestionOption[];
  files?: (SystemFile | FileToUpload)[];
  links?: ExternalLink[];
  downloadFiles?: (SystemFile | FileToUpload)[];
  answers?: ScreenerQuestionAnswer[];
  pathingFlows: ScreenerPathingConfigurationItem[];
  terminations: ScreenerQuestionTermination[];
  loopType?: ScreenerQuestionType;
  loopAnswerType?: ScreenerQuestionLoopAnswerType;
  parentId?: string | number;
  isToolboxItem?: boolean;
  randomiseOptions: boolean;
  requiredOptionCount?: number;
}
