import { ThemeOptions } from 'vuetify/types/services/theme'

export const theme: ThemeOptions = {
  options: { customProperties: true },
  themes: {
    light: {
      primary: '#94BB20',
      secondary: '#0081C6',
      accent: '#C4238F',
      warning: '#EF9D2F',
      error: '#A50C32',
      'theme-background': '#F4F4F4',
      'theme-green': '#94BB20',
      'theme-grey': '#BBBBBB',
      'theme-pink': '#C4238F',
      'theme-toolbar': '#0A2A3B',
      'theme-purple': '#4628C4',
      'theme-yellow': '#EF9D2F',
      'theme-blue': '#0081C6',
      'theme-red': '#A50C32'
    }
  }
}
