














import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import Button from '../Button/Button.vue'

@Component({
  components: { Button }
})
export default class ButtonMenu<T> extends Vue {
  @Prop()
  private readonly value!: T

  @Prop({ type: String, default: '' })
  private readonly placeholder!: string

  @Prop({ type: Boolean, default: false })
  private readonly disabled!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly error!: boolean

  @Prop({ type: Array, default: () => [] })
  private readonly options!: { text: string; value: T }[]

  get buttonText () {
    return this.options.find(i => i.value === this.value)?.text || this.placeholder || 'Unknown'
  }

  private styles: Record<string, string> = {}

  mounted () {
    this.getStyles()
  }

  updated () {
    this.getStyles()
  }

  private getStyles () {
    const styles = this.$el?.attributes.getNamedItem('style')?.value?.split(';').filter(style => !!style) || []
    styles.push('text-transform: none')
    const styleObject = styles.reduce((obj, style) => {
      const [key, value] = style.split(':')
      return {
        ...obj,
        [key.trim()]: value.trim()
      }
    }, {} as Record<string, string>)
    this.styles = styleObject
  }

  private updateValue (value: T) {
    this.$emit('input', value)
  }
}
