



























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import Button from '../Button/Button.vue'

import { OptionTab, TemplateDataOption, templateOptions } from './DataOptionSelect.utilities'
import { capitalize } from '../../utilities/displayMethods'

@Component({
  components: { Button }
})
export default class DataOptionSelect extends Vue {
  @Prop({ type: Boolean, default: () => false })
  private readonly isEditable!: boolean

  @Prop({ type: Boolean, default: () => false })
  private readonly disabled!: boolean

  @Prop({ type: String })
  private readonly type?: string

  private show = false

  private tab: OptionTab | null = null

  private get selectedTab (): number {
    return this.templateTabs.findIndex(i => i.value === this.tab) || 0
  }

  private set selectedTab (value: number) {
    this.tab = this.templateTabs[value]?.value
  }

  private get templateTabs () {
    if (!this.templateOptions) {
      return []
    }
    return Array.from(this.templateOptions.keys()).map(key => ({ text: capitalize(key), value: key }))
  }

  private get templateOptions () {
    if (!this.type) {
      return null
    }
    return templateOptions.get(this.type)
  }

  private get templateDataOptions (): TemplateDataOption[] {
    if (!this.type || !this.tab) {
      return []
    }
    return this.templateOptions?.get(this.tab) || []
  }

  @Watch('type', { immediate: true })
  private typeChanged () {
    this.tab = this.templateTabs[0]?.value || null
  }

  private selectOption (option: TemplateDataOption) {
    this.$emit('selectOption', option)
    this.show = false
  }
}
