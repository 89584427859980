






































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { ScreenerQuestion, ScreenerPathingConfigurationItem, ScreenerPathingConfigurationItemType } from '../../models'
import Button from '../../../Button/Button.vue'

@Component({
  components: { Button }
})
export default class PathingTypeSelect extends Vue {
  @Prop()
  private readonly question!: ScreenerQuestion

  @Prop({ type: Array, default: () => [] })
  private readonly pageQuestions!: ScreenerQuestion[]

  @Prop()
  private readonly item!: ScreenerPathingConfigurationItem

  @Prop({ type: Boolean, default: false })
  private readonly locked!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly error!: boolean

  private get isPageSkip () {
    return this.item.type === ScreenerPathingConfigurationItemType.PAGE
  }

  private get validQuestions () {
    return this.pageQuestions.filter(i => i.order > this.question.order)
  }

  private get selectedQuestion () {
    return this.pageQuestions.find(i => [i.id, i.uuid || '-'].includes(this.item.targetQuestionId || ''))
  }

  private get selectedQuestionOrder () {
    return this.selectedQuestion?.order || '-'
  }

  private setQuestionJump () {
    this.$emit('update:item', { ...this.item, type: ScreenerPathingConfigurationItemType.QUESTION })
  }

  private setPageSkip () {
    this.$emit('update:item', { ...this.item, type: ScreenerPathingConfigurationItemType.PAGE, targetQuestionId: null })
  }

  private selectQuestion (question: ScreenerQuestion) {
    this.$emit('update:item', { ...this.item, targetQuestionId: question.id || question.uuid })
  }
}
