import Vue from 'vue'
import { ApiError } from '@focus/components'
import Component from 'vue-class-component'

@Component
export default class BaseComponent extends Vue {
  async showError (error: ApiError) {
    await this.$store.dispatch('showError', error)
  }
}
