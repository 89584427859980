import { Node, mergeAttributes, CommandProps, RawCommands, Command } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import EmailButton from './EmailButton.vue'

type RenderHtmlParams = {
  HTMLAttributes: Record<string, any>[]; // eslint-disable-line
}

declare module '@tiptap/core' {
  interface Commands {
    email: {
      /**
       * Adds the email button
       */
       setButton: (args: { isVariable: boolean; url: string }) => Command;
    };
  }
}

export const EmailButtonNode = Node.create<RenderHtmlParams>({
  name: 'emailButton',

  group: 'block',

  content: 'inline*',

  parseHTML () {
    return [
      {
        tag: 'div[data-type="emailButton"]'
      }
    ]
  },

  renderHTML ({ HTMLAttributes, node }) {
    const url = node.attrs.url
    const isVariable = node.attrs.isVariable
    return ['div', mergeAttributes(HTMLAttributes, { 'data-type': 'emailButton', 'data-url': url, 'data-isVariable': isVariable }), 0]
  },

  addNodeView () {
    return VueNodeViewRenderer(EmailButton)
  },

  addCommands () {
    return {
      setButton: (attributes: { isVariable: boolean; url: string }) => ({ commands }: CommandProps) => {
        return commands.insertContent({
          type: 'emailButton',
          attrs: attributes
        })
      }
    } as Partial<RawCommands>
  },

  addAttributes () {
    return {
      url: {
        default: null,
        // Customize the HTML parsing (for example, to load the initial content)
        parseHTML: element => {
          return {
            url: element.getAttribute('data-url')
          }
        },
        // … and customize the HTML rendering.
        renderHTML: attributes => {
          return {
            'data-url': attributes.url
          }
        }
      },
      isVariable: {
        default: null,
        // Customize the HTML parsing (for example, to load the initial content)
        parseHTML: element => {
          return {
            isVariable: element.getAttribute('data-isVariable')
          }
        },
        // … and customize the HTML rendering.
        renderHTML: attributes => {
          return {
            'data-isVariable': attributes.isVariable
          }
        }
      }
    }
  }
})
