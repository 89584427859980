









import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import Button from '../Button/Button.vue'

export interface ButtonSelectOption {
  text: string;
  value: string;
}

@Component({
  components: { Button }
})
export default class ButtonSelect extends Vue {
  @Prop({ type: String })
  private readonly value!: string

  @Prop({ type: Array, default: () => [] })
  private readonly options!: ButtonSelectOption[]

  @Prop({ type: Boolean, default: false })
  private readonly disabled!: boolean

  internalValue = ''

  @Watch('value', { immediate: true })
  private valueChanged (value: string) {
    this.internalValue = value
  }

  @Watch('internalValue')
  private internalValueChanged (value: string) {
    this.$emit('input', value)
  }

  private isSelected (item: ButtonSelectOption) {
    return item.value === this.internalValue
  }

  private getColour (item: ButtonSelectOption) {
    if (this.isSelected(item)) {
      return 'primary'
    } else {
      return null
    }
  }
}
