var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"loading":_vm.loading,"disabled":_vm.loading,"headers":_vm.displayColumns,"items":_vm.data,"server-items-length":_vm.totalItemCount,"options":_vm.options,"show-select":_vm.showSelect,"footer-props":{
    itemsPerPageOptions: _vm.itemsPerPageOptions,
    showFirstLastPage: true,
    itemsPerPageText: _vm.itemsPerPageText
  }},on:{"update:options":function($event){return _vm.$emit('update:options', $event)}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
  var pagination = ref.pagination;
  var options = ref.options;
return [_c('div',{staticClass:"top-pagination-container"},[(_vm.selected.length)?_c('v-banner',{staticClass:"bulk-banner white--text",attrs:{"single-line":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(!!_vm.selectedAction)?_c('v-btn',{attrs:{"text":"","dark":"","disabled":_vm.invalidSelectedItems},on:{"click":_vm.applyBulkAction}},[_vm._v(" Confirm ")]):_vm._e()]},proxy:true}],null,true)},[_c('div',{staticClass:"d-flex align-center"},[_c('v-select',{ref:"bulkSelectInput",staticClass:"bulk-select white--text",attrs:{"value":_vm.selectedAction,"placeholder":"Bulk Select","dense":"","filled":"","hide-details":"","dark":"","items":_vm.bulkActions,"item-text":_vm.displayBulkAction,"return-object":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
  var item = ref.item;
  var selectOn = ref.on;
return [(item.children)?_c('v-menu',{attrs:{"open-on-click":false,"open-on-hover":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var menuOn = ref.on;
return [_c('v-list-item',_vm._g({on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},menuOn),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1),_c('v-icon',[_vm._v("keyboard_arrow_right")])],1)]}}],null,true)},[_c('v-list',_vm._l((item.children),function(child,index){return _c('v-list-item',_vm._g({key:index,on:{"click":function($event){return _vm.setSelectAction(item, child)}}},selectOn),[_c('v-list-item-title',[_vm._v(_vm._s(child.text))])],1)}),1)],1):_vm._e(),(!item.children)?_c('v-list-item',{on:{"click":function($event){return _vm.setSelectAction(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)],1):_vm._e()]}}],null,true)}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.selected.length)+" items selected")]),_c('v-spacer')],1)]):_vm._e(),(!_vm.selected.length)?_c('v-data-footer',{staticClass:"top-pagination",attrs:{"options":options,"pagination":pagination,"items-per-page-options":_vm.itemsPerPageOptions,"show-first-last-page":true,"items-per-page-text":_vm.itemsPerPageText},on:{"update:options":function($event){return _vm.$emit('update:options', $event)}}}):_vm._e()],1)]}},{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"primary","indeterminate":""}})]},proxy:true},{key:"item",fn:function(ref){
  var item = ref.item;
  var isSelected = ref.isSelected;
  var select = ref.select;
return [_c('tr',_vm._g({class:{ 'selected-row': isSelected },attrs:{"active":isSelected}},_vm.itemEvents(item)),[(_vm.showSelect)?_c('td',[_c('v-checkbox',{staticClass:"my-auto pa-0",attrs:{"input-value":isSelected,"primary":"","hide-details":""},on:{"change":select,"click":function($event){$event.stopPropagation();}}})],1):_vm._e(),(_vm.hasColorDisplay)?_c('td',[(_vm.badgeColor(item))?_c('v-badge',{attrs:{"dot":"","inline":"","color":_vm.badgeColor(item)}}):_vm._e()],1):_vm._e(),_vm._l((_vm.columns),function(column){return _c('td',{key:column.value},[_vm._v(" "+_vm._s(_vm.renderValue(item, column))+" ")])}),(_vm.actions.length)?_c('td',{staticClass:"justify-start align-center layout px-0 pl-5"},[_c('v-menu',{attrs:{"bottom":"","right":"","eager":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"icon":"","disabled":!_vm.validActions(item).length},on:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-icon',[_vm._v(" expand_more ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.validActions(item)),function(action,i){return _c('v-list-item',{key:i,on:{"click":function($event){return action.method(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(action.text))])],1)}),1)],1)],1):_vm._e()],2)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }