












import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import Button from '../../../Button/Button.vue'

@Component({
  components: { Button }
})
export default class ColumnDisplay extends Vue {
  @Prop({ default: -1 })
  readonly order!: number

  @Prop()
  readonly field!: string

  @Prop({ default: '' })
  readonly table!: string

  remove () {
    this.$emit('onRemove')
  }
}
