






























































import { sanitizeTemplate } from '../../../utilities/page-template-editor.utilities'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { ScreenerBuilderQuestionErrors } from '../layout-editor.types'
import { SystemFile } from '../../../index.types'
import { overviewTextClass } from './views.constants'
import { ExternalLink } from '../models'

@Component
export default class BaseInput extends Vue {
  @Prop({ type: Number, default: 0 })
  private readonly order!: number

  @Prop({ type: String, default: '' })
  private readonly text!: string

  @Prop({ type: Array, default: () => [] })
  private readonly files!: SystemFile[]

  @Prop({ type: Array, default: () => [] })
  private readonly links!: ExternalLink[]

  @Prop({ type: Object, default: () => ({}) })
  private readonly builderError!: ScreenerBuilderQuestionErrors

  @Prop({ type: Boolean, default: false })
  readonly error!: boolean

  @Prop({ type: String, default: null })
  readonly errorMessage!: string

  @Prop({ type: Boolean, default: false })
  readonly terminatingOverview!: boolean

  @Prop({ type: Map, default: () => new Map() })
  readonly terminationMap!: Map<string, number>

  get overviewTextClass () {
    return overviewTextClass
  }

  get questionText () {
    return sanitizeTemplate(this.text)
  }

  get validLinkURLs () {
    return this.links.reduce((list, link) => {
      try {
        const url = new URL(link.url)
        return list.concat(url)
      } catch {
        return list
      }
    }, [] as URL[])
  }

  formatPath (link: ExternalLink) {
    try {
      const url = new URL(link.url)
      return url
    } catch (error) {
      this.$set(link, 'invalid', true)
    }
  }

  downloadFile (file: SystemFile) {
    this.$emit('downloadFile', file)
  }
}
