







import { Component, Prop } from 'vue-property-decorator'
import { TerminationItemError, TerminationItemParamError } from './termination-item.types'

import { ScreenerQuestionTermination, TerminationOperator } from '../../../models'
import ButtonMenu from '../../../../ButtonMenu/ButtonMenu.vue'

import BaseTerminationItem from './BaseItem.vue'

@Component({
  components: { ButtonMenu }
})
export default class RawTerminationItem extends BaseTerminationItem {
  @Prop()
  private readonly value!: ScreenerQuestionTermination

  @Prop({ type: Array, default: () => [] })
  private readonly operators!: { text: string; value: TerminationOperator }[]

  @Prop({ type: Object, required: true })
  private readonly error!: TerminationItemError

  @Prop({ type: Boolean, default: false })
  private readonly locked!: boolean

  private get operator () {
    return this.value.operator
  }

  private set operator (value: TerminationOperator) {
    this.$emit('input', { ...this.value, operator: value })
  }

  private get terminateValue () {
    return this.value.value
  }

  private set terminateValue (value: string | undefined) {
    this.$emit('input', { ...this.value, value })
  }

  validate (): TerminationItemParamError {
    return this.buildParams({
      operator: !this.operator,
      value: !this.terminateValue
    })
  }
}
