















































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { ScreenerQuestion, ScreenerPathingConfigurationItem, ScreenerPathingConfigurationItemType, ScreenerPathingConfigurationItemParameter } from '../../models'
import Button from '../../../Button/Button.vue'
import PathingParameter from './PathingParameter.vue'
import PathingTypeSelect from './PathingTypeSelect.vue'
import { deepClone } from '../../../../utilities/helpers'

@Component({
  components: { Button, PathingParameter, PathingTypeSelect }
})
export default class PathingConfigurationItemComponent extends Vue {
  @Prop()
  private readonly question!: ScreenerQuestion

  @Prop({ type: Array, default: () => [] })
  private readonly pageQuestions!: ScreenerQuestion[]

  @Prop()
  private readonly item!: ScreenerPathingConfigurationItem

  @Prop({ type: Boolean, default: false })
  private readonly locked!: boolean

  private get isPageSkip () {
    return this.item.type === ScreenerPathingConfigurationItemType.PAGE
  }

  private error = { type: false, question: false, params: { invalid: false, count: false } }

  private get hasError () {
    return this.error.type || this.error.question || this.error.params.invalid || this.error.params.count
  }

  private removeItem () {
    this.$emit('remove', this.item)
  }

  private addParam () {
    this.$emit('add:param', this.item)
  }

  private updateParam (data: ScreenerPathingConfigurationItemParameter, index: number) {
    const params = deepClone(this.item.params)
    params.splice(index, 1, { ...data, operator: data.operator || 'eq' })
    this.$emit('update:item', { ...this.item, params })
  }

  private removeParam (param: ScreenerPathingConfigurationItemParameter) {
    this.$emit('update:item', { ...this.item, params: this.item.params.filter(item => item !== param) })
  }

  public validate () {
    this.error = { type: false, question: false, params: { invalid: false, count: false } }

    if (!this.item.type) {
      this.error.type = true
    }

    if (this.item.type === ScreenerPathingConfigurationItemType.QUESTION && !this.item.targetQuestionId) {
      this.error.question = true
    }

    const paramComponents = this.$refs.params as PathingParameter[]
    if (paramComponents) {
      const validParams = paramComponents.every(param => param.validate())
      if (!validParams) {
        this.error.params.invalid = true
      }
    } else {
      this.error.params.count = true
    }

    return !this.error.type && !this.error.question && !this.error.params.invalid && !this.error.params.count
  }
}
