export enum TerminationType {
  SOFT = 'soft',
  HARD = 'hard'
}

export enum TerminationOperator {
  EQUAL = 'eq',
  NOT_EQUAL = 'ne',
  GREATER = 'gt',
  GREATER_OR_EQUAL = 'gte',
  LESS = 'lt',
  LESS_OR_EQUAL = 'lte'
}

export type ScreenerQuestionTermination = {
  id: number;
  questionId: number;
  rowId?: string | number;
  optionId?: string | number;
  operator: TerminationOperator;
  value?: string;
  type?: TerminationType;
}
