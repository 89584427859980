import Vuetify from 'vuetify/lib'
import { UserVuetifyPreset } from 'vuetify/types/services/presets'
import { theme } from '../theme'

import FilterRemoveIcon from '../assets/FilterRemoveIcon.vue'

function deepSetWithFallback<T extends Record<string, any>> (value: T, fallback: T) { // eslint-disable-line
  if (typeof value !== 'object') {
    return value || fallback
  }

  const valueKeys = Object.keys(value) as (keyof T)[]
  const fallBackKeys = Object.keys(fallback) as (keyof T)[]
  const keys = [...valueKeys, ...fallBackKeys]
  const data: T = keys.reduce((data, key) => {
    if (typeof value[key] === 'object') {
      return {
        ...data,
        [key]: deepSetWithFallback(value[key], fallback[key])
      }
    }
    return {
      ...data,
      [key]: value[key] || fallback[key]
    }
  }, {} as T)

  return data
}

export const makeVuetifyInstance = (options?: Partial<UserVuetifyPreset>) => {
  const themeOptions = deepSetWithFallback(options?.theme || {}, theme)
  return new Vuetify({
    icons: {
      values: {
        filter_remove: { //eslint-disable-line
          component: FilterRemoveIcon
        }
      },
      iconfont: 'md'
    },
    ...options,
    theme: themeOptions
  })
}
