import { Node, CommandProps, RawCommands, Command } from '@tiptap/core'
import { mergeAttributes, VueNodeViewRenderer } from '@tiptap/vue-2'
import DataOption from './DataOption.vue'

type RenderHtmlParams = {
  HTMLAttributes: Record<string, any>[]; // eslint-disable-line
}

declare module '@tiptap/core' {
  interface Commands {
    data: {
      setOption: (args: { key: string; name: string; capitalize: boolean }) => Command;
    };
  }
}

export const DataOptionNode = Node.create<RenderHtmlParams>({
  name: 'data-option',

  group: 'inline',

  inline: true,

  selectable: false,

  atom: true,

  parseHTML () {
    return [
      {
        tag: 'span[data-option]'
      }
    ]
  },

  renderHTML ({ HTMLAttributes, node }) {
    const key = node.attrs.key
    const name = node.attrs.name
    const capitalize = !!node.attrs.capitalize
    const text = capitalize ? `{{ uppercase ${key} }}` : `{{ ${key} }}`
    return ['span', mergeAttributes(HTMLAttributes, { 'data-option': '', 'data-name': name, 'data-key': key, 'data-capitalize': capitalize }), text]
  },

  addNodeView () {
    return VueNodeViewRenderer(DataOption)
  },

  addCommands () {
    return {
      setOption: (attributes: { key: string; name: string; capitalize: boolean }) => ({ commands }: CommandProps) => {
        return commands.insertContent({
          type: 'data-option',
          attrs: attributes
        })
      }
    } as Partial<RawCommands>
  },

  addAttributes () {
    return {
      key: {
        default: null,
        parseHTML: element => {
          return {
            key: element.getAttribute('data-key')
          }
        },
        renderHTML: attributes => {
          return {
            'data-key': attributes.key
          }
        }
      },
      name: {
        default: null,
        parseHTML: element => {
          return {
            name: element.getAttribute('data-name')
          }
        },
        renderHTML: attributes => {
          return {
            'data-name': attributes.name
          }
        }
      },
      capitalize: {
        default: null,
        parseHTML: element => {
          return {
            capitalize: element.getAttribute('data-capitalize')
          }
        },
        renderHTML: attributes => {
          return {
            'data-capitalize': attributes.capitalize
          }
        }
      }
    }
  }
})
