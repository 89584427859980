



















































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { ScreenerQuestion, FileToUpload } from '../../models'
import BaseModal from '../../../BaseModal/BaseModal.vue'
import Button from '../../../Button/Button.vue'
import FileUpload from '../../../FileUpload/FileUpload.vue'
import ConfirmRemoveDocumentModal from './ConfirmRemoveDocumentModal.vue'
import { deepClone } from '../../../../utilities/helpers'
import { SystemFile } from '../../../../index.types'

@Component({
  components: { BaseModal, Button, FileUpload, ConfirmRemoveDocumentModal }
})
export default class DocumentDownloadModal extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly locked!: boolean

  private show = false

  private question: ScreenerQuestion | null = null

  private filesToUpload: File[] = []

  private addMoreFiles = false

  private get actionColWidth () {
    const width = this.locked ? '60px' : '120px'
    return `width: ${width};`
  }

  private get files () {
    return this.question?.downloadFiles || []
  }

  private get confirmRemoveModal () {
    return this.$refs.confirmRemoveModal as ConfirmRemoveDocumentModal
  }

  public get isOpen () {
    return this.show
  }

  public open (data: { question: ScreenerQuestion }) {
    this.question = deepClone(data.question)
    this.show = true
  }

  public close () {
    this.question = null
    this.addMoreFiles = false
    this.show = false
  }

  public updateQuestion (data: { question: ScreenerQuestion }) {
    if (this.show) {
      this.question = deepClone(data.question)
    }
  }

  private modalVisibilityUpdated (value: boolean) {
    if (!value) {
      this.close()
    }
  }

  private addFiles () {
    this.addMoreFiles = true
  }

  private uploadFiles () {
    if (!this.filesToUpload.length || !this.question) {
      return
    }
    const files = deepClone(this.question.downloadFiles || [])
    this.filesToUpload.forEach(file => {
      files.push({
        name: file.name,
        url: URL.createObjectURL(file),
        file
      })
    })
    this.question.downloadFiles = files
  }

  private confirmRemoval (file: SystemFile | FileToUpload) {
    this.confirmRemoveModal.open({ file })
  }

  private downloadFile (file: SystemFile | FileToUpload) {
    this.$emit('downloadFile', file)
  }

  private removeFile (file: SystemFile | FileToUpload) {
    if (this.question?.downloadFiles) {
      this.question.downloadFiles = this.question.downloadFiles.filter(f => f.id !== file.id)
    }
  }

  private save () {
    this.$emit('save', this.question?.downloadFiles)
    this.close()
  }
}
