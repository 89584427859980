import {
  ParamOperator,
  ScreenerPathingConfigurationItemParameter,
  ScreenerQuestionAnswer,
  ScreenerQuestionType
} from '../../models'
import { BaseDisplayModule } from './base'

export class RawDisplayModule extends BaseDisplayModule {
  run (type: ScreenerQuestionType) {
    return [
      ScreenerQuestionType.RATING,
      ScreenerQuestionType.FILE,
      ScreenerQuestionType.TEXTAREA,
      ScreenerQuestionType.TEXTFIELD
    ].includes(type)
  }

  valid (type: ScreenerQuestionType, param: ScreenerPathingConfigurationItemParameter, answers: ScreenerQuestionAnswer[]) {
    if (param.operator === ParamOperator.NOT_EQUAL) {
      return answers.every(answer => answer.value !== param.value)
    }

    return answers.some(answer => {
      switch (param.operator) {
        case ParamOperator.GREATER_THAN:
          return answer.value > param.value
        case ParamOperator.GREATER_THAN_EQUAL:
          return answer.value >= param.value
        case ParamOperator.LESS_THAN:
          return answer.value < param.value
        case ParamOperator.LESS_THAN_EQUAL:
          return answer.value <= param.value
        case ParamOperator.EQUAL:
          return answer.value === param.value
        default:
          return false
      }
    })
  }
}
