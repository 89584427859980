















































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import Button from '../../Button/Button.vue'
import TextField from '../../TextField/TextField.vue'
import FileUpload from '../../FileUpload/FileUpload.vue'
import TemplateEditor from '../../TemplateEditor/TemplateEditor.vue'

import { getDependantQuestions, getInputDisplayDetails } from '../layout-editor.utilities'
import { deepClone, valueUnchanged } from '../../../utilities/helpers'
import {
  ChoiceConfigurationOptions,
  ImageConfigurationOptions,
  LoopConfigurationOptions,
  QuestionConfigurationOptions,
  RankingConfigurationOptions,
  SliderConfigurationOptions
} from '../layout-editor.types'
import {
  ExternalLink,
  FileToUpload,
  ScreenerPathingConfigurationItem,
  ScreenerQuestion,
  ScreenerQuestionTermination,
  ScreenerQuestionType
} from '../models'

import PathingConfigurationModal from './PathingConfigurationModal/PathingConfigurationModal.vue'
import TerminationModal from './TerminationModal/TerminationModal.vue'
import DocumentDownloadModal from './DocumentDownloadModal/DocumentDownloadModal.vue'
import RemoveQuestionModal from './RemoveQuestionModal.vue'
import ImagePickerConfigurationOptionsComponent from './ImagePickerOptions.vue'
import CheckboxConfigurationOptionsComponent from './CheckboxOptions.vue'
import RadioGroupConfigurationOptionsComponent from './RadioGroupOptions.vue'
import SelectConfigurationOptionsComponent from './SelectOptions.vue'
import MatrixConfigurationOptionsComponent from './MatrixOptions.vue'
import SliderConfigurationOptionsComponent from './SliderOptions.vue'
import LoopOptionsComponent from './LoopOptions.vue'
import RankingConfigurationOptionsComponent from './RankingOptions.vue'
import ExternalLinkModal from './ExternalLinkModal/ExternalLinkModal.vue'
import MakeTemplateModal from './MakeTemplateModal.vue'
import { SystemFile } from '../../../index.types'
import { downloadFile } from '../../../utilities/file.utilities'
import { getPathingConfigType } from './PathingConfigurationModal/pathing.utilities'

@Component({
  components: {
    Button,
    CheckboxConfigurationOptionsComponent,
    LoopOptionsComponent,
    MatrixConfigurationOptionsComponent,
    ImagePickerConfigurationOptionsComponent,
    RadioGroupConfigurationOptionsComponent,
    SelectConfigurationOptionsComponent,
    RankingConfigurationOptionsComponent,
    SliderConfigurationOptionsComponent,
    TextField,
    RemoveQuestionModal,
    TemplateEditor,
    FileUpload,
    PathingConfigurationModal,
    TerminationModal,
    DocumentDownloadModal,
    ExternalLinkModal,
    MakeTemplateModal
  }
})
export default class ScreenerQuestionConfigurationComponent extends Vue {
  @Prop({ type: Number, default: () => 0 })
  private readonly jobId!: number

  @Prop({ type: Object, required: true })
  private readonly question!: ScreenerQuestion

  @Prop({ type: Array, default: () => [] })
  private readonly questions!: ScreenerQuestion[]

  @Prop({ type: Boolean, default: false })
  private readonly locked!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly disabled!: boolean

  questionData: Partial<ScreenerQuestion> = {}

  loading = false

  gettingAnswerCount = false

  filesToUpload: File[] = []

  options: QuestionConfigurationOptions = { options: [] }

  get questionType () {
    return getInputDisplayDetails(this.question.type).name
  }

  get isLoopQuestion () {
    return this.question.type === ScreenerQuestionType.LOOP
  }

  get questionText () {
    return this.question.text
  }

  set questionText (value: string) {
    this.$emit('update:question', { ...this.question, text: value })
  }

  get canRandomiseOptions () {
    return [
      ScreenerQuestionType.CHECKBOX,
      ScreenerQuestionType.SELECT,
      ScreenerQuestionType.RANKING,
      ScreenerQuestionType.RADIO,
      ScreenerQuestionType.MATRIX,
      ScreenerQuestionType.MATRIX_MULTI,
      ScreenerQuestionType.MATRIX_DYNAMIC
    ].includes(this.question.type)
  }

  get questionCode (): string | null {
    return this.question.code || null
  }

  set questionCode (value: string | null) {
    this.$emit('update:question', { ...this.question, code: value })
  }

  get randomiseOptions () {
    return this.question.randomiseOptions
  }

  set randomiseOptions (value: boolean) {
    this.$emit('update:question', { ...this.question, randomiseOptions: value })
  }

  get width () {
    return this.question.width
  }

  set width (value: number | undefined) {
    this.$emit('update:question', { ...this.question, width: value })
  }

  get height () {
    return this.question.height
  }

  set height (value: number | undefined) {
    this.$emit('update:question', { ...this.question, height: value })
  }

  get canSetPathingConfig () {
    return getPathingConfigType(this.question) !== 'unknown'
  }

  get canManageTermination () {
    return true
  }

  get questionRemoveModal () {
    return this.$refs.questionRemoveModal as RemoveQuestionModal
  }

  get configurationOptionsComponent () {
    switch (this.question.type) {
      case ScreenerQuestionType.IMAGE:
      case ScreenerQuestionType.IMAGE_SINGLE:
        return ImagePickerConfigurationOptionsComponent
      case ScreenerQuestionType.CHECKBOX:
        return CheckboxConfigurationOptionsComponent
      case ScreenerQuestionType.RADIO:
        return RadioGroupConfigurationOptionsComponent
      case ScreenerQuestionType.SELECT:
        return SelectConfigurationOptionsComponent
      case ScreenerQuestionType.MATRIX:
      case ScreenerQuestionType.MATRIX_MULTI:
      case ScreenerQuestionType.MATRIX_DYNAMIC:
        return MatrixConfigurationOptionsComponent
      case ScreenerQuestionType.LOOP:
        return LoopOptionsComponent
      case ScreenerQuestionType.RANKING:
        return RankingConfigurationOptionsComponent
      case ScreenerQuestionType.SLIDER:
        return SliderConfigurationOptionsComponent
    }
  }

  get pathingConfigButtonText () {
    if (this.locked) {
      return 'View Pathing Configuration'
    }

    return this.questionData.pathingFlows?.length ? 'Edit Pathing Configuration' : 'Add Pathing Configuration'
  }

  get pathingModal () {
    return this.$refs.pathingModal as PathingConfigurationModal
  }

  get makeTemplateModal () {
    return this.$refs.makeTemplateModal as MakeTemplateModal
  }

  get documentDownloadModal () {
    return this.$refs.documentDownloadModal as DocumentDownloadModal
  }

  get terminationButtonText () {
    if (this.locked) {
      return 'View Termination Options'
    }

    return this.questionData.terminations?.length ? 'Edit Termination Options' : 'Add Termination Options'
  }

  get terminationModal () {
    return this.$refs.terminationModal as TerminationModal
  }

  get externalLinkModal () {
    return this.$refs.externalLinkModal as ExternalLinkModal
  }

  private get canMakeTemplate () {
    return this.question.type !== ScreenerQuestionType.LOOP
  }

  @Watch('question', { immediate: true, deep: true })
  private questionChanged (value: ScreenerQuestion, oldValue: ScreenerQuestion) {
    if (valueUnchanged(value, oldValue)) {
      return
    }

    this.questionData = deepClone(value)

    switch (this.question.type) {
      case ScreenerQuestionType.IMAGE:
      case ScreenerQuestionType.IMAGE_SINGLE:
        this.options = { height: this.questionData.height, width: this.questionData.width, options: this.questionData.options || [] }
        break
      case ScreenerQuestionType.CHECKBOX:
      case ScreenerQuestionType.RADIO:
      case ScreenerQuestionType.SELECT:
      case ScreenerQuestionType.MATRIX:
      case ScreenerQuestionType.MATRIX_MULTI:
      case ScreenerQuestionType.MATRIX_DYNAMIC:
        this.options = { options: this.questionData.options || [], uniqueAnswers: this.questionData.uniqueAnswers }
        break
      case ScreenerQuestionType.RANKING:
        this.options = { options: this.questionData.options || [], requiredOptionCount: this.question.requiredOptionCount || null } as RankingConfigurationOptions
        break
      case ScreenerQuestionType.SLIDER:
        this.options = { options: this.questionData.options || [], stepCount: this.questionData.stepCount } as SliderConfigurationOptions
        break
      case ScreenerQuestionType.LOOP:
        this.options = {
          parentId: this.questionData.parentId,
          type: this.questionData.loopType,
          loopAnswerType: this.questionData.loopAnswerType,
          uniqueAnswers: this.questionData.uniqueAnswers,
          options: this.questionData.options || []
        }
        break
      default:
        this.options = { options: [] }
        break
    }
  }

  @Watch('questionData', { deep: true })
  private dataUpdated (value: Partial<ScreenerQuestion>, oldValue: Partial<ScreenerQuestion>) {
    if (!valueUnchanged(value, oldValue)) {
      this.documentDownloadModal.updateQuestion({ question: value as ScreenerQuestion })
      this.$emit('update:question', deepClone(value))
    }
  }

  @Watch('options')
  private optionsChanged (value: QuestionConfigurationOptions, oldValue: QuestionConfigurationOptions) {
    if (!valueUnchanged(value, oldValue)) {
      switch (this.question.type) {
        case ScreenerQuestionType.IMAGE:
        case ScreenerQuestionType.IMAGE_SINGLE: {
          const config = value as ImageConfigurationOptions
          this.questionData = { ...this.questionData, height: config.height, width: config.width, options: config.options }
          break
        }
        case ScreenerQuestionType.RANKING: {
          const data = value as RankingConfigurationOptions
          this.questionData = { ...this.questionData, options: data.options, requiredOptionCount: data.requiredOptionCount }
          break
        }
        case ScreenerQuestionType.SLIDER: {
          const data = value as SliderConfigurationOptions
          this.questionData = { ...this.questionData, options: data.options, stepCount: data.stepCount }
          break
        }
        case ScreenerQuestionType.CHECKBOX:
        case ScreenerQuestionType.RADIO:
        case ScreenerQuestionType.SELECT:
        case ScreenerQuestionType.MATRIX:
        case ScreenerQuestionType.MATRIX_MULTI:
        case ScreenerQuestionType.MATRIX_DYNAMIC: {
          const data = value as ChoiceConfigurationOptions
          this.questionData = { ...this.questionData, uniqueAnswers: data.uniqueAnswers, options: data.options }
          break
        }
        case ScreenerQuestionType.LOOP: {
          const data = value as LoopConfigurationOptions
          this.questionData = { ...this.questionData, parentId: data.parentId, loopType: data.type, loopAnswerType: data.loopAnswerType, uniqueAnswers: data.uniqueAnswers, options: data.options }
        }
      }
    }
  }

  async confirmQuestionRemoval () {
    let answerCount = 0
    if (this.question.id) {
      try {
        this.gettingAnswerCount = true
        answerCount = await this.$store.dispatch('screeners/getAnswerCount', { ...this.question, jobId: this.jobId })
        this.gettingAnswerCount = false
      } catch (error) {}
    }

    const dependants = getDependantQuestions(this.question, this.questions)
    this.questionRemoveModal.open(answerCount, dependants)
  }

  async createTemplateQuestion (data: { name: string; question: ScreenerQuestion}) {
    this.$emit('template', data)
  }

  async removeQuestion () {
    this.$emit('onRemove')
  }

  close () {
    this.$emit('onClose')
  }

  async uploadFiles (data: { question: ScreenerQuestion; files: File[] }) {
    const files = deepClone(this.questionData.downloadFiles || [])
    data.files.forEach(file => {
      files.push({
        name: file.name,
        url: URL.createObjectURL(file),
        file
      })
    })
    this.questionData = { ...this.questionData, downloadFiles: files }
  }

  openPathingModal () {
    this.pathingModal.open({ question: this.question, pageQuestions: this.questions })
  }

  openTerminationModal () {
    const parent = this.questions.find(question => [question.id, question.uuid].includes(this.question.parentId))
    this.terminationModal.open({ question: this.question, parent })
  }

  openDocumentDownloadModal () {
    this.documentDownloadModal.open({ question: this.question })
  }

  openExternalLinkModal () {
    this.externalLinkModal.open({ question: this.question })
  }

  saveConfig (flows: ScreenerPathingConfigurationItem[]) {
    this.questionData = { ...this.questionData, pathingFlows: flows }
  }

  saveTerminations (terminations: ScreenerQuestionTermination[]) {
    this.questionData = { ...this.questionData, terminations }
  }

  private downloadFile (file: SystemFile) {
    const path = this.$store.getters['screeners/getQuestionFilePath']({ screenerId: this.question.screenerId, pageId: this.question.pageId }, file)
    downloadFile(path)
  }

  private async saveDownloadFiles (files: (SystemFile | FileToUpload)[]) {
    this.questionData = { ...this.questionData, downloadFiles: files }
  }

  private async saveExternalLinks (links: ExternalLink[]) {
    this.questionData = { ...this.questionData, links }
  }
}
