export type ColumnType = 'number' | 'enum' | 'string' | 'date' | 'boolean' | 'action'

export interface ColumnOption<T = unknown> {
  text: string;
  value: T;
}

export type FieldValue = any //eslint-disable-line

export interface ListItem {
  [key: string]: FieldValue;
}

export type ListExportType = 'all' | 'page'

export interface ListExportData {
  type: ListExportType;
  name: string;
}

export interface Column<T> {
  text: string;
  value: keyof T;
  sortable?: boolean;
  filterable?: boolean;
  groupable?: boolean;
  divider?: boolean;
  filter?: (value: FieldValue, search: string, item: T) => boolean;
  sort?: (a: T, b: T) => number;
  type: ColumnType;
  options?: ColumnOption[];
  display?: false | ((value: FieldValue) => string);
  initiallyVisible?: boolean;
}

export interface ColumnOperator {
  text: string;
  value: string;
  valid: (type: ColumnType) => boolean;
}

type ListFilterValue<T> = T | T[]

export type ListFilterValueType = ListFilterValue<string> | ListFilterValue<number> | ListFilterValue<Date> | ListFilterValue<ColumnOption> | ListFilterValue<boolean>

export type ListFilterRawValueType = ListFilterValue<string> | ListFilterValue<number> | ListFilterValue<boolean>;

export interface ListFilter<T> {
  field?: Column<T>;
  operator?: ColumnOperator;
  value: ListFilterValueType;
  rawValue: ListFilterRawValueType;
}

export type ListBadge<T> = {
  name: string;
  color: string;
  show: (item: T) => boolean;
}

export interface StoredListFilter<T> {
  field?: keyof T;
  operator?: string;
  value: ListFilterRawValueType;
}

export interface StoredListTableOptions<T> {
  page: number;
  itemsPerPage: number;
  sortBy: (keyof T)[];
  sortDesc: boolean[];
}

export type Favourite<T> = {
  id: number;
  name: string;
  filters: StoredListFilter<T>[];
  selectedColumns: (keyof T)[];
  pagination: StoredListTableOptions<T>;
}

export interface StoredListSettings<T> {
  filters: StoredListFilter<T>[];
  selectedColumns: (keyof T)[];
  name?: string;
  pagination: Partial<StoredListTableOptions<T>>;
}

export interface ListAction<T> {
  text: string;
  enabled?: ((item: T) => boolean) | false;
  method: (item: T) => void;
}

export interface ListActionChild<T> { // eslint-disable-line @typescript-eslint/no-unused-vars
  text: string;
  value: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface BulkListAction<T> {
  text: string;
  enabled?: ((items: T[]) => boolean) | false;
  children?: ListActionChild<T>[];
  method: (items: T[], additionalData?: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface ListTableOptions<T> {
  page?: number;
  itemsPerPage?: number;
  sortBy?: (keyof T)[];
  sortDesc?: boolean[];
  groupBy?: (keyof T)[];
  groupDesc?: boolean[];
  multiSort?: boolean;
  mustSort?: boolean;
}

export interface ListCustomiseOptions<T> {
  filters: ListFilter<T>[];
  selectedColumns: (keyof T)[];
  favouriteName?: string;
  pagination: ListTableOptions<T>;
}

export const booleanOptions: ColumnOption<boolean>[] = [{
  text: 'Yes',
  value: true
}, {
  text: 'No',
  value: false
}]

export const dateOperators: ColumnOperator[] = [{
  text: 'Before',
  value: 'lt',
  valid: type => type === 'date'
}, {
  text: 'After',
  value: 'gt',
  valid: type => type === 'date'
}, {
  text: 'On',
  value: 'eq',
  valid: type => type === 'date'
}, {
  text: 'Not On',
  value: 'ne',
  valid: type => type === 'date'
}, {
  text: 'On or Before',
  value: 'lte',
  valid: type => type === 'date'
}, {
  text: 'On or After',
  value: 'gte',
  valid: type => type === 'date'
}]

export const operators: ColumnOperator[] = [{
  text: 'Equals',
  value: 'eq',
  valid: type => type !== 'date'
}, {
  text: 'Not Equals',
  value: 'ne',
  valid: type => type !== 'date'
}, {
  text: 'Like',
  value: 'lk',
  valid: type => type === 'string'
}, {
  text: 'Less Than',
  value: 'lt',
  valid: type => !['date', 'string', 'boolean', 'enum'].includes(type)
}, {
  text: 'Greater Than',
  value: 'gt',
  valid: type => !['date', 'string', 'boolean', 'enum'].includes(type)
}, {
  text: 'Less Than or Equal',
  value: 'lte',
  valid: type => !['date', 'string', 'boolean', 'enum'].includes(type)
}, {
  text: 'Greater Than or Equal',
  value: 'gte',
  valid: type => !['date', 'string', 'boolean', 'enum'].includes(type)
}, {
  text: 'One Of',
  value: 'in',
  valid: type => type === 'enum'
}]
