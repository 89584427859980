import { ColumnOption, ListFilter, StoredListFilter } from './List.types'

export function getDisplayValue<T> (filter: ListFilter<T>) {
  if (filter.field?.display) {
    const display = filter.field.display
    const value = filter.rawValue || filter.value
    return Array.isArray(value)
      ? value.map(i => display(i)).join(', ')
      : filter.field.display(value)
  }

  if (Array.isArray(filter.value)) {
    if (Object.keys(filter.value[0]).includes('text')) {
      return filter.value.map((i) => (i as ColumnOption<T>).text).join(', ')
    } else {
      return filter.value.join(', ')
    }
  }

  if (typeof filter.value === 'object') {
    return (filter.value as ColumnOption<T>).text
  }

  return filter.value
}

export function getDisplayValueFromStored<T> (filter: StoredListFilter<T>) {
  if (Array.isArray(filter.value)) {
    return filter.value.join(', ')
  }

  return filter.value
}
