





















import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { v4 as uuid } from 'uuid'
import { FormFieldError } from '@focus/components'

@Component
export default class RadioGroupComponent extends Vue {
  @Prop({ type: Boolean, default: false })
  value!: boolean

  @Prop()
  readonly label!: string

  @Prop({ default: 'text' })
  readonly type!: string

  @Prop({ type: Boolean, default: false })
  readonly fetching!: boolean

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean

  @Prop({ type: Number, default: 1 })
  readonly tabIndex!: number

  @Prop({ type: Boolean, default: false })
  readonly required!: boolean

  @Prop({ type: Array, default: () => [] })
  readonly rules!: ((val: string) => string | true)[]

  @Prop({ type: Object, default: null })
  readonly error!: FormFieldError

  internalValue = false

  @Watch('value', { immediate: true })
  valueChanged (value: boolean) {
    this.internalValue = value
  }

  @Watch('internalValue')
  internalValueChanged (value: string) {
    this.$emit('input', value)
  }

  get fieldId () {
    return uuid()
  }

  get fieldRules () {
    const rules = []
    if (this.required) {
      rules.push(this.requiredRule)
    }

    return [...rules, ...this.rules]
  }

  get errorMessages () {
    if (!this.error) {
      return []
    }
    return [this.error?.message]
  }

  private requiredRule (val: boolean) {
    return (val === true || val === false) || 'Field is required'
  }
}
