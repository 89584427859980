import { ActionContext } from 'vuex'
import { RootState } from '@/store/store.types'

import axios from 'axios'
import { Screener, ApiResourceModule, ApiResourceState, ScreenerQuestion, SystemFile, ScreenerQuestionAnswer, ScreenerResponseStatus, deepClone } from '@focus/components'

interface AddFilesToScreenerPayload {
  question: ScreenerQuestion;
  answers: ScreenerQuestionAnswer[];
}

interface FileUploadPayload {
  question: ScreenerQuestion;
  files: File[];
}

interface FileRemovalPayload {
  question: ScreenerQuestion;
  file: SystemFile;
}

export interface AnswerPayload {
  screenerId: number;
  pageId: number;
  questions: ScreenerQuestion[];
}

class ScreenerModule extends ApiResourceModule<Screener, RootState> {
  constructor () {
    super('screeners')
  }

  get getters () {
    return {
      ...super.getters,
      getQuestionFilePath: this.getQuestionFilePath.bind(this)
    }
  }

  get mutations () {
    return {
      ...super.mutations,
      addFileToScreener: this.addFileToScreener.bind(this),
      dropFileFromScreener: this.dropFileFromScreener.bind(this),
      markScreenerAsTerminated: this.markScreenerAsTerminated.bind(this)
    }
  }

  get actions () {
    return {
      ...super.actions,
      getLayout: this.getLayout.bind(this),
      start: this.start.bind(this),
      answer: this.answer.bind(this),
      complete: this.complete.bind(this),
      uploadFiles: this.uploadFiles.bind(this),
      removeFile: this.removeFile.bind(this)
    }
  }

  getQuestionFilePath () {
    return (params: { screenerId: number; pageId: number; questionId?: number; jobId?: number }, file: SystemFile) => {
      const questionId = params.questionId || file.screenerQuestionId
      return `${axios.defaults.baseURL}/screeners/${params.screenerId}/pages/${params.pageId}/questions/${questionId}/files/${file.id}`
    }
  }

  private addFileToScreener = (state: ApiResourceState<Screener>, payload: AddFilesToScreenerPayload) => {
    const data = state.activeItem

    if (!data) {
      return
    }
    const pages = data.pages || []
    const page = pages.find(i => i.id === payload.question.pageId)
    const questions = page?.questions || []
    const question = questions.find(i => i.id === payload.question.id)
    const answers = question?.answers || []
    if (!page || !question) {
      return
    }
    answers.push(...payload.answers)
    question.answers = answers
    state.activeItem = deepClone(data)
  }

  private dropFileFromScreener = (state: ApiResourceState<Screener>, payload: FileRemovalPayload) => {
    const data = state.activeItem

    if (!data) {
      return
    }
    const pages = data.pages || []
    const page = pages.find(i => i.id === payload.question.pageId)
    const questions = page?.questions || []
    const question = questions.find(i => i.id === payload.question.id)
    const answers = question?.answers?.filter(i => i.file?.id !== payload.file.id)
    if (!page || !question || !question.answers) {
      return
    }
    question.answers = answers
    state.activeItem = { ...data }
  }

  private markScreenerAsTerminated (state: ApiResourceState<Screener>) {
    if (state.activeItem) {
      state.activeItem = { ...state.activeItem, responseStatus: ScreenerResponseStatus.HARD_TERMINATED }
    }
  }

  private async getLayout (context: ActionContext<ApiResourceState<Screener>, RootState>, payload: Screener) {
    try {
      const response = await axios.get<Screener>(`${context.getters.entityByIdRoute(payload.id)}/layout`)
      context.commit('setActive', response.data)
      return response.data
    } catch (error) {
      context.commit('setLoading', false)
      throw this.formatApiError(error)
    }
  }

  async start (context: ActionContext<ApiResourceState<Screener>, RootState>, payload: Screener) {
    try {
      const response = await axios.post(`${context.getters.entityByIdRoute(payload.id)}/start`)
      context.commit('setActive', { ...context.state.activeItem, responseStatus: ScreenerResponseStatus.IN_PROGRESS })
      return response.data
    } catch (error) {
      context.commit('setLoading', false)
      throw this.formatApiError(error)
    }
  }

  async answer (context: ActionContext<ApiResourceState<Screener>, RootState>, payload: AnswerPayload) {
    try {
      const data = {
        pageId: payload.pageId,
        questions: payload.questions?.map(question => {
          return {
            questionId: question.id,
            answers: question.answers
          }
        })
      }
      const response = await axios.post<{ terminated: boolean }>(`${context.getters.entityByIdRoute(payload.screenerId)}/answer`, data)
      return response.data
    } catch (error) {
      context.commit('setLoading', false)
      throw this.formatApiError(error)
    }
  }

  async complete (context: ActionContext<ApiResourceState<Screener>, RootState>, payload: { id: number }) {
    try {
      await axios.post<{ terminated: boolean }>(`${context.getters.entityByIdRoute(payload.id)}/complete`)
      context.commit('setActive', { ...context.state.activeItem, responseStatus: ScreenerResponseStatus.COMPLETED })
    } catch (error) {
      context.commit('setLoading', false)
      throw this.formatApiError(error)
    }
  }

  async uploadFiles (context: ActionContext<ApiResourceState<Screener>, RootState>, payload: FileUploadPayload) {
    try {
      const formData = new FormData()
      for (const file of payload.files) {
        formData.append('files', file)
      }
      const response = await axios.post<ScreenerQuestionAnswer[]>(`${context.getters.entityByIdRoute(payload.question.screenerId)}/questions/${payload.question.id}/upload`, formData, { headers: { 'content-type': 'multipart/form-data' } })
      context.commit('addFileToScreener', { question: payload.question, answers: response.data })
      return response.data
    } catch (error) {
      context.commit('setLoading', false)
      throw this.formatApiError(error)
    }
  }

  async removeFile (context: ActionContext<ApiResourceState<Screener>, RootState>, payload: FileRemovalPayload) {
    try {
      const response = await axios.delete(`${context.getters.entityByIdRoute(payload.question.screenerId)}/questions/${payload.question.id}/files/${payload.file.id}`)
      context.commit('dropFileFromScreener', payload)
      return response.data
    } catch (error) {
      context.commit('setLoading', false)
      throw this.formatApiError(error)
    }
  }
}

export default new ScreenerModule()
