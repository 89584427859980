import { RootState } from '@/store/store.types'
import { ApiResourceModule, ApiResourceState } from '@focus/components'

import { Job } from './jobs.types'
import { ActionContext } from 'vuex'
import axios from 'axios'

class JobsModule extends ApiResourceModule<Job, RootState> {
  namespaced = true

  constructor () {
    super('jobs')
  }

  get actions () {
    return {
      ...super.actions,
      registerInterest: this.registerInterest.bind(this)
    }
  }

  async registerInterest (context: ActionContext<ApiResourceState<Job>, RootState>, payload: { id: number}) {
    try {
      await axios.post(`${context.getters.entityBaseRoute()}/${payload.id}/register-interest`)
    } catch (error) {
      throw this.formatApiError(error)
    }
  }
}

export default new JobsModule()
