import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { configSetup } from './config-setup'

Vue.config.productionTip = false

configSetup().then((config) => {
  axios.defaults.baseURL = config.apiUrl
  axios.defaults.withCredentials = true

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
})
