








import Vue from 'vue'
import Component from 'vue-class-component'
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-2'
import Button from '../Button/Button.vue'

@Component({
  components: {
    Button,
    NodeViewContent,
    NodeViewWrapper
  },
  props: nodeViewProps
})
export default class EmailButton extends Vue {
  get buttonData () {
    return {
      color: this.$props.node.attrs.color,
      textColor: this.$props.node.attrs.textColor,
      isVariable: this.$props.node.attrs.isVariable,
      url: this.$props.node.attrs.url,
      uuid: this.$props.node.attrs.uuid
    }
  }

  get style () {
    if (this.buttonData.textColor) {
      return `color: ${this.buttonData.textColor};`
    }

    return ''
  }
}
