






































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import OptionImageDisplay from '../../OptionImageDisplay.vue'
import { ScreenerQuestion, ScreenerQuestionOption } from '../../models'
import { downloadFile } from '../../../../utilities/file.utilities'

@Component({
  components: { OptionImageDisplay }
})
export default class OptionImageButton extends Vue {
  @Prop({ required: true })
  private readonly question!: ScreenerQuestion;

  @Prop({ required: true })
  private readonly option!: ScreenerQuestionOption;

  @Prop({ type: Number, default: 0 })
  private readonly screenerId!: number

  @Prop({ type: Number, default: 0 })
  private readonly pageId!: number

  @Prop({ type: Boolean, default: false })
  private readonly locked!: boolean

  @Prop({ type: Boolean, default: true })
  private readonly allowFiles!: boolean

  @Prop({ type: Boolean, default: true })
  private readonly noFileEdits!: boolean

  get iconColour () {
    return this.option.image ? 'green' : null
  }

  get imageEvents () {
    if (this.locked) {
      return null
    }

    return {
      click: () => this.openFileSelect()
    }
  }

  openFileSelect () {
    const input = this.$refs.imageUpload as HTMLInputElement
    input.click()
  }

  removeImage () {
    if (!this.option.image || !this.option.id) {
      return
    }
    console.log('Remove Image')
    this.$emit('removeImage', { option: this.option, file: this.option.image })
  }

  uploadImageForOption (event: Event) {
    const target = event.target as HTMLInputElement
    if (target.files?.length) {
      const file = target.files[0]
      const image = {
        name: file.name,
        url: URL.createObjectURL(file),
        file
      }

      this.$emit('addImage', { option: this.option, file: image })
    }
  }

  downloadFile () {
    if (!this.option.image) {
      return
    }

    let imageUrl = ''
    if (this.option.image.url) {
      imageUrl = this.option.image.url
    } else {
      imageUrl = this.$store.getters['screeners/getQuestionFilePath']({ screenerId: this.screenerId, pageId: this.pageId }, this.option.image)
    }
    downloadFile(imageUrl)
  }
}
