





































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import { FormFieldError, SystemFile } from '../../../index.types'
import { valueUnchanged, deepClone } from '../../../utilities/helpers'
import BaseInput from './BaseInput.vue'
import OptionImageDisplay from '../OptionImageDisplay.vue'
import { getInputName } from '../layout-editor.utilities'
import { AnswerManager } from '../answer-manager'
import { ScreenerQuestionType, ScreenerQuestionAnswer, ScreenerQuestionOption, ScreenerQuestion, FileToUpload, ExternalLink } from '../models'
import { ScreenerBuilderQuestionErrors } from '../layout-editor.types'
import { overviewTextClass } from './views.constants'

@Component({
  components: { BaseInput, OptionImageDisplay }
})
export default class CheckboxInput extends Vue {
  @Prop()
  readonly question!: ScreenerQuestion

  @Prop()
  readonly order!: number

  @Prop()
  readonly text!: string

  @Prop({ type: Array, default: () => [] })
  private readonly files!: SystemFile[]

  @Prop({ type: Array, default: () => [] })
  private readonly links!: ExternalLink[]

  @Prop()
  readonly options!: ScreenerQuestionOption[]

  @Prop({ type: Array, default: () => [] })
  readonly answers!: ScreenerQuestionAnswer[]

  @Prop({ type: Object, default: null })
  readonly error!: FormFieldError

  @Prop({ type: Object, default: () => ({}) })
  private readonly builderError!: ScreenerBuilderQuestionErrors

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean

  @Prop({ type: Boolean, default: false })
  readonly editorView!: boolean

  @Prop({ type: Boolean, default: false })
  readonly terminatingOverview!: boolean

  @Prop({ type: Map, default: () => new Map() })
  readonly terminationMap!: Map<string, number>

  get hasImages () {
    return this.options.some(o => o.image)
  }

  get overviewTextClass () {
    return overviewTextClass
  }

  get errorMessages () {
    if (!this.error) {
      return []
    }
    return [this.error?.message]
  }

  get inputName () {
    return getInputName(ScreenerQuestionType.CHECKBOX)
  }

  selected: { [key: number]: string } = {}

  internalValue: ScreenerQuestionOption[] = []

  @Watch('options', { deep: true, immediate: true })
  private optionsChanged (value: ScreenerQuestionOption[], oldValue: ScreenerQuestionOption[]) {
    if (!valueUnchanged(value, oldValue)) {
      this.internalValue = deepClone(value)
    }
  }

  @Watch('selected', { deep: true })
  private selectedChanged (value: { [id: number]: string }, oldValue: { [id: number]: string }) {
    if (!valueUnchanged(value, oldValue)) {
      const initialAnswers = deepClone(this.answers)
      const keys = Object.keys(this.selected) as unknown[] as number[]
      const updatedAnswers = keys.reduce((answers, key) => {
        return this.saveAnswer(key, this.selected[key], answers)
      }, initialAnswers)

      if (!valueUnchanged(updatedAnswers, this.answers)) {
        this.$emit('answer', updatedAnswers)
      }
    }
  }

  mounted () {
    this.setSelected()
  }

  setSelected () {
    const base = this.options.reduce((map, option) => { return { ...map, [option.id]: 'false' } }, {})
    this.selected = this.answers.reduce((map, answer) => {
      if (answer.optionId) {
        return {
          ...map,
          [answer.optionId]: answer.value.toString()
        }
      }
      return map
    }, base)
  }

  select (option: ScreenerQuestionOption) {
    let value = 'true'
    if (!option.isSingle) {
      value = this.selected[option.id] === 'false' ? 'true' : 'false'
      this.internalValue.forEach(opt => {
        if (opt.isSingle && this.selected[opt.id] === 'true') {
          this.selected = { ...this.selected, [opt.id]: 'false' }
        }
      })
    } else {
      Object.keys(this.selected).forEach(key => {
        if (key !== option.id.toString()) {
          this.selected = { ...this.selected, [key]: 'false' }
        }
      })
    }

    this.selected = { ...this.selected, [option.id]: value }
  }

  saveAnswer (optionId: number, value: string, answers: ScreenerQuestionAnswer[]) {
    const index = answers.findIndex(i => i.optionId === +optionId)
    if (index === -1 && value === 'false') {
      return answers
    }

    if (index === -1) {
      return answers.concat({
        optionId: +optionId,
        value: value
      })
    } else {
      const result = [...answers]
      result.splice(index, 1, { ...answers[index], value: value })
      return result
    }
  }

  imageUrl (option: ScreenerQuestionOption) {
    if (!option.image) {
      return null
    }
    const image = option.image as FileToUpload
    if (image.url) {
      return image.url
    }
    return this.$store.getters['screeners/getQuestionFilePath']({ screenerId: this.question.screenerId, pageId: this.question.pageId }, option.image)
  }

  isAnswered () {
    return AnswerManager.isCheckboxAnswered(this.answers)
  }
}
