import deepEqual from 'deep-equal'
import cloneDeep from 'clone-deep'

export function valueUnchanged<T> (newValue: T, oldValue: T): boolean {
  return deepEqual(newValue, oldValue)
  // return deepEqual(newValue, oldValue, { strict: true })
}

export function deepClone<T> (value: T): T {
  return cloneDeep<T>(value, true)
}

/**
 * Performs a Fisher-Yates shuffle on a given set of values
 * @param source Source array to shuffle
 */
export function fisherYatesShuffle<T> (source: T[]): T[] {
  const shuffled = source.slice(0)
  let sourceLength = source.length
  while (sourceLength--) {
    const index = Math.floor((sourceLength + 1) * Math.random())
    const original = shuffled[index]
    shuffled[index] = shuffled[sourceLength]
    shuffled[sourceLength] = original
  }
  return shuffled
}

/**
 * Performs a Fisher-Yates shuffle and optionally returns a subset from the shuffle
 * @param source Source array to shuffle
 * @param [size] Number of elements to include in the subset
 */
export function fisherYatesShuffleSubset<T> (source: T[], size?: number): T[] {
  const shuffled = fisherYatesShuffle(source)
  return shuffled.slice(0, size)
}

export function repeatCha (character: string, count = 0) {
  let result = ''
  for (let i = 0; i < count; i++) {
    result += character
  }
  return result
}
