import { ErrorDetails, FormErrors, PreferredContactMethod, ValidationError } from '../index.types'
import { states } from './selectOptions'
import { DateTime } from 'luxon'

export function toDateTime (date: Date | string | number): DateTime {
  let dt
  if (typeof date === 'string') {
    dt = DateTime.fromISO(date)
  } else if (typeof date === 'number') {
    dt = DateTime.fromMillis(date)
  } else {
    dt = DateTime.fromJSDate(date)
  }
  return dt
}

export function displayBoolean (val?: boolean) {
  return val ? 'Yes' : 'No'
}

export function date (val?: Date | string) {
  if (!val) return '-'
  return toDateTime(val).toJSDate().toLocaleDateString()
}

export function dateTime (val?: Date | string | number) {
  if (!val) return '-'
  return toDateTime(val).toFormat('HH\':\'mm dd\'/\'LL\'/\'yyyy')
}

export function twelveHourDateTime (val?: Date | string) {
  if (!val) return '-'
  return toDateTime(val).toFormat('hh\':\'mma - dd\'/\'LL\'/\'yyyy')
}

export function capitalize (text?: string): string {
  if (!text) {
    return ''
  }

  if (text.includes('-')) {
    return text.split('-').map(capitalize).join(' ')
  }

  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`
}

export function dollar (value: number) {
  if (value === null || value === undefined) {
    return null
  }
  const num = +value

  if (Number.isNaN(num)) {
    return 'Invalid dollar value'
  }

  return `$${num.toFixed(2)}`
}

export function formatValidationError<T> (error: ErrorDetails<T>): FormErrors<T> {
  if (error?.code === 'VALIDATION_FAILED') {
    return error?.validationErrors?.reduce((errors: FormErrors<T>, value: ValidationError<T>) => {
      const constraint = { message: value.constraints[Object.keys(value.constraints)[0]] }
      return { ...errors, [value.field]: constraint }
    }, {}) || {}
  }
  return {}
}

export function verboseAccountType (type?: string) {
  switch (type) {
    case 'admin':
      return 'System Administrator'
    case 'manager':
      return 'Manager'
    case 'staff':
      return 'Staff Member'
    case 'respondent':
      return 'Respondent'
    case 'guest':
      return 'Guest'
    case 'list-recruit':
      return 'List Recruit'
    default:
      return 'Unknown'
  }
}

export function verboseState (state?: string) {
  switch (state) {
    case 'qld':
      return 'Queensland'
    case 'nsw':
      return 'New South Wales'
    case 'vic':
      return 'Victoria'
    case 'tas':
      return 'Tasmania'
    case 'act':
      return 'Australian Capital Territory'
    case 'sa':
      return 'South Australia'
    case 'wa':
      return 'Western Australia'
    case 'nt':
      return 'Northern Territory'
    default:
      return 'Unknown'
  }
}

export function verbosePreferredContact (type?: PreferredContactMethod) {
  switch (type) {
    case 'email':
      return 'Email'
    case 'phone':
      return 'Phone'
    case 'sms':
      return 'SMS'
    default:
      return 'Unknown'
  }
}

export function displayState (stateCode: string) {
  return states.find(state => state.value === stateCode)?.text || ''
}

export function displayYesNo (value: boolean) {
  return value ? 'Yes' : 'No'
}
