









import Vue from 'vue'
import { Prop, Component, Watch } from 'vue-property-decorator'

@Component
export default class Checkbox extends Vue {
  @Prop({ type: Boolean, default: () => false })
  readonly value!: boolean

  @Prop()
  readonly label!: string

  @Prop()
  readonly color!: string

  @Prop({ type: Boolean, default: false })
  readonly fetching!: boolean

  @Prop({ type: Boolean, default: false })
  readonly dense!: boolean

  @Prop({ type: Boolean, default: false })
  readonly hideDetails!: boolean

  @Prop({ type: Boolean, default: false })
  readonly error!: boolean

  @Prop({ type: Boolean, default: false })
  readonly required!: boolean

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  @Prop({ type: Array, default: () => [] })
  readonly rules!: ((value: Date) => boolean | string)[]

  internalValue = false

  get hasLabelSlot () {
    return !!this.$slots.label
  }

  get fieldRules () {
    if (this.required) {
      return this.rules.concat(this.requiredRule)
    }

    return this.rules
  }

  @Watch('value', { immediate: true })
  valueChanged (val: boolean) {
    if (this.internalValue !== val) {
      this.internalValue = val
    }
  }

  @Watch('internalValue')
  internalValueChanged (val: boolean) {
    this.$emit('input', val)
  }

  private requiredRule (val: Date) {
    return (val !== null && val !== undefined) || 'Field is required'
  }
}
