


















import Vue from 'vue'
import Component from 'vue-class-component'
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'

@Component({
  components: { NodeViewWrapper },
  props: nodeViewProps
})
export default class DataOption extends Vue {
  get attributes () {
    return this.$props.node.attrs as { key: string; name: string; capitalize: string }
  }

  get capitalize () {
    return this.attributes.capitalize === 'true'
  }

  set capitalize (value: boolean) {
    this.$props.updateAttributes({ capitalize: value ? 'true' : 'false' })
  }
}
