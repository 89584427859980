import { Module, MutationMethod } from 'vuex'
import axios, { AxiosError } from 'axios'
import { v4 as uuid } from 'uuid'
import { ValidationError } from '../index.types'

export type BaseResourceState = {
  isLoading: boolean;
}

type ApiErrorDetails = {
  code?: string;
  message?: string;
}

type ApiErrorData = {
  code: string;
  message: string;
  statusCode?: number;
  timeout?: number;
  validationErrors?: ValidationError<unknown>[];
}

export class ApiError extends Error {
  id: string
  code: string
  statusCode?: number
  validationErrors?: ValidationError<unknown>[]
  timeout: number

  constructor (data: ApiErrorData) {
    super(data.message)
    this.id = uuid()
    this.statusCode = data.statusCode
    if (data.timeout || data.timeout === 0) {
      this.timeout = data.timeout
    } else {
      this.timeout = 5000
    }
    this.code = data.code
    this.validationErrors = data.validationErrors
  }
}

export function formatApiError (error: Error | AxiosError<ApiErrorDetails>): ApiError {
  if (axios.isAxiosError(error)) {
    if (!error.response?.data.code) {
      switch (error.response?.status) {
        case 404:
          return new ApiError({ code: 'NOT_FOUND', message: 'Unable to find resource', statusCode: 404 })
        case 403:
          return new ApiError({ code: 'FORBIDDEN', message: 'You do not have permission to perform this action', statusCode: 403 })
        case 401:
          return new ApiError({ code: 'UNAUTHORISED', message: 'You are not authorised to perform this action', statusCode: 401 })
        case 500:
          return new ApiError({ code: 'INTERNAL_ERROR', message: 'An unknown error occurred performing this action', statusCode: 500 })
      }
    }
    return new ApiError({ code: error.response?.data.code || '', message: error.response?.data.message || 'Unknown error occurred', statusCode: error.response?.status })
  }
  throw error
}

export class BaseApiResourceModule<T, R> implements Module<BaseResourceState, R> {
  namespaced = true

  get mutations (): { [key: string]: MutationMethod } {
    return {
      setLoading: this.setLoading
    }
  }

  get state (): () => BaseResourceState {
    return () => ({
      isLoading: false
    })
  }

  formatApiError (error: Error | AxiosError<ApiErrorDetails>): ApiError {
    return formatApiError(error)
  }

  private setLoading = (state: BaseResourceState, payload: boolean) => {
    state.isLoading = payload
  }
}
