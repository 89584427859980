



























import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import { Card } from '../../../Card'
import Button from '../../../Button/Button.vue'
import Select from '../../../Select/Select.vue'
import TextField from '../../../TextField/TextField.vue'

import { Column, ListTableOptions } from '../../List.types'

@Component({
  components: { Card, Button, Select, TextField }
})
export default class PaginationOptions<T> extends Vue {
  @Prop()
  readonly options!: ListTableOptions<T>

  @Prop({ default: () => [] })
  readonly columns!: Column<T>[]

  itemsPerPage = 10

  itemsPerPageOptions = [1, 5, 10, 15, 25, 100]

  sortBy: Column<T> | null = null

  sortDesc: boolean | null = null

  @Watch('options', { immediate: true })
  optionsChanged (value: ListTableOptions<T>) {
    this.itemsPerPage = value.itemsPerPage as number
    const sortBy = (value.sortBy || []) as (keyof T)[]
    if (sortBy && sortBy.length) {
      this.sortBy = (this.columns.find(i => i.value === sortBy[0]) as Column<T>)
    }

    const sortDesc = (value.sortDesc || []) as boolean[]
    if (!sortDesc.length) {
      this.sortDesc = null
    } else {
      this.sortDesc = !!sortDesc[0]
    }
  }

  @Watch('sortBy')
  sortByChanged () {
    if (this.sortDesc === null) {
      this.sortDesc = false
    }
  }

  paginationChanged () {
    this.$emit('update:options', {
      page: 1,
      itemsPerPage: this.itemsPerPage,
      sortBy: this.sortBy ? [this.sortBy.value] : [],
      sortDesc: this.sortDesc === null ? [] : [this.sortDesc]
    })
  }
}
