export enum ScreenerPathingConfigurationItemType {
  QUESTION = 'question',
  PAGE = 'page'
}

export enum ParamOperator {
  EQUAL = 'eq',
  NOT_EQUAL = 'ne',
  GREATER_THAN = 'gt',
  GREATER_THAN_EQUAL = 'gte',
  LESS_THAN = 'lt',
  LESS_THAN_EQUAL = 'lte'
}

export const pathingConfigOperators: { html: string; value: ParamOperator }[] = [
  { html: '=', value: ParamOperator.EQUAL },
  { html: '&lt;', value: ParamOperator.LESS_THAN },
  { html: '&le;', value: ParamOperator.LESS_THAN_EQUAL },
  { html: '&gt;', value: ParamOperator.GREATER_THAN },
  { html: '&ge;', value: ParamOperator.GREATER_THAN_EQUAL }
]

export type ScreenerQuestionParameter = {
  optionId: number | string;
  rowId: number | string;
  operator: ParamOperator;
  value: string;
}

export type ScreenerPathingConfigurationItemParameter = ScreenerQuestionParameter & {
  id?: number;
}

export type ScreenerPathingConfigurationItem = {
  id?: number;
  type: ScreenerPathingConfigurationItemType;
  questionId?: number;
  targetQuestionId?: number | string;
  params: ScreenerPathingConfigurationItemParameter[];
}
