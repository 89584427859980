

















import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import Button from '../Button/Button.vue'
import { updateOnPageQuestions } from './layout-editor.utilities'
import {
  ScreenerQuestion,
  ScreenerPage,
  ScreenerQuestionAnswer,
  ScreenerQuestionType,
  ScreenerQuestionLoopAnswerType
} from './models'
import ScreenerQuestionComponent from './ScreenerQuestionComponent.vue'
import { SystemFile } from '../../index.types'
import { AnswerManager } from './answer-manager'

@Component({
  components: { Button, ScreenerQuestionComponent }
})
export default class ScreenerPageComponent extends Vue {
  @Prop()
  readonly page?: ScreenerPage

  @Prop({ type: Array, default: () => [] })
  readonly allQuestions!: ScreenerQuestion[]

  @Prop({ type: Map, default: () => new Map() })
  private visibleQuestionsByPage!: Map<number, Set<number>>

  @Prop({ type: String, default: 'Next' })
  readonly actionText!: string

  public visibleQuestionIds: Set<number> = new Set()

  private orderMap: Map<number, number> = new Map()

  @Watch('page')
  private pageUpdated (newValue: ScreenerPage, oldValue: ScreenerPage) {
    this.updateVisibility()
    if (newValue?.id !== oldValue?.id) {
      this.setOrderValues()
    }
  }

  mounted () {
    this.updateVisibility()
    this.setOrderValues()
  }

  setOrderValues (questions?: ScreenerQuestion[]) {
    this.orderMap.clear()
    const visibleQuestions = questions || this.visibleQuestions()
    visibleQuestions.forEach((_, index, _questions) => {
      const order = this.order(_questions, index)
      this.orderMap.set(index, order)
    })
    this.$forceUpdate()
  }

  questions (): ScreenerQuestion[] {
    return this.page?.questions || []
  }

  private firstQuestionOrder (): number {
    const firstQuestion = this.questions()[0]
    const allQuestionsSorted = this.allQuestions.filter(q => {
      const set = this.visibleQuestionsByPage.get(q.pageId || 0)
      if (set) {
        return set.has(q.id)
      }

      return this.visibleQuestionIds.has(q.id)
    })
    allQuestionsSorted.sort((a, b) => a.order - b.order)
    const index = allQuestionsSorted.findIndex(q => q.id === firstQuestion.id)
    return index + 1 || 0
  }

  public visibleQuestions (): ScreenerQuestion[] {
    const pageQuestions = this.questions()
    const questions = Array.from(this.visibleQuestionIds).reduce((array: ScreenerQuestion[], id) => {
      const q = pageQuestions.find(question => question.id === id)
      if (q) {
        return array.concat(q)
      }
      return array
    }, [])

    questions.sort((a, b) => a.order - b.order)
    return questions
  }

  private addVisibleQuestion (question: ScreenerQuestion, questions: ScreenerQuestion[]) {
    this.visibleQuestionIds.add(question.id)
    const newQuestions = updateOnPageQuestions(question, questions)

    if (newQuestions.length) {
      for (const child of newQuestions) {
        this.addVisibleQuestion(child, questions)
      }
    }
  }

  public updateVisibility () {
    const sortedQuestions = this.questions().slice()
    sortedQuestions.sort((a, b) => a.order - b.order)

    if (sortedQuestions.length) {
      this.visibleQuestionIds.clear()
      this.addVisibleQuestion(sortedQuestions[0], sortedQuestions)
    }
    this.$forceUpdate()
  }

  private allAnswered () {
    return this.visibleQuestions().every(question => AnswerManager.isAnswered(question, this.questions()))
  }

  public orderUsedCount (question: ScreenerQuestion, questions: ScreenerQuestion[]): number {
    const isLoopQuestionWithQuestionAnswerType = question.type === ScreenerQuestionType.LOOP && question.loopAnswerType === ScreenerQuestionLoopAnswerType.QUESTIONS
    if (!isLoopQuestionWithQuestionAnswerType) {
      return 1
    }

    const parentQuestion = questions.find(i => i.id === question.parentId)
    const validLoopParentAnswers = parentQuestion?.answers?.filter(answer => AnswerManager.validLoopParentAnswer(parentQuestion, answer))

    return validLoopParentAnswers?.length ?? 0
  }

  private order (questions: ScreenerQuestion[], index: number): number {
    if (index === 0) {
      return this.firstQuestionOrder()
    } else {
      const previousQuestion = questions[index - 1]
      return this.order(questions, index - 1) + this.orderUsedCount(previousQuestion, questions)
    }
  }

  private questionAnswered (question: ScreenerQuestion, answers: ScreenerQuestionAnswer[]) {
    this.$emit('answer', { ...question, answers })
    this.$nextTick().then(() => this.setOrderValues())
  }

  private uploadFiles (question: ScreenerQuestion, files: File[]) {
    this.$emit('upload', { question, files })
  }

  private removeFile (question: ScreenerQuestion, file: SystemFile) {
    this.$emit('removeFile', { question, file })
  }

  private cancel () {
    this.$emit('onCancel')
  }

  private nextPage () {
    this.$emit('nextPage', new Set(this.visibleQuestionIds))
  }
}
