










import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import Modal from '../../../Modal/Modal.vue'
import { SystemFile } from '../../../../index.types'
import { FileToUpload } from '../../models'

@Component({
  components: { Modal }
})
export default class ConfirmRemoveDocumentModal extends Vue {
  private file: SystemFile | FileToUpload | null = null

  private show = false

  private get text () {
    return `Are you sure you want to remove ${this.file?.name}?`
  }

  open (data: { file: SystemFile | FileToUpload }) {
    this.file = data.file
    this.show = true
  }

  close () {
    this.file = null
    this.show = false
  }

  private modalVisibilityUpdated (value: boolean) {
    if (!value) {
      this.close()
    }
  }

  private confirmRemoval () {
    this.$emit('remove', this.file)
    this.close()
  }
}
