








import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import DataOptionSelect from './DataOptionSelect.vue'
import Textarea from '../Textarea/Textarea.vue'

@Component({
  components: { DataOptionSelect, Textarea }
})
export default class BasicTemplateEditor extends Vue {
  @Prop({ type: String })
  private readonly type?: string

  @Prop({ type: String })
  private readonly value?: string

  get editor () {
    return this.$refs.editor as Textarea
  }

  get template () {
    return this.value
  }

  set template (text: string | undefined) {
    this.$emit('input', text)
  }

  insertVariable (data: { name: string; key: string }) {
    const variable = `{{ ${data.key} }}`
    const textArea = this.editor.$refs.textarea as Vue
    const input = textArea.$refs.input as HTMLInputElement
    const text = input.value.slice()
    const cursorIndex = input.selectionStart || 0
    const before = text.substr(0, cursorIndex)
    const after = text.substr(cursorIndex, text.length)

    this.template = before.concat(variable, after)
    this.$nextTick(() => {
      input.selectionStart = cursorIndex + variable.length
    })
  }
}
