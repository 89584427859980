import axios from 'axios'

export type RespondentAppConfig = {
  apiUrl: string;
}

export const configSetup = async (): Promise<RespondentAppConfig> => {
  if (process.env.NODE_ENV === 'production') {
    const result = await axios.get<RespondentAppConfig>('/config.json', { baseURL: '/' })

    if (typeof result.data !== 'object') {
      throw new Error('Invalid production config')
    }
    return result.data
  }

  if (!process.env.VUE_APP_API_URL) {
    throw new Error('API URL is required for this application')
  }

  const config: RespondentAppConfig = {
    apiUrl: process.env.VUE_APP_API_URL
  }

  return config
}
