










import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import { ListBadge } from './List.types'

@Component
export default class BadgeKey<T> extends Vue {
  @Prop({ type: Array, default: () => [] })
  private readonly badges!: ListBadge<T>[]
}
