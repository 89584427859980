







import { ScreenerQuestion } from '../models'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import Modal from '../../Modal/Modal.vue'
import { prettyJoin } from '../../../utilities/string.utilities'

@Component({
  components: { Modal }
})
export default class RemoveQuestionModal extends Vue {
  answerCount = 0

  dependantQuestions: ScreenerQuestion[] = []

  showRemoveConfirm = false

  get warnings () {
    const warnings: string[] = []
    if (this.answerCount) {
      warnings.push(`This will also remove ${this.answerCount} answers related to this question`)
    }

    if (this.dependantQuestions.length) {
      const questionNumbers = this.dependantQuestions.map(q => `Q${q.order}`)
      warnings.push(`This question has pathing configuration for ${prettyJoin(questionNumbers)}`)
    }

    return warnings
  }

  private confirm () {
    this.$emit('onRemove')
    this.close()
  }

  open (answerCount: number, dependantQuestions: ScreenerQuestion[] = []) {
    this.answerCount = answerCount
    this.dependantQuestions = dependantQuestions
    this.showRemoveConfirm = true
  }

  close () {
    this.answerCount = 0
    this.showRemoveConfirm = false
  }
}
