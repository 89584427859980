



























import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { v4 as uuid } from 'uuid'
import { FormFieldError } from '@focus/components'

@Component
export default class TextArea extends Vue {
  @Prop()
  private readonly value!: string

  @Prop()
  private readonly label!: string

  @Prop()
  private readonly hint!: string

  @Prop({ type: Boolean, default: false })
  private readonly persistentHint!: boolean

  @Prop({ default: 'text' })
  private readonly type!: string

  @Prop({ default: null })
  private readonly placeholder!: string

  @Prop({ default: null })
  private readonly prependIcon!: string

  @Prop({ type: Boolean, default: false })
  private readonly hideDetails!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly fetching!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly disabled!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly clearable!: boolean

  @Prop({ type: Number, default: 1 })
  private readonly tabIndex!: number

  @Prop({ type: Boolean, default: false })
  private readonly required!: boolean

  @Prop({ type: Array, default: () => [] })
  private readonly rules!: ((val: string) => string | true)[]

  @Prop({ type: Object, default: null })
  private readonly error!: FormFieldError

  internalValue: string | null = null

  valueDebounce = 0

  showPassword = false

  get fieldId () {
    return uuid()
  }

  get fieldRules () {
    if (this.required) {
      return [this.requiredRule, ...this.rules]
    }

    return this.rules
  }

  get fieldType () {
    if (this.type === 'password') {
      return this.showPassword ? 'text' : 'password'
    } else {
      return this.type
    }
  }

  get appendIcon () {
    if (this.type === 'password') {
      return this.showPassword ? 'visibility_off' : 'visibility'
    } else {
      return null
    }
  }

  get errorMessages () {
    if (!this.error) {
      return []
    }
    return [this.error?.message]
  }

  @Watch('value', { immediate: true })
  private valueChanged (value: string) {
    this.internalValue = value
  }

  @Watch('internalValue')
  private internalValueChanged (value: string) {
    clearTimeout(this.valueDebounce)

    this.valueDebounce = window.setTimeout(() => {
      this.$emit('input', value)
    }, 300)
  }

  private requiredRule (val: string) {
    return !!val || 'Field is required'
  }
}
