







import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { Banner, ErrorDetails } from '@focus/components'

@Component({
  components: { Banner }
})
export default class ErrorBanner<T> extends Vue {
  @Prop()
  readonly error!: ErrorDetails<T>

  get hasError () {
    return !!this.error
  }

  get errorMessage (): string {
    return this.error?.message
  }
}
