import { Gender, PersonClosureReason, PersonClosureReasonOption, PreferredContactMethod, State } from '../index.types'

export const baseStatuses = [{ text: 'Active', value: 'active' }, { text: 'Closed', value: 'closed' }]

export const yesNo = [
  { text: 'Yes', value: true },
  { text: 'No', value: false }
]

export const preferredContacts = [
  { text: 'Email', value: PreferredContactMethod.EMAIL },
  { text: 'Phone', value: PreferredContactMethod.PHONE },
  { text: 'SMS', value: PreferredContactMethod.SMS }
]

export const genders = [
  { text: 'Male', value: Gender.MALE },
  { text: 'Female', value: Gender.FEMALE },
  { text: 'Other', value: Gender.OTHER }
]

export const states = [
  { text: 'Queensland', value: State.QLD },
  { text: 'New South Wales', value: State.NSW },
  { text: 'Victoria', value: State.VIC },
  { text: 'Tasmania', value: State.TAS },
  { text: 'Australian Capital Territory', value: State.ACT },
  { text: 'South Australia', value: State.SA },
  { text: 'Western Australia', value: State.WA },
  { text: 'Northern Territory', value: State.NT }
]

export const closureReasons: PersonClosureReasonOption[] = [
  { value: PersonClosureReason.NOT_INTERESTED, text: 'Not Interested' },
  { value: PersonClosureReason.TOO_MANY_EMAILS, text: 'Too Many Emails' },
  { value: PersonClosureReason.NOT_RECEIVING_INVITATIONS, text: 'Not receiving invitations to participate' },
  { value: PersonClosureReason.RENUMERATIONS_INSUFFICIENT, text: 'Remuneration for participating is insufficient' },
  { value: PersonClosureReason.OTHER, text: 'Other' }
]
