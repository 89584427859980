import VueRouter from 'vue-router'
import { CombinedVueInstance } from 'vue/types/vue'
import sanitizeHtml from 'sanitize-html'
import { VBtn } from 'vuetify/lib'

const sanitizeOptions: sanitizeHtml.IOptions = {
  allowedTags: ['h1', 'h2', 'h3', 'p', 'span', 'button', 'strong', 'em', 's', 'u', 'ul', 'li', 'ol', 'img'],
  allowedAttributes: {
    '*': ['style', 'data-*'],
    img: ['src']
  }
}

export function sanitizeTemplate (template: string) {
  return sanitizeHtml(template, sanitizeOptions)
}

function buildAttributeMap (element: string) {
  const attributes: { [key: string]: string } = {}
  const attributeRegex = /([a-z-]*?)="(.*?)"/gm
  let validAttribute = attributeRegex.exec(element)
  while (validAttribute) {
    Object.assign(attributes, { [validAttribute[1]]: validAttribute[2] })
    validAttribute = attributeRegex.exec(element)
  }

  return attributes
}

function generateButton (props: { url: string; color: string; textColor?: string; content?: string }, options?: { router?: VueRouter }) {
  const button = new VBtn({ propsData: { href: props.url, color: props.color, depressed: true }, router: options?.router })
  let style = 'text-transform: none;'

  if (props.textColor) {
    style = style.concat(` color: ${props.textColor};`)
  }

  if (props.content) {
    button.$slots.default = [{ isRootInsert: true, isComment: false, text: props.content, tag: 'span', data: { attrs: { style } } }]
  }

  return button
}

type VuetifyButton = CombinedVueInstance<Vue, object, object, object, Record<never, any>> // eslint-disable-line

type VuetifyButtonMap = Map<string, VuetifyButton>

export type FormattedPageTemplateResult = {
  template: string;
  buttons: VuetifyButtonMap;
}

function runRegex (regex: RegExp, text: string) {
  const matches: string[] = []
  let match = regex.exec(text)
  while (match) {
    matches.push(match[0])
    match = regex.exec(text)
  }
  return matches
}

export function formatPageTemplateHtml (template?: string, router?: VueRouter): FormattedPageTemplateResult {
  if (!template) {
    return { template: '', buttons: new Map() }
  }

  let sanitizedTemplate = sanitizeTemplate(template)

  const buttonRegex = /<button .*?data-type="linkButton" ?.*?>.*?<\/button>/gm
  const matches = runRegex(buttonRegex, sanitizedTemplate)

  const buttons: VuetifyButtonMap = new Map<string, VuetifyButton>()
  if (matches.length) {
    for (const match of matches) {
      const buttonContentRegex = /(?<=<button.*?>).*?(?=<\/button>)/gm
      const buttonContent = buttonContentRegex.exec(match)?.toString()

      const attributes = buildAttributeMap(match)
      const url = attributes['data-url']
      const uuid = attributes['data-uuid']
      const color = attributes['data-color']
      const textColor = attributes['data-text-color']

      const buttonId = `page-template-button-${uuid}`
      const html = `<div id="${buttonId}">Button goes here</div>`
      const button = generateButton({ url, color, textColor, content: buttonContent }, { router })
      buttons.set(buttonId, button)

      sanitizedTemplate = sanitizedTemplate.replace(match, html)
    }
  }

  return { template: sanitizedTemplate, buttons }
}
