










import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class BannerComponent extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly value!: boolean

  @Prop()
  readonly message!: string

  @Prop()
  readonly color!: string
}
