export type FormFieldError = { message: string }

export enum State {
  QLD = 'qld',
  NSW = 'nsw',
  VIC = 'vic',
  TAS = 'tas',
  ACT = 'act',
  SA = 'sa',
  WA = 'wa',
  NT = 'nt'
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other'
}

export enum PreferredContactMethod {
  EMAIL = 'email',
  PHONE = 'phone',
  SMS = 'sms'
}

export enum JobRespondentAssessedSuitability {
  SUITABLE = 'suitable',
  NOT_SUITABLE = 'not-suitable',
  NOT_SET ='not-set'
}

export const jobRespondentAssessedSuitabilities = [
  { text: 'Suitable', value: JobRespondentAssessedSuitability.SUITABLE },
  { text: 'Not Suitable', value: JobRespondentAssessedSuitability.NOT_SUITABLE },
  { text: 'Not Set', value: JobRespondentAssessedSuitability.NOT_SET }
]

export enum ProfileQuality {
  HIGH = 'high',
  AVERAGE = 'average',
  LOW = 'low',
  BLACKLIST = 'blacklist'
}

export type ValidationConstraint = {
  [key: string]: string;
}

export type ValidationError<T> = {
  field: keyof T;
  value: any; // eslint-disable-line
  constraints: ValidationConstraint;
}

export type ErrorDetails<T> = {
  code: string;
  message: string;
  validationErrors?: ValidationError<T>[];
}

export type FormErrors<T> = {
  [k in keyof T]?: ErrorDetails<T>;
}

export enum PersonClosureReason {
  NOT_INTERESTED = 'not-interested',
  TOO_MANY_EMAILS = 'too-many-emails',
  NOT_RECEIVING_INVITATIONS = 'not-receiving-invitations',
  RENUMERATIONS_INSUFFICIENT = 'renumerations-insufficient',
  OTHER = 'other'
}

export enum PersonRole {
  GUEST = 'guest',
  RESPONDENT = 'respondent',
  STAFF = 'staff',
  MANAGER = 'manager',
  ADMIN = 'admin'
}

export type PersonClosureReasonOption = {
  text: string;
  value: PersonClosureReason;
}

export type SystemFile = {
  id: number;
  name: string;
  mimeType: string;
  url?: string;
  fileName: string;
  isPublic: boolean;
  sizeInBytes?: number;
  correspondenceId?: number;
  jobId?: number;
  screenerQuestionId?: number;
  screenerQuestionOptionId?: number;
  created: Date;
  updated: Date;
  width?: number;
  height?: number;
}
