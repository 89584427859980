import {
  ParamOperator,
  ScreenerPathingConfigurationItemParameter,
  ScreenerQuestionAnswer,
  ScreenerQuestionType
} from '../../models'
import { BaseDisplayModule } from './base'

export class MatrixDisplayModule extends BaseDisplayModule {
  run (type: ScreenerQuestionType) {
    return [ScreenerQuestionType.MATRIX, ScreenerQuestionType.MATRIX_DYNAMIC, ScreenerQuestionType.MATRIX_MULTI].includes(type)
  }

  valid (type: ScreenerQuestionType, param: ScreenerPathingConfigurationItemParameter, answers: ScreenerQuestionAnswer[]) {
    if (type === ScreenerQuestionType.MATRIX_MULTI && param.operator === ParamOperator.NOT_EQUAL) {
      return answers.every(answer => (answer.value !== param.value && param.optionId === answer.optionId) || param.optionId !== answer.optionId)
    }

    return answers.some(answer => {
      if (type === ScreenerQuestionType.MATRIX_MULTI) {
        switch (param.operator) {
          case ParamOperator.EQUAL:
            return param.rowId === answer.rowId && param.optionId === answer.optionId && answer.value === 'true'
          case ParamOperator.NOT_EQUAL:
            return param.rowId === answer.rowId && param.optionId === answer.optionId && answer.value === 'false'
          default:
            return false
        }
      }

      if (param.operator === ParamOperator.EQUAL) {
        return param.rowId === answer.rowId && param.optionId === answer.optionId
      } else {
        return param.rowId === answer.rowId && param.optionId !== answer.optionId
      }
    })
  }
}
