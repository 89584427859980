















import Vue from 'vue'
import { Prop, Component, Watch } from 'vue-property-decorator'
import Checkbox from '../Checkbox/Checkbox.vue'

@Component({
  components: { Checkbox }
})
export default class CheckList extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly value!: string[]

  @Prop({ type: Array, required: true })
  readonly items!: { value: string; label: string }[]

  @Prop()
  readonly color!: string

  @Prop({ type: Boolean, default: false })
  readonly fetching!: boolean

  @Prop({ type: Boolean, default: false })
  readonly dense!: boolean

  @Prop({ type: Boolean, default: false })
  readonly hideDetails!: boolean

  @Prop({ type: Boolean, default: false })
  readonly error!: boolean

  @Prop({ type: Boolean, default: false })
  readonly selectAll!: boolean

  selected: string[] = []

  get itemLength () {
    return this.items.length
  }

  get all (): boolean {
    return (this.selected.length >= this.itemLength)
  }

  set all (value: boolean) {
    if (value) {
      this.selected = this.items.map(i => i.value)
    } else if (this.selected.length >= this.itemLength) {
      this.selected = []
    }
  }

  @Watch('value', { immediate: true, deep: true })
  valueChanged (val: string[]) {
    if (this.selected !== val) {
      this.selected = val
    }
  }

  @Watch('selected', { deep: true })
  internalValueChanged (val: string[]) {
    this.$emit('input', val)
  }
}
