

















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import Button from '../../Button/Button.vue'
import BaseModal from '../../BaseModal/BaseModal.vue'
import Modal from '../../Modal/Modal.vue'
import FileUpload from '../../FileUpload/FileUpload.vue'
import TextField from '../../TextField/TextField.vue'
import OptionListModal from './OptionListModal/OptionListModal.vue'
import { ImageConfigurationOptions } from '../layout-editor.types'
import { ScreenerQuestionOption, ScreenerQuestion } from '../models'

@Component({
  components: { Button, BaseModal, FileUpload, Modal, TextField, OptionListModal }
})
export default class ImagePickerConfigurationOptionsComponent extends Vue {
  @Prop({ type: Object, default: () => ({ height: null, width: null }) })
  readonly value!: ImageConfigurationOptions

  @Prop({ type: Object, required: true })
  readonly question!: ScreenerQuestion

  @Prop({ type: Boolean, default: false })
  readonly locked!: boolean

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  @Prop({ type: Boolean, default: false })
  readonly loading!: boolean

  selectedFiles: File[] = []

  editOptions = false

  get modalTitle () {
    return 'Question Images'
  }

  get optionsModal () {
    return this.$refs.optionsModal as OptionListModal
  }

  openOptionsModal () {
    this.optionsModal.open(this.question.options)
  }

  saveOptions (options: ScreenerQuestionOption[]) {
    this.$emit('input', { ...this.value, options })
  }
}
