


















import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class OptionPasteMenu extends Vue {
  private show = false

  private content: string | null = null

  private cancel () {
    this.show = false
    this.content = null
  }

  confirm () {
    if (this.content) {
      this.$emit('confirm', this.content.trim())
    }
    this.cancel()
  }
}
