

















import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import { Card } from '../../Card'
import BaseModal from '../../BaseModal/BaseModal.vue'
import Button from '../../Button/Button.vue'
import TextField from '../../TextField/TextField.vue'

import FilterEdit from './FilterEdit/FilterEdit.vue'
import ColumnSelect from './ColumnSelect/ColumnSelect.vue'
import PaginationOptions from './PaginationOptions/PaginationOptions.vue'
import { Column, Favourite, ListFilter, ListCustomiseOptions, ListTableOptions } from '../List.types'
import { deepClone } from '../../../utilities/helpers'

type CustomiseModalData<T> = {
  favourite?: Favourite<T>;
  columns: Column<T>[];
  options: ListCustomiseOptions<T>;
}

@Component({
  components: { Card, Button, BaseModal, TextField, FilterEdit, ColumnSelect, PaginationOptions }
})
export default class CustomiseModal<T> extends Vue {
  private selectedFavourite: Favourite<T> | null = null

  private columns: Column<T>[] = []

  show = false

  favouriteName = ''

  selectedColumns: Column<T>[] = []

  filters: ListFilter<T>[] = []

  pagination: ListTableOptions<T> = {}

  get title () {
    if (!this.selectedFavourite) return 'Default'
    return this.selectedFavourite.name
  }

  get filterableColumns () {
    return this.columns.filter(i => i.filterable)
  }

  modalVisibilityUpdated (value: boolean) {
    if (!value) {
      this.close()
    }
  }

  close () {
    this.columns = []
    this.filters = []
    this.pagination = {}
    this.selectedColumns = []
    this.selectedFavourite = null
    this.favouriteName = ''
    this.show = false
  }

  open (data: CustomiseModalData<T>) {
    this.columns = deepClone(data.columns)
    this.selectedColumns = data.columns.filter(i => data.options.selectedColumns.includes(i.value))
    this.filters = deepClone(data.options.filters)
    this.pagination = deepClone(data.options.pagination)

    if (data.favourite) {
      this.selectedFavourite = deepClone(data.favourite)
      this.favouriteName = data.favourite.name
    }
    this.show = true
  }

  saveChanges () {
    const data: ListCustomiseOptions<T> = {
      filters: deepClone(this.filters),
      selectedColumns: this.selectedColumns.map(i => i.value),
      favouriteName: this.favouriteName,
      pagination: deepClone(this.pagination)
    }
    this.$emit('save', data)
    this.close()
  }
}
