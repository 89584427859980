








































import Vue from 'vue'
import { Prop, Component, Watch } from 'vue-property-decorator'
import { v4 as uuid } from 'uuid'

import { VuetifyMenu } from '../../index.types'
import { FormFieldError } from '../../../index.types'
import { toDateTime } from '../../../'
import { DateTime } from 'luxon'

@Component
export default class DateInput extends Vue {
  @Prop({ default: () => null })
  readonly label!: string

  @Prop()
  readonly value!: string

  @Prop()
  readonly placeholder!: string

  @Prop({ type: Boolean, default: false })
  readonly fetching!: boolean

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  @Prop({ type: Number, default: 1 })
  readonly tabIndex!: number

  @Prop({ type: Object, default: null })
  readonly error!: FormFieldError

  @Prop({ type: Array, default: () => [] })
  readonly rules!: ((val: string) => string | true)[]

  @Prop({ type: Boolean, default: false })
  private readonly solo!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly hideDetails!: boolean

  open = false

  invalidDateFormat = false

  pickerValue: string | null = null

  valueDebounce = 0

  get fieldId () {
    return uuid()
  }

  get displayValue () {
    if (!this.value) {
      return null
    }
    return toDateTime(this.value).toLocaleString(DateTime.DATE_SHORT)
  }

  get menu () {
    return this.$refs.menu as VuetifyMenu<string>
  }

  get errorMessages () {
    if (this.invalidDateFormat) {
      return ['Invalid date format']
    } else if (this.error) {
      return [this.error.message]
    }

    return []
  }

  @Watch('value', { immediate: true })
  valueChanged (value: string) {
    const dateString = this.dateString(value)
    this.pickerValue = dateString
    if (this.open) {
      this.menu.save(dateString)
    }
  }

  @Watch('pickerValue')
  internalValueChanged (newValue: string) {
    if (newValue === null) {
      this.$emit('input', null)
    } else if (newValue !== '') {
      this.$emit('input', new Date(newValue))
    }
  }

  clearField () {
    this.setField(null)
  }

  setField (value: string | null) {
    this.pickerValue = value
    this.menu.save(value)
  }

  updateField (value: string) {
    this.invalidDateFormat = false
    clearTimeout(this.valueDebounce)

    if (!value) {
      this.clearField()
      return
    }

    this.valueDebounce = window.setTimeout(() => {
      const formatted = this.formatDate(value)
      if (formatted) {
        this.setField(formatted)
      } else {
        this.invalidDateFormat = true
      }
    }, 300)
  }

  dateString (value: string | Date) {
    if (!value) {
      return null
    }
    return toDateTime(value).toISODate()
  }

  formatDate (value: string) {
    if (value.split('/').length !== 3) {
      return null
    }
    const [day, month, year] = value.split('/').map(p => {
      const val = +p
      if (Number.isNaN(val)) {
        return null
      }
      return val
    })

    if (!day || !month || !year) {
      return null
    }

    if (year.toString().length < 4) {
      return null
    }

    const date = DateTime.fromObject({ year, month, day }).toJSDate()
    return this.dateString(date)
  }
}
