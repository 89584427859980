




















import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

import BaseModal from '../BaseModal/BaseModal.vue'
import { Card } from '../Card'

import { ModalOption } from './OptionsModal.types'

@Component({ components: { BaseModal, Card } })
export default class OptionsModal extends Vue {
  @Prop()
  readonly value!: boolean

  @Prop()
  readonly title!: string

  @Prop({ default: () => [] })
  readonly options!: ModalOption[]

  get visibleOptions () {
    return this.options.filter(option => (option.visible === null || option.visible === undefined) || (option.visible && option.visible()))
  }

  select (item: ModalOption) {
    if (item.disabled) {
      return
    }
    this.$emit('onSelect', item.title)
    this.$emit('input', false)
  }
}
