






import Vue from 'vue'
import Color from 'color'
import { Prop, Component, Watch } from 'vue-property-decorator'

type HSVA = {
  h: number;
  s: number;
  v: number;
  a: number;
}

type HSLA = {
  h: number;
  s: number;
  l: number;
  a: number;
}

type RGBA = {
  r: number;
  g: number;
  b: number;
  a: number;
}

type ColorMode = 'rgba' | 'hsla' | 'hexa' | 'hex' | 'hsva'

type ColourValue = {
  alpha: number;
  hue: number;
  hex: string;
  hexa: string;
  hsla: HSLA;
  hsva: HSVA;
  rgba: RGBA;
}

@Component
export default class ColourPicker extends Vue {
  @Prop({ default: null })
  value!: string | null

  @Prop()
  private readonly label?: string

  @Prop({ type: Boolean, default: false })
  private readonly showSwatches!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly readonly!: boolean

  mode: ColorMode = 'hex'

  internalValue: ColourValue | null = null

  @Watch('value', { immediate: true })
  private valueChanged (newHex: string | null) {
    if (newHex !== this.internalValue?.hex) {
      const newColour = this.generateColorValue(newHex)
      this.internalValue = newColour
    }
  }

  @Watch('internalValue')
  private internalValueChanged (newValue: ColourValue | null) {
    this.$emit('input', newValue?.hex)
  }

  private generateColorValue (hex: string | null): ColourValue | null {
    if (!hex) {
      return null
    }
    const color = new Color(hex)
    const alpha = color.alpha()

    return {
      alpha,
      hue: color.hue(),
      hex: color.hex(),
      hexa: color.hexa(),
      hsla: { ...color.hsl().object(), a: alpha } as HSLA,
      hsva: { ...color.hsv().object(), a: alpha } as HSVA,
      rgba: { ...color.rgb().object(), a: alpha } as RGBA
    }
  }
}
