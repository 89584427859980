







import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { ModalOption } from '../../OptionsModal/OptionsModal.types'
import StatusModal from './StatusModal.vue'
import Button from '../../Button/Button.vue'

@Component({
  components: { Button, StatusModal }
})
export default class StatusTabDisplay extends Vue {
  @Prop({ default: 'Unknown' })
  readonly status!: string

  @Prop({ type: Array, default: () => [] })
  readonly options!: ModalOption[]

  get isMobile (): boolean {
    return this.$vuetify.breakpoint.smAndDown
  }

  showStatusModal = false
}
