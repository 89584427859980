














































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { v4 as uuid } from 'uuid'
import { FormFieldError } from '@focus/components'

@Component
export default class TextField extends Vue {
  @Prop()
  readonly value!: string

  @Prop()
  readonly label!: string

  @Prop({ default: 'text' })
  readonly type!: string

  @Prop({ default: null })
  readonly placeholder!: string

  @Prop({ default: null })
  readonly prependIcon!: string

  @Prop({ default: null })
  readonly suffix!: string

  @Prop({ default: null })
  readonly hint!: string

  @Prop({ default: null })
  private readonly helpText!: string;

  @Prop({ type: Boolean, default: false })
  readonly hideDetails!: boolean

  @Prop({ type: Boolean, default: false })
  readonly fetching!: boolean

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean

  @Prop({ type: Boolean, default: false })
  readonly clearable!: boolean

  @Prop({ type: Number, default: 1 })
  readonly tabIndex!: number

  @Prop({ type: Boolean, default: false })
  readonly required!: boolean

  @Prop()
  readonly autocomplete!: string

  @Prop({ type: Array, default: () => [] })
  readonly rules!: ((val: string) => string | true)[]

  @Prop({ type: Object, default: null })
  readonly error!: FormFieldError

  internalValue: string | null = null

  valueDebounce = 0

  showPassword = false

  get fieldId () {
    return uuid()
  }

  get fieldRules () {
    const rules = []
    if (this.required) {
      rules.push(this.requiredRule)
    }

    if (this.type === 'number') {
      rules.push(this.numberRule)
    }

    return [...rules, ...this.rules]
  }

  get fieldType () {
    if (this.type === 'password') {
      return this.showPassword ? 'text' : 'password'
    } else {
      return this.type
    }
  }

  get appendIcon () {
    if (this.type === 'password') {
      return this.showPassword ? 'visibility_off' : 'visibility'
    } else {
      return null
    }
  }

  get errorMessages () {
    if (!this.error) {
      return []
    }
    return [this.error?.message]
  }

  @Watch('value', { immediate: true })
  valueChanged (value: string) {
    this.internalValue = value
  }

  @Watch('internalValue')
  internalValueChanged (value: string) {
    clearTimeout(this.valueDebounce)

    this.valueDebounce = window.setTimeout(() => {
      switch (this.type) {
        case 'number':
          if (!Number.isNaN(+value) && value !== null && value !== '') {
            this.$emit('input', +value)
          } else {
            this.$emit('input', null)
          }
          break
        default:
          this.$emit('input', value)
          break
      }
    }, 30)
  }

  private requiredRule (val: string) {
    if (this.type === 'number' && !Number.isNaN(+val)) {
      return true
    } else if (val) {
      return true
    } else {
      return 'Field is required'
    }
  }

  private numberRule (val: string) {
    const isANumber = !Number.isNaN(+val)
    const isRequired = this.required
    if (!isANumber && isRequired) {
      return 'Field must be a valid number'
    }

    return true
  }
}
