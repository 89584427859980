import { RootState } from '@/store/store.types'
import { ApiResourceModule } from '@focus/components'
import axios from 'axios'
import { ActionContext } from 'vuex'

import { Group, GroupState } from './groups.types'

class GroupsModule extends ApiResourceModule<Group, RootState> {
  namespaced = true

  constructor () {
    super('job-groups')
  }

  get actions () {
    return {
      ...super.actions,
      upcoming: this.getUpcoming.bind(this),
      invitations: this.invitations.bind(this)
    }
  }

  async getUpcoming (context: ActionContext<GroupState, RootState>, payload: { id: number }) {
    context.commit('setLoading', true)
    try {
      const response = await axios.get<Group[]>(`/job-groups/respondent/${payload.id}/upcoming`)
      context.commit('setLoading', false)
      return response.data
    } catch (error) {
      context.commit('setLoading', false)
      throw this.formatApiError(error)
    }
  }

  async invitations () {
    try {
      const response = await axios.get<Group[]>('/jobs/invitations')
      return response.data
    } catch (error) {
      throw this.formatApiError(error)
    }
  }
}

export default new GroupsModule()
