









































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

import { Favourite, ListCustomiseOptions, Column, ListExportData } from '../List.types'
import { Card } from '../../Card'
import CardTitle from '../../CardTitle/CardTitle.vue'
import Button from '../../Button/Button.vue'
import Select from '../../Select/Select.vue'
import CustomiseModal from '../CustomiseModal/CustomiseModal.vue'
import FilterDisplay from '../FilterDisplay/FilterDisplay.vue'
import ExportModal from '../ExportModal/ExportModal.vue'
import { deepClone } from '../../../utilities/helpers'

@Component({
  components: { Card, Button, Select, CustomiseModal, FilterDisplay, ExportModal, CardTitle }
})
export default class ListHeader<T> extends Vue {
  @Prop({ default: 'List Header' })
  readonly title!: string

  @Prop({ default: () => [] })
  readonly favourites!: Favourite<T>[]

  @Prop({ default: () => [] })
  readonly columns!: Column<T>[]

  @Prop({ type: Boolean, default: false })
  readonly noFavourites!: boolean

  @Prop({ default: null })
  readonly selectedFavourite!: Favourite<T>

  @Prop()
  readonly customiseOptions!: ListCustomiseOptions<T>

  selectedFavouriteId: number | null = null

  showExportModal = false

  get smallScreen () {
    return this.$vuetify.breakpoint.mdAndDown
  }

  get mediumScreen () {
    return this.$vuetify.breakpoint.lgAndDown
  }

  get filters () {
    if (!this.customiseOptions) {
      return []
    }

    return this.customiseOptions.filters
  }

  get customiseModal () {
    return this.$refs.customiseModal as CustomiseModal<T>
  }

  @Watch('selectedFavourite', { immediate: true })
  initialFavouriteChanged (favourite?: Favourite<T>) {
    this.selectedFavouriteId = favourite?.id || null
  }

  refresh () {
    this.$emit('onRefresh')
  }

  reset () {
    this.selectedFavouriteId = null
    this.$emit('onReset')
  }

  openCustomiseModal () {
    const favourite = this.favourites.find(fav => fav.id === this.selectedFavouriteId)
    this.customiseModal.open({ columns: this.columns, options: this.customiseOptions, favourite })
  }

  openExportModal () {
    this.showExportModal = true
  }

  exportData (type: ListExportData) {
    this.$emit('onExport', type)
  }

  applyFavourite () {
    if (!this.selectedFavouriteId) return
    const favourite = this.favourites.find(fav => fav.id === this.selectedFavouriteId)
    this.$emit('onFavouriteSelected', deepClone(favourite))
  }

  saveOptions (data: ListCustomiseOptions<T>) {
    this.$emit('update:customiseOptions', data)
  }

  removeFilter (index: number) {
    this.customiseOptions.filters.splice(index, 1)
  }
}
