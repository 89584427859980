









import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import Button from '../../Button/Button.vue'
import BaseModal from '../../BaseModal/BaseModal.vue'
import Select from '../../Select/Select.vue'
import TextField from '../../TextField/TextField.vue'
import { getInputName } from '../layout-editor.utilities'

import MatrixConfigurationOptionsComponent from './MatrixOptions.vue'
import CheckboxConfigurationOptionsComponent from './CheckboxOptions.vue'
import RadioGroupConfigurationOptionsComponent from './RadioGroupOptions.vue'
import SelectConfigurationOptionsComponent from './SelectOptions.vue'
import RankingConfigurationOptions from './RankingOptions.vue'

import { LoopConfigurationOptions } from '../layout-editor.types'
import { ScreenerQuestion, ScreenerQuestionLoopAnswerType, ScreenerQuestionType } from '../models'
import { deepClone, valueUnchanged } from '../../../utilities/helpers'

@Component({
  components: { Button, BaseModal, Select, TextField, MatrixConfigurationOptionsComponent, CheckboxConfigurationOptionsComponent, RadioGroupConfigurationOptionsComponent, SelectConfigurationOptionsComponent, RankingConfigurationOptions }
})
export default class LoopConfigurationOptionsComponent extends Vue {
  @Prop()
  readonly value!: LoopConfigurationOptions

  @Prop({ type: Object, required: true })
  readonly question!: ScreenerQuestion

  @Prop({ type: Array, default: () => [] })
  readonly questions!: ScreenerQuestion[]

  @Prop({ type: Boolean, default: false })
  readonly locked!: boolean

  @Prop({ type: Boolean, default: false })
  readonly loading!: boolean

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  get modalTitle () {
    return this.locked ? 'View Dropdown Options' : 'Edit Dropdown Options'
  }

  get parentQuestion () {
    return this.validParents.find(i => (i.id || i.uuid!) === this.internalValue.parentId) // eslint-disable-line
  }

  validParentTypes: ScreenerQuestionType[] = [
    ScreenerQuestionType.CHECKBOX
  ]

  get usesParentAnswersAsOptions () {
    return this.question.loopAnswerType === ScreenerQuestionLoopAnswerType.OPTIONS
  }

  get questionTypes () {
    if (this.question.loopAnswerType === ScreenerQuestionLoopAnswerType.OPTIONS) {
      return [
        { text: getInputName(ScreenerQuestionType.SELECT), value: ScreenerQuestionType.SELECT },
        { text: getInputName(ScreenerQuestionType.RANKING), value: ScreenerQuestionType.RANKING },
        { text: getInputName(ScreenerQuestionType.MATRIX), value: ScreenerQuestionType.MATRIX },
        { text: getInputName(ScreenerQuestionType.MATRIX_DYNAMIC), value: ScreenerQuestionType.MATRIX_DYNAMIC },
        { text: getInputName(ScreenerQuestionType.MATRIX_MULTI), value: ScreenerQuestionType.MATRIX_MULTI }
      ]
    }

    return [
      { text: getInputName(ScreenerQuestionType.TEXTAREA), value: ScreenerQuestionType.TEXTAREA },
      { text: getInputName(ScreenerQuestionType.RATING), value: ScreenerQuestionType.RATING },
      { text: getInputName(ScreenerQuestionType.SELECT), value: ScreenerQuestionType.SELECT },
      { text: getInputName(ScreenerQuestionType.MATRIX), value: ScreenerQuestionType.MATRIX },
      { text: getInputName(ScreenerQuestionType.MATRIX_DYNAMIC), value: ScreenerQuestionType.MATRIX_DYNAMIC },
      { text: getInputName(ScreenerQuestionType.MATRIX_MULTI), value: ScreenerQuestionType.MATRIX_MULTI }
    ]
  }

  get answersTypes () {
    return [
      { text: 'Answers feed into new questions', value: ScreenerQuestionLoopAnswerType.QUESTIONS },
      { text: 'Answers feed into question options', value: ScreenerQuestionLoopAnswerType.OPTIONS }
    ]
  }

  get configurationOptionsComponent () {
    switch (this.value.type) {
      case ScreenerQuestionType.MATRIX:
      case ScreenerQuestionType.MATRIX_MULTI:
      case ScreenerQuestionType.MATRIX_DYNAMIC:
        return MatrixConfigurationOptionsComponent
      case ScreenerQuestionType.CHECKBOX:
        return CheckboxConfigurationOptionsComponent
      case ScreenerQuestionType.RADIO:
        return RadioGroupConfigurationOptionsComponent
      case ScreenerQuestionType.SELECT:
        return SelectConfigurationOptionsComponent
      case ScreenerQuestionType.RANKING:
        return RankingConfigurationOptions
    }
  }

  get validParents () {
    return this.questions.filter((q) => {
      return q.order < this.question.order && this.validParentTypes.includes(q.type)
    })
  }

  internalValue: Partial<LoopConfigurationOptions> = {}

  @Watch('value', { immediate: true, deep: true })
  private valueChanged (value: LoopConfigurationOptions, oldValue: LoopConfigurationOptions) {
    if (!valueUnchanged(value, oldValue)) {
      this.internalValue = deepClone(value)
    }
  }

  @Watch('internalValue', { deep: true })
  private internalValueChanged (value: LoopConfigurationOptions) {
    const newValue = deepClone({ ...this.value, ...value })

    if (!valueUnchanged(newValue, this.value)) {
      this.$emit('input', newValue)
    }
  }

  clearType () {
    this.internalValue.type = undefined
  }

  removeOptions () {
    this.internalValue = { ...this.internalValue, options: [] }
  }

  itemValue (item: ScreenerQuestion) {
    return item.id || item.uuid || ''
  }

  itemText (item: ScreenerQuestion) {
    return `Q${item.order}`
  }
}
