import {
  ScreenerQuestion,
  ScreenerQuestionAnswer,
  ScreenerQuestionLoopAnswerType,
  ScreenerQuestionOption, ScreenerQuestionOptionType,
  ScreenerQuestionType
} from './models'

export class AnswerManager {
  static validLoopParentAnswer (parent?: ScreenerQuestion, answer?: ScreenerQuestionAnswer): boolean {
    switch (parent?.type) {
      case ScreenerQuestionType.CHECKBOX:
        return answer?.value === 'true'
      default:
        return false
    }
  }

  static isCheckboxAnswered (answers: ScreenerQuestionAnswer[]): boolean {
    return answers.length > 0 && answers.some(i => i.value === 'true')
  }

  static isMultiMatrixAnswered (rows: ScreenerQuestionOption[], answers: ScreenerQuestionAnswer[]): boolean {
    return rows.every(row => answers.some(answer => answer.rowId === row.id && answer.value === 'true'))
  }

  static isRankingAnswered (answers: ScreenerQuestionAnswer[], options: ScreenerQuestionOption[], requiredCount = 0): boolean {
    const cutoffRank = (requiredCount || options.length) + 1
    for (let i = 1; i < cutoffRank; i++) {
      if (!answers.some(answer => answer.value === i.toString())) {
        return false
      }
    }
    return true
  }

  static isSingleSelectAnswered (answers: ScreenerQuestionAnswer[]): boolean {
    return answers.length > 0
  }

  static isRatingAnswered (answers: ScreenerQuestionAnswer[]): boolean {
    return answers.length > 0 && !!+answers[0].value
  }

  static isMatrixAnswered (rows: ScreenerQuestionOption[], answers: ScreenerQuestionAnswer[]): boolean {
    return rows.length === answers.length
  }

  static isDynamicMatrixAnswered (answers: ScreenerQuestionAnswer[]): boolean {
    return answers.length > 0 && answers.every(i => !!i.value && !!i.rowId)
  }

  static hasFilesAttached (answers: ScreenerQuestionAnswer[]): boolean {
    return answers.filter(i => !!i.file).length > 0
  }

  static isLoopAnswered (question: ScreenerQuestion, parent?: ScreenerQuestion): boolean {
    if (!parent) {
      return false
    }

    const parentAnswers = parent.answers?.filter(answer => this.validLoopParentAnswer(parent, answer)) || []
    if (question.loopAnswerType === ScreenerQuestionLoopAnswerType.QUESTIONS) {
      return this.isQuestionLoopAnswered(question, parentAnswers)
    } else {
      return this.isOptionLoopAnswered(question, parent, parentAnswers)
    }
    return false
  }

  private static isOptionLoopAnswered (question: ScreenerQuestion, parent: ScreenerQuestion, parentAnswers: ScreenerQuestionAnswer[]) {
    const options = parentAnswers.reduce((options, answer) => {
      const option = parent.options.find(option => option.id === answer.optionId)
      if (option) {
        if (question.loopType && [ScreenerQuestionType.MATRIX, ScreenerQuestionType.MATRIX_MULTI, ScreenerQuestionType.MATRIX_DYNAMIC].includes(question.loopType)) {
          return options.concat({ ...option, type: ScreenerQuestionOptionType.ROW })
        }
        return options.concat(option)
      }
      return options
    }, question.options || [])
    return this.isAnswered({ type: question.loopType, options, answers: question.answers } as ScreenerQuestion)
  }

  private static isQuestionLoopAnswered (question: ScreenerQuestion, parentAnswers: ScreenerQuestionAnswer[]): boolean {
    const questionAnswers = question.answers || []
    return parentAnswers.every(answer => {
      const relatedAnswers = questionAnswers.filter(a => a.parentOptionId === answer.optionId)
      return this.isAnswered({ type: question.loopType, options: question.options, answers: relatedAnswers } as ScreenerQuestion)
    })
  }

  static isAnswered (question: ScreenerQuestion, questions: ScreenerQuestion[] = []): boolean {
    const answers = question.answers || []
    const rows = question.options.filter(i => i.type === ScreenerQuestionOptionType.ROW)
    switch (question.type) {
      case ScreenerQuestionType.CHECKBOX:
      case ScreenerQuestionType.IMAGE:
      case ScreenerQuestionType.IMAGE_SINGLE:
        return this.isCheckboxAnswered(answers)
      case ScreenerQuestionType.MATRIX_MULTI:
        return this.isMultiMatrixAnswered(rows, answers)
      case ScreenerQuestionType.MATRIX_DYNAMIC:
        return this.isDynamicMatrixAnswered(answers)
      case ScreenerQuestionType.MATRIX:
        return this.isMatrixAnswered(rows, answers)
      case ScreenerQuestionType.RADIO:
      case ScreenerQuestionType.SELECT:
      case ScreenerQuestionType.TEXTAREA:
      case ScreenerQuestionType.TEXTFIELD:
      case ScreenerQuestionType.SLIDER:
        return this.isSingleSelectAnswered(answers)
      case ScreenerQuestionType.RATING:
        return this.isRatingAnswered(answers)
      case ScreenerQuestionType.FILE:
        return this.hasFilesAttached(answers)
      case ScreenerQuestionType.LOOP: {
        const parent = questions.find(i => i.id === question.parentId)
        return this.isLoopAnswered(question, parent)
      }
      case ScreenerQuestionType.RANKING:
        return this.isRankingAnswered(answers, question.options, question.requiredOptionCount)
      default:
        return false
    }
  }
}
