






















import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

import CardTitle from '../CardTitle/CardTitle.vue'

/**
 * Cool card component
 */
@Component({ components: { CardTitle } })
export default class Card extends Vue {
  @Prop({ default: '' })
  private readonly title!: string;

  @Prop({ default: '' })
  private readonly subtitle!: string

  @Prop({ default: null })
  private readonly maxWidth!: string | number;

  @Prop()
  private readonly color?: string

  @Prop({ type: Boolean, default: false })
  private readonly titleSmall!: boolean;

  @Prop({ type: Boolean, default: false })
  private readonly borderless!: boolean;

  @Prop({ type: Boolean, default: false })
  private readonly clickable!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly disabled!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly collapsable!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly error!: boolean

  @Prop({ type: String, required: false })
  private readonly bodyClass!: string;

  private get events () {
    if (this.clickable) {
      return { click: () => this.$emit('onClick') }
    }

    return null
  }

  private showContent = true

  toggleShow () {
    this.showContent = !this.showContent
  }
}
