
































import { RouterLocation } from '../index.types'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import { Card } from '../Card'

/**
 * Base Button component
 */
@Component({ components: { Card } })
export default class Button extends Vue {
  @Prop({ default: null })
  private readonly text!: string

  @Prop()
  private readonly textColor?: string

  @Prop()
  private readonly color!: string

  @Prop({ type: Boolean, default: false })
  private readonly dark!: boolean

  @Prop()
  private readonly type!: string

  @Prop({ type: Number, default: 2 })
  readonly tabIndex!: number

  @Prop({ type: Boolean, default: false })
  readonly outlined!: boolean

  @Prop({ type: Boolean, default: false })
  readonly textButton!: boolean

  @Prop({ type: Boolean, default: false })
  readonly small!: boolean

  @Prop({ type: Boolean, default: false })
  readonly icon!: boolean

  @Prop()
  readonly to!: string | RouterLocation

  @Prop({ type: Boolean, default: false })
  readonly tile!: boolean

  @Prop({ type: Boolean, default: false })
  readonly loading!: boolean

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  @Prop({ type: Boolean, default: false })
  readonly noTransform!: boolean

  @Prop({ type: Boolean, default: false })
  readonly block!: boolean

  @Prop({ type: Boolean, default: false })
  readonly fixed!: boolean

  @Prop({ type: Boolean, default: false })
  readonly fab!: boolean

  @Prop({ type: Boolean, default: false })
  readonly top!: boolean

  @Prop({ type: Boolean, default: false })
  readonly left!: boolean

  @Prop({ type: Boolean, default: false })
  readonly bottom!: boolean

  @Prop({ type: Boolean, default: false })
  readonly right!: boolean

  @Prop({ type: Boolean, default: false })
  readonly noPadding!: boolean

  @Prop({ type: Boolean, default: false })
  readonly inputValue!: boolean

  get navigationLink () {
    if (this.to) {
      if (typeof this.to === 'string') {
        return { name: this.to }
      } else {
        return this.to
      }
    } else {
      return null
    }
  }

  onClick (click: MouseEvent) {
    this.$emit('onClick', click)
  }
}
