









import Vue from 'vue'
import { Prop, Component, Watch } from 'vue-property-decorator'
import Checkbox from '../Checkbox/Checkbox.vue'

type CheckboxGroupValue = boolean | null;

@Component({
  components: { Checkbox }
})
export default class CheckboxGroup extends Vue {
  @Prop({ type: Boolean, default: () => null })
  readonly value!: CheckboxGroupValue

  @Prop()
  readonly label!: string

  @Prop()
  readonly color!: string

  @Prop({ type: Boolean, default: false })
  readonly fetching!: boolean

  @Prop({ type: Boolean, default: false })
  readonly required!: boolean

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  internalValue: CheckboxGroupValue = null

  yes = false

  no = false

  error = false

  @Watch('value', { immediate: true })
  valueChanged (val: CheckboxGroupValue, oldVal: CheckboxGroupValue) {
    if (val === true) {
      this.internalValue = true
    } else if (val === false) {
      this.internalValue = false
    } else {
      this.internalValue = null
    }

    if (this.required && oldVal !== undefined) {
      this.error = this.internalValue === null
    }
  }

  yesChanged (val: boolean) {
    if (val) {
      this.internalValue = true
    } else if (!this.no) {
      this.internalValue = null
    }
  }

  noChanged (val: boolean) {
    if (val) {
      this.internalValue = false
    } else if (!this.yes) {
      this.internalValue = null
    }
  }

  @Watch('internalValue')
  internalValueChanged (val: CheckboxGroupValue) {
    if (val === true) {
      this.yes = true
      this.no = false
    } else if (val === false) {
      this.no = true
      this.yes = false
    } else {
      this.yes = false
      this.no = false
    }
    this.$emit('input', val)
  }
}
