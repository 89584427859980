




























































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { ScreenerQuestion, ExternalLink } from '../../models'
import BaseModal from '../../../BaseModal/BaseModal.vue'
import Button from '../../../Button/Button.vue'
import TextField from '../../../TextField/TextField.vue'
import { deepClone } from '../../../../utilities/helpers'

@Component({
  components: { BaseModal, Button, TextField }
})
export default class ExternalLinkModal extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly locked!: boolean

  private show = false

  private question: ScreenerQuestion | null = null

  private linkText = ''

  private addMore = false

  private get actionColWidth () {
    const width = this.locked ? '60px' : '120px'
    return `width: ${width};`
  }

  private get links () {
    if (!this.question) {
      return []
    }

    return this.question.links || []
  }

  public get isOpen () {
    return this.show
  }

  public open (data: { question: ScreenerQuestion }) {
    const questionData = { ...data.question, links: data.question.links || [] }
    this.question = deepClone(questionData)
    this.show = true
  }

  public close () {
    this.question = null
    this.addMore = false
    this.show = false
  }

  public updateQuestion (data: { question: ScreenerQuestion }) {
    if (this.show) {
      this.question = deepClone(data.question)
    }
  }

  private modalVisibilityUpdated (value: boolean) {
    if (!value) {
      this.close()
    }
  }

  private addMoreLinks () {
    this.addMore = true
  }

  formatPath (link: ExternalLink) {
    try {
      const url = new URL(link.url)
      return url
    } catch (error) {
      this.$set(link, 'invalid', true)
    }
  }

  private addLink () {
    if (!this.linkText || !this.question) {
      return
    }
    const links = deepClone(this.question.links || [])
    links.push({ url: this.linkText })
    this.question.links = links
    this.linkText = ''
    this.$forceUpdate()
  }

  private removeLink (link: ExternalLink) {
    if (this.question?.links) {
      this.question.links = this.question.links.filter(l => l !== link)
    }
  }

  private save () {
    this.$emit('save', this.question?.links)
    this.close()
  }
}
