























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { FormFieldError, SystemFile } from '../../../index.types'
import BaseInput from './BaseInput.vue'
import { getInputName } from '../layout-editor.utilities'
import { AnswerManager } from '../answer-manager'
import { ScreenerQuestion, ScreenerQuestionType, ScreenerQuestionAnswer, ScreenerQuestionOption, FileToUpload, ExternalLink } from '../models'
import { ScreenerBuilderQuestionErrors } from '../layout-editor.types'
import { deepClone, valueUnchanged } from '../../../utilities/helpers'
import { overviewTextClass } from './views.constants'
import OptionImageDisplay from '../OptionImageDisplay.vue'

@Component({
  components: { OptionImageDisplay, BaseInput }
})
export default class ImagePickerInput extends Vue {
  @Prop()
  readonly question!: ScreenerQuestion

  @Prop()
  readonly order!: number

  @Prop()
  readonly text!: string

  @Prop({ type: Array, default: () => [] })
  private readonly files!: SystemFile[]

  @Prop({ type: Array, default: () => [] })
  private readonly links!: ExternalLink[]

  @Prop()
  readonly height?: number

  @Prop()
  readonly width?: number

  @Prop({ type: Array, default: () => [] })
  readonly options!: ScreenerQuestionOption[]

  @Prop({ type: Array, default: () => [] })
  readonly answers!: ScreenerQuestionAnswer[]

  @Prop({ type: Boolean, default: false })
  readonly singleSelect!: boolean

  @Prop({ type: Object, default: null })
  readonly error!: FormFieldError

  @Prop({ type: Object, default: () => ({}) })
  private readonly builderError!: ScreenerBuilderQuestionErrors

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean

  @Prop({ type: Boolean, default: false })
  readonly editorView!: boolean

  @Prop({ type: Boolean, default: false })
  readonly terminatingOverview!: boolean

  @Prop({ type: Map, default: () => new Map() })
  readonly terminationMap!: Map<string, number>

  get overviewTextClass () {
    return overviewTextClass
  }

  get errorMessages () {
    if (!this.error) {
      return []
    }
    return [this.error?.message]
  }

  get inputName () {
    return getInputName(ScreenerQuestionType.IMAGE)
  }

  get validOptions (): ScreenerQuestionOption[] {
    return this.options.sort((a, b) => a.order - b.order).filter(i => !!i.image)
  }

  imageUrl (option: ScreenerQuestionOption) {
    if (!option.image) {
      return null
    }
    const image = option.image as FileToUpload
    if (image.url) {
      return image.url
    }
    return this.$store.getters['screeners/getQuestionFilePath']({ screenerId: this.question.screenerId, pageId: this.question.pageId }, option.image)
  }

  cardEvents (option: ScreenerQuestionOption) {
    if (this.readonly || this.editorView) {
      return {}
    } else {
      return {
        click: () => this.selectOption(option)
      }
    }
  }

  selectOption (option: ScreenerQuestionOption) {
    const answers = deepClone(this.answers)
    const index = answers.findIndex(i => i.optionId === option.id)
    if (this.singleSelect) {
      answers.forEach((answer) => {
        answer.value = 'false'
      })
    }

    if (index > -1) {
      answers[index] = { ...answers[index], value: answers[index].value !== 'true' ? 'true' : 'false' }
    } else {
      answers.push({
        optionId: option.id,
        value: 'true'
      })
    }

    if (!valueUnchanged(answers, this.answers)) {
      this.$emit('answer', answers)
    }
  }

  isSelected (option: ScreenerQuestionOption) {
    const answer = this.answers.find(answer => answer.optionId === option.id)
    return answer?.value === 'true'
  }

  isAnswered () {
    return AnswerManager.isCheckboxAnswered(this.answers)
  }
}
