












import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { v4 as uuid } from 'uuid'

import DateInput from './DateInput/DateInput.vue'
import TimeInput from './TimeInput/TimeInput.vue'
import Button from '../Button/Button.vue'
import { FormFieldError } from '../../index.types'
import { toDateTime } from '../../'
import { DateTime } from 'luxon'

@Component({
  components: { DateInput, TimeInput, Button }
})
export default class Datepicker extends Vue {
  @Prop({ default: null })
  readonly label!: string

  @Prop()
  readonly value!: Date

  @Prop({ default: 'date' })
  readonly type!: 'date' | 'datetime'

  @Prop({ type: Boolean, default: false })
  readonly fetching!: boolean

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  @Prop({ type: Boolean, default: false })
  readonly required!: boolean

  @Prop({ type: Boolean, default: false })
  readonly stretch!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly noClear!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly solo!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly hideDetails!: boolean

  @Prop({ type: Object, default: null })
  readonly error!: FormFieldError

  @Prop({ type: Array, default: () => [] })
  readonly rules!: ((value: Date) => boolean | string)[]

  dateTime: DateTime | null = null

  get fieldId () {
    return uuid()
  }

  get fieldRules () {
    if (this.required) {
      return this.rules.concat(this.requiredRule)
    }

    return this.rules
  }

  @Watch('value', { immediate: true })
  valueChanged (newValue: Date, oldValue: Date) {
    if (!newValue) {
      this.dateTime = null
      return
    }

    const oldDateTime = oldValue ? toDateTime(oldValue) : null
    const newDateTime = toDateTime(newValue)

    if (oldDateTime && newDateTime.diff(oldDateTime).toMillis() === 0) {
      return
    }

    this.dateTime = newDateTime
  }

  @Watch('dateTime')
  dateTimeChanged (newValue: DateTime, oldValue: DateTime) {
    if (+newValue !== +oldValue) {
      this.$emit('input', newValue?.toJSDate() || null)
    }
  }

  get internalDate () {
    return this.dateTime?.toISODate()
  }

  set internalDate (value: string | undefined) {
    if (!value) {
      return
    }
    const dt = toDateTime(value)
    const date = { year: dt.year, month: dt.month, day: dt.day }
    this.dateTime = this.dateTime ? this.dateTime.set(date) : DateTime.fromObject(date)
  }

  get internalTime () {
    if (!this.dateTime) {
      return ''
    }
    const isoTime = this.dateTime.toISOTime()
    const timeParts = isoTime.split(':')
    return timeParts.slice(0, 2).join(':')
  }

  set internalTime (value: string | undefined) {
    if (!value) {
      return
    }
    const dt = toDateTime(value)
    const time = { hour: dt.hour, minute: dt.minute, second: dt.second }
    this.dateTime = this.dateTime ? this.dateTime.set(time) : DateTime.fromObject(time)
  }

  clearFields () {
    this.dateTime = null
  }

  private requiredRule (val: Date) {
    return (val !== null && val !== undefined) || 'Field is required'
  }
}
