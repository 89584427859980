




































































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import { FormFieldError, SystemFile } from '../../../index.types'
import BaseInput from './BaseInput.vue'
import Button from '../../Button/Button.vue'
import { getInputName } from '../layout-editor.utilities'
import { AnswerManager } from '../answer-manager'
import { ScreenerQuestionType, ScreenerQuestionOption, ScreenerQuestionAnswer, ExternalLink } from '../models'
import { ScreenerBuilderQuestionErrors } from '../layout-editor.types'
import { overviewTextClass } from './views.constants'

@Component({
  components: { BaseInput, Button }
})
export default class DynamicMatrixInput extends Vue {
  @Prop()
  readonly order!: number

  @Prop()
  readonly text!: string

  @Prop({ type: Array, default: () => [] })
  private readonly files!: SystemFile[]

  @Prop({ type: Array, default: () => [] })
  private readonly links!: ExternalLink[]

  @Prop({ type: Array, default: () => [] })
  readonly rows!: ScreenerQuestionOption[]

  @Prop({ type: Array, default: () => [] })
  readonly columns!: ScreenerQuestionOption[]

  @Prop({ type: Boolean, default: false })
  readonly unique!: boolean

  @Prop({ type: Object, default: null })
  readonly error!: FormFieldError

  @Prop({ type: Object, default: () => ({}) })
  private readonly builderError!: ScreenerBuilderQuestionErrors

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean

  @Prop({ type: Boolean, default: false })
  readonly editorView!: boolean

  @Prop({ type: Array, default: () => [] })
  readonly answers!: ScreenerQuestionAnswer[]

  @Prop({ type: Boolean, default: false })
  readonly terminatingOverview!: boolean

  @Prop({ type: Map, default: () => new Map() })
  readonly terminationMap!: Map<string, number>

  internalValue: ScreenerQuestionAnswer[] = []

  rowValueMap: { [key: number]: string } = {}

  get overviewTextClass () {
    return overviewTextClass
  }

  get lockEdit () {
    return this.readonly || this.editorView
  }

  get errorMessages () {
    if (!this.error) {
      return []
    }
    return [this.error?.message]
  }

  get inputName () {
    return getInputName(ScreenerQuestionType.MATRIX_DYNAMIC)
  }

  get columnCount () {
    return this.columns.length + 2
  }

  get buttonText () {
    if (this.internalValue.length) {
      return 'Add another row'
    } else {
      return 'Add row'
    }
  }

  get canAddMore () {
    if (!this.unique) {
      return true
    }

    if (this.internalValue.length === this.rows.length) {
      return false
    }

    return this.rows.some(option => this.internalValue.every(value => value.rowId !== option.id))
  }

  @Watch('answers', { immediate: true })
  private answersChanged (value: ScreenerQuestionAnswer[]) {
    this.internalValue = value.map(i => ({ ...i }))
  }

  validRowOptions (row: ScreenerQuestionAnswer): ScreenerQuestionOption[] {
    if (!this.unique) {
      return this.rows
    } else {
      const selectedOptionIds = this.internalValue.map(i => i.rowId)
      return this.rows.filter(i => !selectedOptionIds.includes(i.id) || i.id === row.rowId)
    }
  }

  addRow () {
    this.internalValue.push({ value: '' })
  }

  removeRow (row: ScreenerQuestionAnswer) {
    this.internalValue = this.internalValue.filter(val => val !== row)
  }

  updateAnswers () {
    this.$nextTick().then(() => {
      this.$emit('answer', this.internalValue.map(answer => {
        return {
          ...answer,
          value: answer.optionId?.toString() || answer.value
        }
      }))
    })
  }

  isAnswered () {
    return AnswerManager.isDynamicMatrixAnswered(this.answers)
  }
}
