































import { ScreenerQuestion, ScreenerQuestionTermination, TerminationOperator, TerminationType } from '../../models'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import BaseModal from '../../../BaseModal/BaseModal.vue'
import Button from '../../../Button/Button.vue'
import { deepClone } from '../../../../utilities/helpers'

import TerminationItem from './TerminationItem/TerminationItem.vue'

type ScreenerQuestionData = Omit<ScreenerQuestion, 'terminations'> & { terminations: Partial<ScreenerQuestionTermination>[] }

@Component({
  components: { BaseModal, Button, TerminationItem }
})
export default class PathingConfigurationModal extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly locked!: boolean

  private question: ScreenerQuestionData | null = null

  private parent: ScreenerQuestion | null = null

  private show = false

  private get title () {
    return `Terminations: Q${this.question?.order || 0}`
  }

  private get terminations () {
    return this.question?.terminations || []
  }

  private get configItemComponents () {
    return this.$refs.configItemComponents as TerminationItem[]
  }

  public open (data: { question: ScreenerQuestion; parent?: ScreenerQuestion }) {
    this.question = deepClone(data.question)
    if (data.parent) {
      this.parent = data.parent
    }
    this.show = true
  }

  public close () {
    this.question = null
    this.parent = null
    this.show = false
  }

  public save () {
    if (this.validate()) {
      this.$emit('save', this.question?.terminations)
      this.close()
    }
  }

  private addItem () {
    if (!this.question) {
      return
    }

    const item: Partial<ScreenerQuestionTermination> = {
      type: TerminationType.SOFT,
      operator: TerminationOperator.EQUAL
    }

    this.$set(this.question, 'terminations', [...this.terminations, item])
  }

  private removeItem (item: Partial<ScreenerQuestionTermination>, index: number) {
    if (!this.question || !this.question.terminations) {
      return
    }
    this.question.terminations.splice(index, 1)
  }

  private updateItem (item: ScreenerQuestionTermination, index: number) {
    if (!this.question || !this.question.terminations) {
      return
    }

    this.question.terminations.splice(index, 1, item)
    this.$nextTick().then(() => this.validate())
  }

  private modalVisibilityUpdated (value: boolean) {
    if (!value && this.question) {
      this.close()
    }
  }

  public validate () {
    const validChecks = this.configItemComponents.map(component => component.validate())
    return validChecks.every(check => check === true)
  }
}
