


































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { FormFieldError, SystemFile } from '../../../index.types'
import { getInputName } from '../layout-editor.utilities'
import { AnswerManager } from '../answer-manager'
import {
  ExternalLink,
  ScreenerQuestion,
  ScreenerQuestionAnswer,
  ScreenerQuestionLoopAnswerType,
  ScreenerQuestionOption,
  ScreenerQuestionOptionType,
  ScreenerQuestionType
} from '../models'
import BaseInput from './BaseInput.vue'
import SelectInput from './SelectInput.vue'
import RatingInput from './RatingInput.vue'
import MatrixInput from './MatrixInput.vue'
import SingleTextInput from './SingleTextInput.vue'
import MultiTextInput from './MultiTextInput.vue'
import MultiMatrixInput from './MultiMatrixInput.vue'
import DynamicMatrixInput from './DynamicMatrixInput.vue'
import CheckboxInput from './CheckboxInput.vue'
import RadioGroupInput from './RadioGroupInput.vue'
import RankingInput from './RankingInput.vue'
import { deepClone, valueUnchanged } from '../../../utilities/helpers'
import { ScreenerBuilderQuestionErrors } from '../layout-editor.types'

@Component({
  components: { BaseInput }
})
export default class LoopInput extends Vue {
  @Prop()
  readonly order!: number

  @Prop()
  readonly question!: ScreenerQuestion

  @Prop()
  readonly parentQuestion!: ScreenerQuestion

  @Prop({ type: Array, default: () => [] })
  readonly answers!: ScreenerQuestionAnswer[]

  @Prop()
  readonly value!: string

  @Prop({ type: Array, default: () => [] })
  private readonly files!: SystemFile[]

  @Prop({ type: Array, default: () => [] })
  private readonly links!: ExternalLink[]

  @Prop({ type: Object, default: null })
  readonly error!: FormFieldError

  @Prop({ type: Object, default: () => ({}) })
  private readonly builderError!: ScreenerBuilderQuestionErrors

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean

  @Prop({ type: Boolean, default: false })
  readonly editorView!: boolean

  @Prop({ type: Boolean, default: false })
  readonly terminatingOverview!: boolean

  @Prop({ type: Map, default: () => new Map() })
  readonly terminationMap!: Map<string, number>

  get isQuestionLoop () {
    return this.question.loopAnswerType === ScreenerQuestionLoopAnswerType.QUESTIONS
  }

  get errorMessages () {
    if (!this.error) {
      return []
    }
    return [this.error?.message]
  }

  get validParentAnswers () {
    const answers = this.parentQuestion?.answers || []
    return answers.filter(answer => this.validAnswer(answer))
  }

  get inputName () {
    if (this.question.loopType) {
      return `${getInputName(ScreenerQuestionType.LOOP)} (${getInputName(this.question.loopType)})`
    }
    return getInputName(ScreenerQuestionType.LOOP)
  }

  get options (): ScreenerQuestionOption[] {
    if (this.question.loopAnswerType === ScreenerQuestionLoopAnswerType.OPTIONS) {
      if (this.editorView) {
        return this.parentQuestion?.options || []
      } else {
        return this.validParentAnswers.reduce((options, answer) => {
          const option = this.parentQuestion.options.find(option => option.id === answer.optionId)
          if (option) {
            return options.concat(option)
          }
          return options
        }, [] as ScreenerQuestionOption[])
      }
    }
    return this.question.options
  }

  get rows () {
    if (this.question.loopAnswerType === ScreenerQuestionLoopAnswerType.OPTIONS) {
      return this.options
    }
    return this.options.filter(option => option.type === ScreenerQuestionOptionType.ROW)
  }

  get columns () {
    return this.question.options.filter(option => option.type === ScreenerQuestionOptionType.COLUMN)
  }

  get component () {
    if (!this.parentQuestion) {
      return null
    }
    switch (this.question.loopType) {
      case ScreenerQuestionType.SELECT:
        return SelectInput
      case ScreenerQuestionType.RATING:
        return RatingInput
      case ScreenerQuestionType.MATRIX:
        return MatrixInput
      case ScreenerQuestionType.TEXTFIELD:
        return SingleTextInput
      case ScreenerQuestionType.TEXTAREA:
        return MultiTextInput
      case ScreenerQuestionType.MATRIX_MULTI:
        return MultiMatrixInput
      case ScreenerQuestionType.MATRIX_DYNAMIC:
        return DynamicMatrixInput
      case ScreenerQuestionType.CHECKBOX:
        return CheckboxInput
      case ScreenerQuestionType.RADIO:
        return RadioGroupInput
      case ScreenerQuestionType.RANKING:
        return RankingInput
    }
  }

  subOrder (index: number) {
    if (this.order) {
      return this.order + index
    }
  }

  loopAnswers (optionId?: number) {
    return this.answers.filter(i => i.parentOptionId === optionId)
  }

  isAnswered () {
    return AnswerManager.isLoopAnswered(this.question, this.parentQuestion)
  }

  questionText (answer: ScreenerQuestionAnswer) {
    const option = this.parentQuestion?.options.find(i => i.id === answer.optionId || 0)
    return this.question.text?.replace('[text]', option?.text || '') || ''
  }

  showQuestion (index: number) {
    if (!index || index < 1) {
      return true
    }
    const previousAnswer = this.validParentAnswers[index - 1]
    const answers = this.relatedAnswers(previousAnswer)
    return AnswerManager.isAnswered({ type: this.question.loopType, options: this.question.options, answers } as ScreenerQuestion)
  }

  validAnswer (answer: ScreenerQuestionAnswer) {
    if (!this.parentQuestion) {
      return false
    }
    return AnswerManager.validLoopParentAnswer(this.parentQuestion, answer)
  }

  loopAnswered (parentAnswer: ScreenerQuestionAnswer, data: ScreenerQuestionAnswer[]) {
    const currentAnswers = this.relatedAnswers(parentAnswer)
    if (valueUnchanged(data, currentAnswers)) {
      return
    }
    const answers = deepClone(this.answers).filter(i => i.parentOptionId !== parentAnswer.optionId)
    const newAnswers = data.map<ScreenerQuestionAnswer>(i => {
      return { ...i, parentOptionId: parentAnswer.optionId }
    })

    answers.push(...newAnswers)
    this.answer(deepClone(answers))
  }

  relatedAnswers (parentAnswer: ScreenerQuestionAnswer) {
    return this.answers.filter(i => i.parentOptionId === parentAnswer.optionId)
  }

  answer (data: ScreenerQuestionAnswer[]) {
    this.$emit('answer', data)
  }
}
