import Vue from 'vue'
import Vuetify from 'vuetify'
import { makeVuetifyInstance } from '@focus/components'

Vue.use(Vuetify)

const vuetify = makeVuetifyInstance({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        'theme-background': '#F8F8F8'
      }
    }
  }
})

export default vuetify
