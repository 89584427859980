import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'

import store from '../store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [{
  path: '/',
  redirect: '/home'
}, {
  name: 'home',
  path: '/home',
  component: () => import(/* webpackChunkName: "core" */ '../domains/core/pages/DashboardPage.vue')
}, {
  name: 'login',
  path: '/login',
  meta: {
    public: true
  },
  component: () => import(/* webpackChunkName: "auth" */ '../domains/auth/pages/LoginPage.vue')
}, {
  name: 'register',
  path: '/register',
  meta: {
    public: true
  },
  component: () => import(/* webpackChunkName: "auth" */ '../domains/auth/pages/RegisterPage.vue')
}, {
  name: 'verify',
  path: '/verify',
  meta: {
    public: true
  },
  component: () => import(/* webpackChunkName: "auth" */ '../domains/auth/pages/VerifyAccountPage.vue')
}, {
  name: 'forgot-password',
  path: '/forgot-password',
  meta: {
    public: true
  },
  component: () => import(/* webpackChunkName: "auth" */ '../domains/auth/pages/ForgotPasswordPage.vue')
}, {
  name: 'new-password',
  path: '/new-password',
  meta: {
    public: true
  },
  component: () => import(/* webpackChunkName: "auth" */ '../domains/auth/pages/NewPasswordPage.vue')
}, {
  name: 'confirm-email',
  path: '/email/confirm',
  meta: {
    public: true
  },
  component: () => import(/* webpackChunkName: "auth" */ '../domains/auth/pages/EmailConfirmPage.vue')
}, {
  name: 'guest-conversion',
  path: '/profile/convert',
  component: () => import(/* webpackChunkName: "profile" */ '../domains/profile/pages/UpgradeGuestAccountPage.vue')
}, {
  name: 'my-details',
  path: '/profile',
  component: () => import(/* webpackChunkName: "profile" */ '../domains/profile/pages/MyDetailsPage.vue')
}, {
  name: 'edit-my-details',
  path: '/profile/edit',
  component: () => import(/* webpackChunkName: "profile" */ '../domains/profile/pages/EditDetailsPage.vue')
}, {
  name: 'unsubscribe',
  path: '/profile/unsubscribe/:identity/:signature',
  meta: {
    public: true
  },
  component: () => import(/* webpackChunkName: "profile" */ '../domains/profile/pages/UnsubscribePage.vue')
}, {
  name: 'jobs-read',
  path: '/jobs/:id',
  meta: {
    public: true
  },
  component: () => import(/* webpackChunkName: "jobs" */ '../domains/jobs/pages/JobReadPage.vue')
}, {
  name: 'screeners-read',
  path: '/screeners/:id',
  meta: {
    public: true
  },
  component: () => import(/* webpackChunkName: "screeners" */ '../domains/screeners/pages/ScreenerReadPage.vue')
}, {
  name: 'upcoming-groups',
  path: '/upcoming-groups',
  component: () => import(/* webpackChunkName: "groups" */ '../domains/groups/pages/GroupListPage.vue')
}, {
  name: 'group',
  path: '/group/:id',
  component: () => import(/* webpackChunkName: "groups" */ '../domains/groups/pages/GroupReadPage.vue')
}]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

async function authGuard (to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  if (!store.state.auth?.isLoggedIn) {
    try {
      await store.dispatch('auth/me')
    } catch (error) {
      if (to.meta.public === true) {
        next()
      } else {
        if (to.name) {
          next({ name: 'login', query: { ...to.query, redirect: to.path } })
        } else {
          next({ name: 'login' })
        }
      }
      return
    }
  }

  if (!store.state.auth?.isLoggedIn && to.meta.public !== true) {
    next({ name: 'login', query: { ...to.query, redirect: to.path } })
  } else {
    next()
  }
}

router.beforeEach(authGuard)

export default router
