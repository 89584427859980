













import Vue from 'vue'
import '@focus/components/styles.scss'
import { Component } from 'vue-property-decorator'

import { AppErrorBanner, Navbar, Toolbar } from '../src/domains/core'
import { PersonRole } from '@focus/components'
import ErrorPopup from '@/domains/core/components/ErrorPopup.vue'

@Component({
  components: { ErrorPopup, Navbar, Toolbar, AppErrorBanner }
})
export default class AppComponent extends Vue {
  showNav = false

  get showToolbar () {
    if (!this.$route.name) {
      return false
    } else {
      const invalidRoutes = ['login', 'register', 'verify', 'new-password', 'forgot-password']
      return !invalidRoutes.includes(this.$route.name)
    }
  }

  get navOpen () {
    return this.showNav && this.showToolbar
  }

  set navOpen (value: boolean) {
    this.showNav = value
  }

  get items () {
    const items = [{ title: 'Home', icon: 'dashboard', path: '/home' }]

    if (this.$store.state.auth.isLoggedIn && this.$store.state.auth.user.role !== PersonRole.GUEST) {
      items.push(
        { title: 'My Upcoming Groups', icon: 'supervisor_account', path: '/upcoming-groups' },
        { title: 'My Details', icon: 'accessibility', path: '/profile' }
      )
    }

    return items
  }

  toggleNav () {
    this.showNav = !this.showNav
  }
}
