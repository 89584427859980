export type TemplateDataOption = {
  name: string;
  key: string;
  note?: string;
  validInButton?: boolean;
}

export type OptionTab = 'person' | 'client' | 'group' | 'job' | 'venue' | 'special'
type TemplateDataMap = Map<OptionTab, TemplateDataOption[]>
type DataOptionKeyPair = [OptionTab, TemplateDataOption[]]

const PersonOptions: DataOptionKeyPair = [
  'person',
  [
    { name: 'Person Given Name', key: 'person_givenName', note: 'Given name of the person receiving the email' },
    { name: 'Person Surname', key: 'person_surname', note: 'Surname of the person receiving the email' },
    { name: 'Person Name', key: 'person_name', note: 'Full name of the person receiving the email' },
    { name: 'Person Email', key: 'person_email', note: 'Email of the person receiving the email' },
    { name: 'Person Address', key: 'profile_address', note: 'Address of the person receiving the email' },
    { name: 'Person Suburb', key: 'profile_suburb', note: 'Suburb of the person receiving the email' },
    { name: 'Person State', key: 'profile_state', note: 'State of the person receiving the email' },
    { name: 'Person Mobile', key: 'profile_mobile', note: 'Mobile number of the person receiving the email' },
    { name: 'Person Home Phone', key: 'profile_homePhone', note: 'Home phone number of the person receiving the email' }
  ]
]

const ClientOptions: DataOptionKeyPair = [
  'client',
  [
    { name: 'Client Name', key: 'client_name', note: 'Name of the client on the job' },
    { name: 'Client Email', key: 'client_email', note: 'Email of the client on the job' },
    { name: 'Client Phone', key: 'client_phone', note: 'Phone number of the client on the job' },
    { name: 'Client Address', key: 'client_address', note: 'Address of the client on the job' },
    { name: 'Client Suburb', key: 'client_suburb', note: 'Suburb of the client on the job' },
    { name: 'Client State', key: 'client_state', note: 'State of the client on the job' },
    { name: 'Client Postcode', key: 'client_postcode', note: 'Postcode of the client on the job' }
  ]
]

const GroupOptions: DataOptionKeyPair = [
  'group',
  [
    { name: 'Group Name', key: 'group_name', note: 'Name of the job group' },
    { name: 'Group Description', key: 'group_description', note: 'Description of the job group' },
    { name: 'Group Appointment Date', key: 'group_date', note: 'Date of the job group\'s session' },
    { name: 'Group Appointment Time', key: 'group_time', note: 'Time of the job group\'s session' },
    { name: 'Group Appointment Date/Time', key: 'group_dateTime', note: 'Date and time of the job group\'s session' },
    { name: 'Group Appointment Duration', key: 'group_duration', note: 'Duration of the job group\'s session (in minutes)' },
    { name: 'Group Incentive Amount', key: 'group_incentiveAmount', note: 'Attendance incentive amount of the job group session' },
    { name: 'Group Homework Incentive Amount', key: 'group_homeworkIncentiveAmount', note: 'Homework incentive amount of the job group session' }
  ]
]

const JobOptions: DataOptionKeyPair = [
  'job',
  [
    { name: 'Name', key: 'job_publicName', note: 'Public name of the job' },
    { name: 'Job Type', key: 'job_type', note: 'Type of job (Focus Group, Quantitative Research, etc.)' },
    { name: 'Job Description', key: 'job_description', note: 'Public description of job' }
  ]
]

const VenueOptions: DataOptionKeyPair = [
  'venue',
  [
    { name: 'Venue Name', key: 'venue_name', note: 'Name of the venue related to the group\'s session' },
    { name: 'Venue Contact Name', key: 'venue_contactName', note: 'Name of contact for the venue related to the group\'s session' },
    { name: 'Venue Contact Email', key: 'venue_email', note: 'Email of the venue related to the group\'s session' },
    { name: 'Venue Contact Fax', key: 'contact_fax', note: 'Fax of the venue related to the group\'s session' },
    { name: 'Venue Contact Phone #', key: 'contact_phone', note: 'Phone number of the venue related to the group\'s session' },
    { name: 'Venue Contact Mobile #', key: 'venue_mobile', note: 'Mobile number of the venue related to the group\'s session' },
    { name: 'Venue Address', key: 'venue_address', note: 'Address of the venue related to the group\'s session' },
    { name: 'Venue Suburb', key: 'venue_suburb', note: 'Suburb of the venue related to the group\'s session' },
    { name: 'Venue State', key: 'venue_state', note: 'State of the venue related to the group\'s session' },
    { name: 'Venue Postcode', key: 'venue_postcode', note: 'Postcode of the venue related to the group\'s session' }
  ]
]

const AccountVerifyOptions: DataOptionKeyPair = ['special', [{ name: 'Verify URL', key: 'url', note: 'URL path to verify their account', validInButton: true }]]
const PasswordResetOptions: DataOptionKeyPair = ['special', [{ name: 'Reset URL', key: 'url', note: 'URL path to reset their password', validInButton: true }]]
const SendScreenerOptions: DataOptionKeyPair = ['special', [{ name: 'Screener URL', key: 'url', note: 'URL path to the screener', validInButton: true }]]
const UpdateNotificationOptions: DataOptionKeyPair = ['special', [{ name: 'URL', key: 'url', note: 'URL path to the respondent update page', validInButton: true }]]
const GuestConversionOptions: DataOptionKeyPair = ['special', [{ name: 'URL', key: 'url', note: 'URL path to the page the guest can use to convert their account into a full respondent', validInButton: true }]]
const EmailVerifyOptions: DataOptionKeyPair = ['special', [{ name: 'Verify URL', key: 'url', note: 'URL path to verify their new email', validInButton: true }]]
const SendFailureOptions: DataOptionKeyPair = ['special', [{ name: 'Update URL', key: 'url', note: 'URL path to update their details', validInButton: true }]]

export const templateOptions = new Map<string, TemplateDataMap>([
  ['group-initial-confirmation', new Map([PersonOptions, ClientOptions, GroupOptions, JobOptions, VenueOptions])],
  ['group-final-confirmation', new Map([PersonOptions, ClientOptions, GroupOptions, JobOptions, VenueOptions])],
  ['account-verify', new Map([PersonOptions, AccountVerifyOptions])],
  ['password-reset-respondent', new Map([PersonOptions, PasswordResetOptions])],
  ['password-reset-staff', new Map([PersonOptions, PasswordResetOptions])],
  ['send-screener', new Map([PersonOptions, ClientOptions, JobOptions, SendScreenerOptions])],
  ['update-notification', new Map([PersonOptions, UpdateNotificationOptions])],
  ['custom', new Map([PersonOptions])],
  ['screener-template', new Map([PersonOptions, JobOptions])],
  ['guest-conversion', new Map([PersonOptions, GuestConversionOptions])],
  ['email-verify', new Map([PersonOptions, EmailVerifyOptions])],
  ['sms-failed', new Map([PersonOptions, SendFailureOptions])],
  ['email-failed', new Map([PersonOptions, SendFailureOptions])]
])

export function getValidButtonVariables (type: string): TemplateDataOption[] {
  return Array.from(templateOptions.get(type)?.values() || []).reduce((acc, set) => {
    return acc.concat(set.filter(i => i.validInButton))
  }, [])
}
