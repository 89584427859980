



import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import { Modal } from '@focus/components'

@Component({
  components: { Modal }
})
export default class LogoutModal extends Vue {
  isOpen = false
  color = 'warning'
  icon = 'info'
  text = 'Are you sure you want to log out of your account?'
  subtext = 'You will be logged out and taken back to the login screen'
  confirmText = 'Confirm'

  close () {
    this.isOpen = false
  }

  open () {
    this.isOpen = true
  }

  confirm () {
    this.$emit('confirm')
    this.close()
  }
}
