












import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import { Banner, ApiError } from '@focus/components'

@Component({
  components: { Banner }
})
export default class ErrorPopup extends Vue {
  get errors (): ApiError[] {
    return this.$store.state.errors.slice(0, 3)
  }

  calculateMargin (index: number) {
    return `${index * 60}px`
  }

  close (error: ApiError) {
    this.$store.commit('removeError', error.id)
  }
}
