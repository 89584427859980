



















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import {
  ScreenerQuestion,
  ScreenerQuestionOptionType,
  ScreenerQuestionTermination,
  ScreenerQuestionType,
  TerminationOperator,
  TerminationType
} from '../../../models'
import Button from '../../../../Button/Button.vue'
import ButtonMenu from '../../../../ButtonMenu/ButtonMenu.vue'
import { TerminationItemError } from './termination-item.types'

import MatrixItem from './MatrixItem.vue'
import OptionAndRawItem from './OptionAndRawItem.vue'
import OneDimMultiSelectItem from './OneDimMultiSelectItem.vue'
import OneDimSingleSelectItem from './OneDimSingleSelectItem.vue'
import RawItem from './RawItem.vue'
import LoopItem from './LoopItem.vue'
import BaseTerminationItem from './BaseItem.vue'

@Component({
  components: { Button, ButtonMenu }
})
export default class TerminationItemComponent extends Vue {
  @Prop()
  private readonly question!: ScreenerQuestion

  @Prop()
  private readonly parent?: ScreenerQuestion

  @Prop()
  private readonly item!: ScreenerQuestionTermination

  @Prop({ type: Boolean, default: false })
  private readonly locked!: boolean

  private error: TerminationItemError = { type: false, params: { row: false, option: false, operator: false, value: false } }

  private get hasError () {
    return this.error.type || this.error.params.row || this.error.params.option || this.error.params.value
  }

  private get columns () {
    return this.question.options.filter(i => i.type === ScreenerQuestionOptionType.COLUMN)
  }

  private get rows () {
    return this.question.options.filter(i => i.type === ScreenerQuestionOptionType.ROW)
  }

  private get questionOptions () {
    return this.question.options
  }

  private get usesSelectionOptions () {
    const validQuestionTypes = [ScreenerQuestionType.MATRIX_MULTI, ScreenerQuestionType.MATRIX, ScreenerQuestionType.MATRIX_DYNAMIC, ScreenerQuestionType.CHECKBOX, ScreenerQuestionType.RADIO, ScreenerQuestionType.IMAGE]
    if (this.question.type === ScreenerQuestionType.LOOP) {
      return this.question.loopType && validQuestionTypes.includes(this.question.loopType)
    } else {
      return validQuestionTypes.includes(this.question.type)
    }
  }

  private get operators () {
    if (this.usesSelectionOptions) {
      return [
        { text: 'selected', value: TerminationOperator.EQUAL },
        { text: 'not selected', value: TerminationOperator.NOT_EQUAL }
      ]
    }

    return [
      { text: 'equal to', value: TerminationOperator.EQUAL },
      { text: 'greater than', value: TerminationOperator.GREATER },
      { text: 'greater than or equal to', value: TerminationOperator.GREATER_OR_EQUAL },
      { text: 'less than', value: TerminationOperator.LESS },
      { text: 'less than or equal to', value: TerminationOperator.LESS_OR_EQUAL }
    ]
  }

  private get terminationTypes () {
    return [
      { text: 'Soft Terminate', value: TerminationType.SOFT },
      { text: 'Hard Terminate', value: TerminationType.HARD }
    ]
  }

  private removeItem () {
    this.$emit('remove', this.item)
  }

  get itemComponentType (): typeof BaseTerminationItem {
    switch (this.question.type) {
      case ScreenerQuestionType.MATRIX_MULTI:
      case ScreenerQuestionType.MATRIX:
      case ScreenerQuestionType.MATRIX_DYNAMIC:
        return MatrixItem
      case ScreenerQuestionType.CHECKBOX:
      case ScreenerQuestionType.IMAGE:
        return OneDimMultiSelectItem
      case ScreenerQuestionType.RADIO:
      case ScreenerQuestionType.SELECT:
      case ScreenerQuestionType.IMAGE_SINGLE:
        return OneDimSingleSelectItem
      case ScreenerQuestionType.RANKING:
        return OptionAndRawItem
      case ScreenerQuestionType.TEXTAREA:
      case ScreenerQuestionType.TEXTFIELD:
      case ScreenerQuestionType.RATING:
        return RawItem
      case ScreenerQuestionType.LOOP:
        return LoopItem
      default:
        return BaseTerminationItem
    }
  }

  get itemComponent (): BaseTerminationItem {
    return this.$refs.itemComponent as BaseTerminationItem
  }

  public validate () {
    this.error = { type: false, params: { row: false, option: false, operator: false, value: false } }
    this.error.params = this.itemComponent.validate()

    return !this.error.type && !this.error.params.row && !this.error.params.option && !this.error.params.operator && !this.error.params.value
  }

  private updateItem (data: Partial<ScreenerQuestionTermination>) {
    const updateData = { ...this.item, ...data }
    if (this.question.type === ScreenerQuestionType.MATRIX_MULTI) {
      updateData.value = 'true'
    }
    this.$emit('update:item', updateData)
  }
}
