



import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { OptionModal, ModalOption } from '../../OptionsModal'

@Component({
  components: { OptionModal }
})
export default class StatusModal extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly value!: boolean

  @Prop({ type: Array, default: () => [] })
  readonly options!: ModalOption[]
}
