import { API_PREFIX, RootState } from '@/store/store.types'
import { ActionContext } from 'vuex'
import axios from 'axios'
import {
  AuthState,
  CloseAccountPayload,
  GuestRegisterData,
  LoginDetails,
  LoginResult,
  RecruitLoginDetails,
  RespondentRegisterData,
  User
} from './auth.types'
import { BaseApiResourceModule } from '@focus/components'

class AuthModule extends BaseApiResourceModule<User, RootState> {
  namespaced = true

  get state (): () => AuthState {
    return () => ({
      ...super.state(),
      user: null,
      isLoggedIn: false
    })
  }

  get getters () {
    return {
      userRole: (state: AuthState) => {
        if (!state.user) { return null }
        return state.user.role
      }
    }
  }

  get mutations () {
    return {
      ...super.mutations,
      setUser: this.setUser.bind(this),
      setIsLoggedIn: this.setIsLoggedIn.bind(this)
    }
  }

  get actions () {
    return {
      login: this.login.bind(this),
      recruitLogin: this.recruitLogin.bind(this),
      logout: this.logout.bind(this),
      me: this.me.bind(this),
      register: this.register.bind(this),
      guestRegister: this.guestRegister.bind(this),
      requestReset: this.requestReset.bind(this),
      checkToken: this.checkToken.bind(this),
      resetPassword: this.resetPassword.bind(this),
      verify: this.verify.bind(this),
      closeAccount: this.closeAccount.bind(this),
      confirmEmail: this.confirmEmail.bind(this)
    }
  }

  private async login (context: ActionContext<AuthState, RootState>, payload: LoginDetails) {
    context.commit('setLoading', true)
    const data: any = {
      email: payload.email,
      password: payload.password,
      portal: 'respondent'
    }

    if (payload.dateOfBirth) {
      data.dateOfBirth = payload.dateOfBirth
    }
    try {
      const response = await axios.post<User>(`${API_PREFIX}/auth/login`, data)
      context.commit('setLoading', false)
      context.commit('setUser', response.data)
      context.commit('setIsLoggedIn', true)
    } catch (error) {
      context.commit('setLoading', false)
      throw this.formatApiError(error)
    }
  }

  private async recruitLogin (context: ActionContext<AuthState, RootState>, payload: RecruitLoginDetails) {
    try {
      const response = await axios.post<User>(`${API_PREFIX}/auth/recruit-login`, {
        givenName: payload.givenName,
        surname: payload.surname,
        email: payload.email
      })
      context.commit('setUser', response.data)
      context.commit('setIsLoggedIn', true)
    } catch (error) {
      throw this.formatApiError(error)
    }
  }

  private async register (context: ActionContext<AuthState, RootState>, payload: RespondentRegisterData) {
    context.commit('setLoading', true)
    try {
      await axios.post(`${API_PREFIX}/auth/register`, payload)
      context.commit('setLoading', false)
    } catch (error) {
      context.commit('setLoading', false)
      throw this.formatApiError(error)
    }
  }

  private async confirmEmail (context: ActionContext<AuthState, RootState>, payload: { token: string }) {
    try {
      const response = await axios.post(`${API_PREFIX}/auth/confirm-email`, payload)
      return response.data
    } catch (error) {
      throw this.formatApiError(error)
    }
  }

  private async guestRegister (context: ActionContext<AuthState, RootState>, payload: GuestRegisterData) {
    context.commit('setLoading', true)
    try {
      await axios.post<User>(`${API_PREFIX}/auth/guest-register`, payload)
      context.commit('setLoading', false)
      context.dispatch('login', { email: payload.email, password: payload.password })
    } catch (error) {
      context.commit('setLoading', false)
      throw this.formatApiError(error)
    }
  }

  private async verify (context: ActionContext<AuthState, RootState>, payload: { token: string }) {
    context.commit('setLoading', true)
    try {
      const response = await axios.post(`${API_PREFIX}/auth/verify?token=${payload.token}`)
      context.commit('setLoading', false)
      return response.data
    } catch (error) {
      context.commit('setLoading', false)
      throw this.formatApiError(error)
    }
  }

  private async me (context: ActionContext<AuthState, RootState>) {
    context.commit('setLoading', true)
    try {
      const response = await axios.get(`${API_PREFIX}/auth/me?portal=respondent`)
      context.commit('setLoading', false)
      context.commit('setUser', response.data)
      context.commit('setIsLoggedIn', true)
    } catch (error) {
      context.commit('setLoading', false)
      throw this.formatApiError(error)
    }
  }

  private async requestReset (context: ActionContext<AuthState, RootState>, payload: { email: string; slotNumber: string }) {
    context.commit('setLoading', true)
    try {
      await axios.post(`${API_PREFIX}/auth/request-reset`, payload)
      context.commit('setLoading', false)
    } catch (error) {
      context.commit('setLoading', false)
      throw this.formatApiError(error)
    }
  }

  private async checkToken (context: ActionContext<AuthState, RootState>, payload: { token: string }) {
    context.commit('setLoading', true)
    try {
      await axios.get(`${API_PREFIX}/auth/reset-password?token=${payload.token}`)
      context.commit('setLoading', false)
    } catch (error) {
      context.commit('setLoading', false)
      throw this.formatApiError(error)
    }
  }

  private async resetPassword (context: ActionContext<AuthState, RootState>, payload: { password: string; token: string }) {
    context.commit('setLoading', true)
    try {
      await axios.post(`${API_PREFIX}/auth/reset-password`, { password: payload.password, token: payload.token })
      context.commit('setLoading', false)
    } catch (error) {
      context.commit('setLoading', false)
      throw this.formatApiError(error)
    }
  }

  private async logout (context: ActionContext<AuthState, RootState>) {
    context.commit('setLoading', true)
    try {
      await axios.post(`${API_PREFIX}/auth/logout`)
      context.commit('setLoading', false)
      context.commit('setUser', null)
      context.commit('setIsLoggedIn', false)
    } catch (error) {
      context.commit('setLoading', false)
      throw this.formatApiError(error)
    }
  }

  private async closeAccount (context: ActionContext<AuthState, RootState>, payload: CloseAccountPayload) {
    context.commit('setLoading', true)
    try {
      await axios.post(`${API_PREFIX}/auth/close-account`, payload)
      context.commit('setLoading', false)
      context.commit('setUser', null)
      context.commit('setIsLoggedIn', false)
    } catch (error) {
      context.commit('setLoading', false)
      throw this.formatApiError(error)
    }
  }

  private setIsLoggedIn (state: AuthState, payload: boolean) {
    state.isLoggedIn = payload
  }

  private setUser = (state: AuthState, payload: LoginResult) => {
    if (!payload) {
      state.user = null
    } else {
      state.user = {
        id: payload.id,
        givenName: payload.givenName,
        surname: payload.surname,
        email: payload.email,
        role: payload.role
      }
    }
  }
}

export default new AuthModule()
