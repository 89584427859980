import { isValidPhoneNumber } from 'libphonenumber-js'

import { PersonRole } from '../index.types'

const passwordTests = [
  (p: string) => /[a-z]/.test(p), // Contains lower case character
  (p: string) => /[A-Z]/.test(p), // Contains upper case character
  (p: string) => /[0-9]/.test(p), // Contains number
  (p: string) => /\W|_/g.test(p) // Contains symbol
]

export function isEmail (val: string) {
  const regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/) //eslint-disable-line
  return regex.test(val) || 'Invalid email address'
}

export function isPhoneNumber (val: string) {
  try {
    const isValid = isValidPhoneNumber(val, 'AU')

    if (!isValid) {
      throw new Error()
    }

    return true
  } catch {
    return 'Invalid phone number'
  }
}

export function isNumber (val: string) {
  return !Number.isNaN(+val)
}

export function isLengthLessThanOrEqualTo (val: string, number: number) {
  const valString = val ? val.toString() : ''
  return valString.length <= number || 'Input too long'
}

export function isLengthGreaterThanOrEqualTo (val: string, number: number) {
  const valString = val ? val.toString() : ''
  return valString.length >= number || 'Input too long'
}

export function isValidPassword (val: string) {
  return passwordTests.reduce((passed, test) => {
    return test(val) ? passed + 1 : passed
  }, 0) >= 3 || 'Password too weak'
}

export function postcodeRule (val: string) {
  if (val === null || val === '' || val === undefined) {
    return true
  }

  const num = +val

  return (!Number.isNaN(Number(num)) && num > 999 && num <= 9999) || 'Invalid postcode'
}

export function isWeakerRole (userRole: PersonRole, role: PersonRole): boolean {
  if (userRole === 'admin') return false

  if (userRole === 'manager' && role !== 'admin') return false

  if (userRole === 'staff' && ['staff', 'respondent', 'guest'].includes(role)) return false

  return true
}
