







import { Component, Prop } from 'vue-property-decorator'
import { TerminationItemError, TerminationItemParamError } from './termination-item.types'

import { ScreenerQuestionOption, ScreenerQuestionTermination, TerminationOperator } from '../../../models'
import ButtonMenu from '../../../../ButtonMenu/ButtonMenu.vue'

import BaseTerminationItem from './BaseItem.vue'

@Component({
  components: { ButtonMenu }
})
export default class OneDimensionMultiSelectTerminationItem extends BaseTerminationItem {
  @Prop()
  private readonly value!: ScreenerQuestionTermination

  @Prop({ type: Array, default: () => [] })
  private readonly options!: ScreenerQuestionOption[]

  @Prop({ type: Array, default: () => [] })
  private readonly operators!: { text: string; value: TerminationOperator }[]

  @Prop({ type: Object, required: true })
  private readonly error!: TerminationItemError

  @Prop({ type: Boolean, default: false })
  private readonly locked!: boolean

  private get optionItems () {
    return this.options.map(i => ({ text: i.text, value: i.id || i.uuid }))
  }

  private get optionId () {
    return this.value.optionId
  }

  private set optionId (value: string | number | undefined) {
    this.$emit('input', { ...this.value, optionId: value, value: 'true' })
  }

  private get operator () {
    return this.value.operator
  }

  private set operator (value: TerminationOperator) {
    this.$emit('input', { ...this.value, operator: value })
  }

  validate (): TerminationItemParamError {
    return this.buildParams({
      option: !this.optionId,
      operator: !this.operator
    })
  }
}
