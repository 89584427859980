


















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { v4 as uuid } from 'uuid'
import { sanitizeTemplate } from '../../utilities/page-template-editor.utilities'

@Component
export default class ReadField extends Vue {
  @Prop()
  readonly value!: string

  @Prop()
  readonly label!: string

  @Prop({ type: Boolean, default: false })
  readonly fetching!: boolean

  @Prop({ type: Boolean, default: false })
  readonly rawHtml!: boolean

  get fieldId () {
    return uuid()
  }

  get displayValue () {
    if (this.fetching) {
      return null
    }

    if (this.rawHtml) {
      return this.value ? sanitizeTemplate(this.value) : '-'
    }

    if (+this.value === 0) {
      return this.value
    }

    if (this.$slots.default && !this.value) {
      return null
    }

    return this.value || '-'
  }
}
