import { SystemFile } from '../../../index.types'

export enum ScreenerQuestionOptionType {
  ROW = 'row',
  COLUMN = 'column'
}

export type FileToUpload = {
  id?: never;
  name: string;
  url: string;
  file: File;
}

export type ScreenerQuestionOption = {
  id: number;
  uuid?: string;
  screenerId: number;
  questionId: number;
  parentId?: number;
  order: number;
  text?: string;
  type?: ScreenerQuestionOptionType;
  code?: string;
  image?: SystemFile | FileToUpload;
  isSingle?: boolean;
}
