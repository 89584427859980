










import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class GuestConversionButton extends Vue {
  get isMobile () {
    return this.$vuetify.breakpoint.smAndDown
  }

  upgradeAccount () {
    this.$router.push({ name: 'guest-conversion' })
  }
}
