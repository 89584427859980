








import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import { Banner } from '@focus/components'

@Component({
  components: { Banner }
})
export default class BannerComponent extends Vue {
  get hasError () {
    return !!this.$store.state.error
  }

  get code () {
    return this.$store.state.error?.code
  }

  get message () {
    return this.$store.state.error?.message
  }

  get color () {
    switch (this.code) {
      case 'UNAUTHORISED':
        return 'info'
      case 'NETWORK_ERROR':
        return 'error'
      default:
        return null
    }
  }

  login () {
    this.$router.push({ name: 'login' })
  }
}
