




















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import Button from '../../Button/Button.vue'

@Component({
  components: { Button }
})
export default class ScreenerBuilderHeader extends Vue {
  @Prop({ type: Number, default: 0 })
  readonly value!: number

  @Prop({ type: Number, default: 0 })
  readonly pageCount!: number

  @Prop({ type: Boolean, default: false })
  readonly preview!: boolean

  @Prop({ type: Boolean, default: false })
  readonly locked!: boolean

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  get pageIndex () {
    return this.value
  }

  set pageIndex (value: number) {
    this.$emit('input', value)
  }

  get pages () {
    const pages = []
    for (let i = 0; i < this.pageCount; i++) {
      pages.push({ text: `Page ${i + 1}`, value: i })
    }
    return pages
  }

  addPage () {
    this.$emit('addPage')
  }
}
