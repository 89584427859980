























































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { ScreenerPage } from '../../models'

import NewItemGroup from './NewItemGroup.vue'
import NewItem from './NewItem.vue'
import TemplateItem from './TemplateItem.vue'

import { toolboxItems } from './toolbox.utilities'

@Component({
  components: { NewItem, NewItemGroup, TemplateItem }
})
export default class ScreenerEditorToolboxComponent extends Vue {
  @Prop()
  readonly page!: ScreenerPage

  @Prop({ type: Boolean, default: false })
  readonly locked!: boolean

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  private get hasPage (): boolean {
    return !!this.page
  }

  type: 'new' | 'template' = 'new'

  blackQuestionItems = toolboxItems

  templateQuestions = []

  isMini = false

  get scrollContainerHeight () {
    const list = this.$refs.list as Vue
    const clientHeight = list.$el?.clientHeight || 0
    return Math.max(clientHeight - 48, 600) // This should always be fine since we have a fixed padding of 8px and a list-item heading with a height of 40px
  }

  mounted () {
    this.getTemplateQuestions()
  }

  public async getTemplateQuestions () {
    this.templateQuestions = await this.$store.dispatch('screeners/getTemplateQuestions')
  }
}
