


































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import Button from '../../../Button/Button.vue'
import Modal from '../../../Modal/Modal.vue'

import { ScreenerQuestionSchema } from '../../models'
import ScreenerQuestionComponent from '../../ScreenerQuestionComponent.vue'
import { getInputDisplayDetails } from '../../layout-editor.utilities'

@Component({
  components: { ScreenerQuestionComponent, Button, Modal }
})
export default class TemplateItemTool extends Vue {
  @Prop()
  readonly item!: ScreenerQuestionSchema

  @Prop({ type: Boolean, default: false })
  readonly locked!: boolean

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  private show = false

  private showRemoveConfirm = false

  private get displayDetails () {
    return getInputDisplayDetails(this.item.type)
  }

  add () {
    this.$emit('add')
    this.show = false
  }

  remove () {
    this.$emit('remove')
    this.showRemoveConfirm = false
  }
}
