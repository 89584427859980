

















import Vue from 'vue'
import { v4 as uuid } from 'uuid'
import { FormFieldError } from '../../index.types'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { valueUnchanged } from '../../utilities/helpers'
import Button from '../Button/Button.vue'

@Component({
  components: { Button }
})
export default class FileUpload extends Vue {
  @Prop()
  readonly label!: string

  @Prop({ type: Object, default: null })
  readonly error!: FormFieldError

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  @Prop({ type: Boolean, default: false })
  readonly loading!: boolean

  @Prop({ type: Boolean, default: false })
  readonly hideUpload!: boolean

  @Prop({ type: Boolean, default: false })
  readonly single!: boolean

  @Prop()
  readonly value!: File[]

  get errorMessages () {
    if (!this.error) {
      return []
    }
    return [this.error?.message]
  }

  private internalValue: File[] = []

  get fieldId () {
    return uuid()
  }

  @Watch('value')
  valueChanged (value: File[], oldValue: File[]) {
    if (valueUnchanged(value, oldValue)) {
      this.internalValue = value
    }
  }

  @Watch('internalValue')
  internalValueChanged (value: File[]) {
    this.$emit('input', value)
  }

  uploadFiles () {
    this.$emit('upload')
    this.internalValue = []
  }
}
