














import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import { FormFieldError, SystemFile } from '../../../index.types'
import { getInputName } from '../layout-editor.utilities'
import { AnswerManager } from '../answer-manager'
import { ScreenerQuestionType, ScreenerQuestionAnswer, ExternalLink } from '../models'
import BaseInput from './BaseInput.vue'
import { ScreenerBuilderQuestionErrors } from '../layout-editor.types'
import { deepClone, valueUnchanged } from '../../../utilities/helpers'

@Component({
  components: { BaseInput }
})
export default class SingleTextInput extends Vue {
  @Prop()
  readonly order!: number

  @Prop()
  readonly text!: string

  @Prop({ type: Array, default: () => [] })
  private readonly files!: SystemFile[]

  @Prop({ type: Array, default: () => [] })
  private readonly links!: ExternalLink[]

  @Prop({ type: Array, default: () => [] })
  readonly answers!: ScreenerQuestionAnswer[]

  @Prop()
  readonly value!: string

  @Prop({ type: Object, default: null })
  readonly error!: FormFieldError

  @Prop({ type: Object, default: () => ({}) })
  private readonly builderError!: ScreenerBuilderQuestionErrors

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean

  @Prop({ type: Boolean, default: false })
  readonly editorView!: boolean

  @Prop({ type: Boolean, default: false })
  readonly terminatingOverview!: boolean

  @Prop({ type: Map, default: () => new Map() })
  readonly terminationMap!: Map<string, number>

  get errorMessages () {
    if (!this.error) {
      return []
    }
    return [this.error?.message]
  }

  get inputName () {
    return getInputName(ScreenerQuestionType.TEXTFIELD)
  }

  internalValue: string | null = null

  debounceTimer = 0

  @Watch('answers', { immediate: true })
  private answersChanged (value: ScreenerQuestionAnswer[]) {
    const answer = value[0]
    this.internalValue = answer ? answer.value : null
  }

  @Watch('internalValue')
  private internalValueChanged (value: string) {
    clearTimeout(this.debounceTimer)
    this.debounceTimer = setTimeout(() => {
      let answers = deepClone(this.answers)

      if (answers.length) {
        answers = [{ ...answers[0], value }]
      } else {
        answers.push({ value })
      }

      if (!valueUnchanged(answers, this.answers)) {
        this.$emit('answer', answers)
      }
    }, 50)
  }

  isAnswered () {
    return AnswerManager.isSingleSelectAnswered(this.answers)
  }
}
