










import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { Button, formatPageTemplateHtml } from '@focus/components'

@Component({
  components: { Button }
})
export default class ScreenerWelcome extends Vue {
  @Prop({ type: String, default: '' })
  private readonly template!: string

  private get templateData () {
    return formatPageTemplateHtml(this.template, this.$router)
  }

  private get hasTemplate () {
    return !!this.template
  }

  private get templateHtml () {
    const template = this.templateData
    if (!this.hasTemplate) {
      return ''
    }

    if (template?.buttons) {
      this.$nextTick().then(() => {
        template.buttons.forEach((button, id) => {
          const element = document.getElementById(id)
          if (element) {
            button.$mount(element)
          }
        })
      })
    }
    return template?.template
  }

  cancel () {
    this.$router.push({ name: 'home' })
  }
}
