








import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class Loader extends Vue {
  @Prop()
  readonly text!: string
}
