




























import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import Modal from '../../Modal/Modal.vue'
import Button from '../../Button/Button.vue'
import TextField from '../../TextField/TextField.vue'
import { ListExportType } from '../List.types'

@Component({
  components: { Button, Modal, TextField }
})
export default class ExportModal extends Vue {
  @Prop({ default: false })
  readonly value!: boolean

  fileName: string | null = null

  exportTypeRawValue: number | null = null

  error: string | null = null

  get exportType (): ListExportType | null {
    if (this.exportTypeRawValue === null || this.exportTypeRawValue === undefined) {
      return null
    }

    switch (this.exportTypeRawValue) {
      case 0:
        return 'all'
      case 1:
        return 'page'
    }

    return null
  }

  get filePath () {
    if (!this.fileName) {
      return null
    }
    return `${this.fileName}.xlsx`
  }

  get incompleteForm () {
    return this.exportType === null || this.fileName === null
  }

  @Watch('exportTypeRawValue')
  exportTypeChanged () {
    this.error = null
  }

  @Watch('value')
  valueChanged (value: boolean) {
    if (!value) {
      this.exportTypeRawValue = null
    }
  }

  exportConfirmed () {
    this.error = null
    if (this.exportType === null) {
      this.error = 'Please select an option'
      return
    }

    this.$emit('onExport', { name: this.filePath, type: this.exportType })
    this.$emit('input', false)
  }
}
