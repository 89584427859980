import { Node, mergeAttributes, CommandProps, RawCommands } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import LinkButton from './LinkButton.vue'

type RenderHtmlParams = {
  HTMLAttributes: Record<string, any>[]; // eslint-disable-line
}

export const LinkButtonNode = Node.create<RenderHtmlParams>({
  name: 'linkButton',

  group: 'block',

  content: 'inline*',

  parseHTML () {
    return [
      {
        tag: 'button[data-type="linkButton"]'
      }
    ]
  },

  renderHTML ({ HTMLAttributes }) {
    return ['button', mergeAttributes(HTMLAttributes, { 'data-type': 'linkButton' }), 0]
  },

  addNodeView () {
    return VueNodeViewRenderer(LinkButton)
  },

  addCommands () {
    return {
      setLinkButton: (attributes: { isVariable: boolean; url: string; uuid: string; color: string }) => ({ commands }: CommandProps) => {
        return commands.insertContent({
          type: 'linkButton',
          attrs: attributes
        })
      }
    } as Partial<RawCommands>
  },

  addAttributes () {
    return {
      url: {
        default: null,
        // Customize the HTML parsing (for example, to load the initial content)
        parseHTML: element => {
          return {
            url: element.getAttribute('data-url')
          }
        },
        // … and customize the HTML rendering.
        renderHTML: attributes => {
          return {
            'data-url': attributes.url
          }
        }
      },
      isVariable: {
        default: null,
        // Customize the HTML parsing (for example, to load the initial content)
        parseHTML: element => {
          return {
            isVariable: element.getAttribute('data-isVariable')
          }
        },
        // … and customize the HTML rendering.
        renderHTML: attributes => {
          return {
            'data-isVariable': attributes.isVariable
          }
        }
      },
      uuid: {
        parseHTML: element => {
          return { uuid: element.getAttribute('data-uuid') }
        },
        renderHTML: attributes => {
          return {
            'data-uuid': attributes.uuid
          }
        }
      },
      color: {
        parseHTML: element => {
          return { color: element.getAttribute('data-color') }
        },
        renderHTML: attributes => {
          return {
            'data-color': attributes.color
          }
        }
      },
      textColor: {
        parseHTML: element => {
          return { textColor: element.getAttribute('data-text-color') }
        },
        renderHTML: attributes => {
          return {
            'data-text-color': attributes.textColor
          }
        }
      }
    }
  }
})
