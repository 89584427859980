










































import { Prop, Component } from 'vue-property-decorator'

import { NavItem } from './navbar.types'
import { ApiError, Button } from '@focus/components'
import LogoutModal from '../../auth/components/LogoutModal.vue'
import BaseComponent from '@/plugins/mixins'

@Component({
  components: { Button, LogoutModal }
})
export default class NavbarComponent extends BaseComponent {
  @Prop({ type: Boolean, default: true })
  readonly value!: boolean

  @Prop({ type: Array, default: () => [] })
  readonly items!: NavItem[]

  get logoutModal () {
    return this.$refs.logoutModal as LogoutModal
  }

  async logout () {
    try {
      await this.$store.dispatch('auth/logout')
      this.$router.push({ name: 'login' })
    } catch (error) {
      if (error instanceof ApiError) {
        await this.showError(error)
      } else {
        throw error
      }
    }
  }
}
