import {
  ParamOperator,
  ScreenerPathingConfigurationItemParameter,
  ScreenerQuestionAnswer,
  ScreenerQuestionType
} from '../../models'
import { BaseDisplayModule } from './base'

export class RadioDisplayModule extends BaseDisplayModule {
  run (type: ScreenerQuestionType) {
    return [ScreenerQuestionType.RADIO, ScreenerQuestionType.SELECT].includes(type)
  }

  valid (type: ScreenerQuestionType, param: ScreenerPathingConfigurationItemParameter, answers: ScreenerQuestionAnswer[]) {
    return answers.some(answer => {
      if (param.operator === ParamOperator.EQUAL) {
        return answer.value === param.optionId.toString()
      } else {
        return answer.value !== param.optionId.toString()
      }
    })
  }
}
