





import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class Chip extends Vue {
  @Prop()
  readonly text!: string

  @Prop()
  readonly color!: string

  @Prop()
  readonly chipClass!: string

  @Prop({ type: Boolean, default: false })
  readonly fetching!: boolean

  @Prop({ type: Boolean, default: false })
  readonly close!: boolean

  @Prop({ type: Boolean, default: false })
  readonly large!: boolean

  @Prop({ type: Boolean, default: false })
  readonly outlined!: boolean

  @Prop({ type: Boolean, default: false })
  readonly clickable!: boolean

  get events () {
    if (this.clickable) {
      return { click: () => this.$emit('onClick') }
    }

    return null
  }
}
