import { ScreenerQuestion, ScreenerQuestionType } from '../../models'

export function getPathingConfigType (question: ScreenerQuestion) {
  switch (question.type) {
    case ScreenerQuestionType.CHECKBOX:
    case ScreenerQuestionType.IMAGE:
    case ScreenerQuestionType.IMAGE_SINGLE:
    case ScreenerQuestionType.RADIO:
    case ScreenerQuestionType.SELECT:
      return 'checkbox'
    case ScreenerQuestionType.RATING:
      return 'rating'
    case ScreenerQuestionType.MATRIX:
    case ScreenerQuestionType.MATRIX_MULTI:
    case ScreenerQuestionType.MATRIX_DYNAMIC:
      return 'matrix'
  }

  return 'unknown'
}
