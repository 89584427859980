






























































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { BreadcrumbPart, ModalOption, PageTab, RouterLocation } from '@focus/components'
import OptionModal from '../OptionsModal/OptionsModal.vue'
import Button from '../Button/Button.vue'

import Chip from '../Chip/Chip.vue'
import StatusDisplay from './StatusDisplay/StatusDisplay.vue'
import Tabs from './Tabs/Tabs.vue'

@Component({
  components: { Button, Chip, OptionModal, StatusDisplay, Tabs }
})
export default class Header extends Vue {
  @Prop()
  readonly domain!: string

  @Prop()
  readonly prefix!: string

  @Prop()
  readonly title!: string

  @Prop()
  readonly breadcrumb!: BreadcrumbPart[]

  @Prop()
  readonly subtitle!: string

  @Prop({ type: Boolean, default: false })
  readonly fetching!: boolean

  @Prop({ type: Boolean, default: false })
  readonly singleLine!: boolean

  @Prop({ type: Array, default: () => [] })
  readonly tabs!: PageTab[]

  @Prop({ type: Array, default: () => [] })
  readonly options!: ModalOption[]

  @Prop()
  readonly activeTab!: string

  @Prop({ type: Boolean, default: false })
  readonly extraTabs!: boolean

  @Prop({ type: Array, default: () => [] })
  readonly statusOptions!: ModalOption[]

  @Prop({ type: String, default: '' })
  readonly statusText!: string

  showOptions = false

  get color () {
    switch (this.domain) {
      default:
        return '#4CB2E1'
    }
  }

  get chipClass () {
    switch (this.domain) {
      default:
        return 'white--text'
    }
  }

  get isMobile (): boolean {
    return this.$vuetify.breakpoint.smAndDown
  }

  get visibleOptions () {
    return this.options.filter(option => (option.visible === null || option.visible === undefined) || (option.visible && option.visible()))
  }

  goTo (location: RouterLocation) {
    this.$router.push(location)
  }
}
