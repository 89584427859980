













import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { Card } from '../../../Card'
import Button from '../../../Button/Button.vue'
import Select from '../../../Select/Select.vue'
import ColumnDisplay from '../ColumnDisplay/ColumnDisplay.vue'

import { Column } from '../../List.types'

@Component({
  components: { Card, Button, Select, ColumnDisplay }
})
export default class ColumnSelect<T> extends Vue {
  @Prop({ default: () => [] })
  readonly selectedColumns!: Column<T>[]

  @Prop({ default: () => [] })
  readonly columns!: Column<T>[]

  selectedColumn: Column<T> | null = null

  get unselectedColumns (): Column<T>[] {
    return this.columns.filter(column => column.display !== false && this.selectedColumns.every(selected => selected.value !== column.value))
  }

  get incompleteInput () {
    return !this.selectedColumn
  }

  addColumn () {
    this.$emit('update:selectedColumns', [...this.selectedColumns, this.selectedColumn])
    this.selectedColumn = null
  }

  removeColumn (index: number) {
    const selectedColumns = Array.from(this.selectedColumns)
    selectedColumns.splice(index, 1)
    this.$emit('update:selectedColumns', selectedColumns)
  }
}
