













import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import Button from '../../Button/Button.vue'
import Modal from '../../Modal/Modal.vue'

import ScreenerBuilderHeader from '../ScreenerBuilder/ScreenerBuilderHeader.vue'
import ScreenerBuilderPageComponent from '../ScreenerBuilder/ScreenerBuilderPageComponent.vue'
import ScreenerQuestionConfigurationComponent from '../ScreenerQuestionConfiguration/ScreenerQuestionConfiguration.vue'
import { ScreenerPage, ScreenerQuestion } from '../models'
import { valueUnchanged } from '../../../utilities/helpers'

@Component({
  components: { Button, Modal, ScreenerBuilderHeader, ScreenerBuilderPageComponent, ScreenerQuestionConfigurationComponent }
})
export default class ScreenerBuilder extends Vue {
  @Prop({ type: Array, default: () => [] })
  private readonly value!: Partial<ScreenerPage>[]

  @Prop({ type: Boolean, default: false })
  private readonly loading!: boolean

  private pages: Partial<ScreenerPage>[] = []

  private selectedPageIndex = 0

  private get selectedPage () {
    return this.pages[this.selectedPageIndex]
  }

  private get questions () {
    return this.selectedPage?.questions || []
  }

  private get allQuestions () {
    return this.pages.reduce((array, page) => {
      if (page.questions) {
        return [...array, ...page.questions]
      }
      return array
    }, [] as ScreenerQuestion[])
  }

  @Watch('value', { immediate: true, deep: true })
  private valueChanged (value: Partial<ScreenerPage>[], oldValue: Partial<ScreenerPage>[]) {
    if (!valueUnchanged(value, oldValue)) {
      this.pages = [...value]
    }
  }

  @Watch('pages', { deep: true })
  private pagesUpdated (value: Partial<ScreenerPage>[]) {
    this.$emit('input', value)
  }
}
