import {
  ParamOperator,
  ScreenerPathingConfigurationItemParameter,
  ScreenerQuestionAnswer,
  ScreenerQuestionType
} from '../../models'
import { BaseDisplayModule } from './base'

export class OptionAndRawDisplayModule extends BaseDisplayModule {
  run (type: ScreenerQuestionType) {
    return [ScreenerQuestionType.CHECKBOX, ScreenerQuestionType.IMAGE, ScreenerQuestionType.RANKING].includes(type)
  }

  valid (type: ScreenerQuestionType, param: ScreenerPathingConfigurationItemParameter, answers: ScreenerQuestionAnswer[]) {
    if (param.operator === ParamOperator.NOT_EQUAL) {
      return answers.every(answer => (answer.value !== param.value && param.optionId === answer.optionId) || param.optionId !== answer.optionId)
    }

    return answers.some(answer => {
      switch (param.operator) {
        case ParamOperator.GREATER_THAN:
          return answer.value > param.value && param.optionId === answer.optionId
        case ParamOperator.GREATER_THAN_EQUAL:
          return answer.value >= param.value && param.optionId === answer.optionId
        case ParamOperator.LESS_THAN:
          return answer.value < param.value && param.optionId === answer.optionId
        case ParamOperator.LESS_THAN_EQUAL:
          return answer.value <= param.value && param.optionId === answer.optionId
        case ParamOperator.EQUAL:
          return answer.value === param.value && param.optionId === answer.optionId
        default:
          return false
      }
    })
  }
}
