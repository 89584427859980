










































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { v4 as uuid } from 'uuid'
import { FormFieldError } from '@focus/components'
import Chip from '../Chip/Chip.vue'

export interface ChipSelectOption {
  text: string;
  value: string;
  description?: string;
}

@Component({
  components: { Chip }
})
export default class ChipSelect extends Vue {
  @Prop()
  private readonly value!: string

  @Prop()
  private readonly label!: string

  @Prop({ type: Boolean, default: false })
  private readonly fetching!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly disabled!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly readonly!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly required!: boolean

  @Prop({ type: String, default: '' })
  private readonly helpHint!: boolean

  @Prop({ type: Array, default: () => [] })
  private readonly rules!: ((val: string) => string | true)[]

  @Prop({ type: Object, default: null })
  private readonly error!: FormFieldError

  @Prop({ type: Array, default: () => [] })
  private readonly options!: ChipSelectOption[]

  internalValue: string | null = null

  valueDebounce = 0

  get fieldId () {
    return uuid()
  }

  get fieldRules () {
    const rules = []
    if (this.required) {
      rules.push(this.requiredRule)
    }

    return [...rules, ...this.rules]
  }

  get showHelp () {
    return !!this.helpHint
  }

  get errorMessages () {
    if (!this.error) {
      return []
    }
    return [this.error?.message]
  }

  @Watch('value', { immediate: true })
  valueChanged (value: string) {
    this.internalValue = value
  }

  @Watch('internalValue')
  internalValueChanged (value: string) {
    clearTimeout(this.valueDebounce)

    this.valueDebounce = window.setTimeout(() => {
      this.$emit('input', value)
    }, 30)
  }

  private requiredRule (val: string) {
    return val || 'Field is required'
  }

  setValue (value: string) {
    this.internalValue = value
  }
}
