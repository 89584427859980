import { ScreenerPage } from './screener-page.model'

export enum ScreenerStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published'
}

export enum ScreenerVisibility {
  LIST_RECRUITMENT = 'list-recruit',
  PRIVATE = 'private',
  GUEST = 'guest',
  OPEN = 'open'
}

export enum ScreenerResponseStatus {
  PENDING = 'pending',
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
  SOFT_TERMINATED = 'soft-terminated',
  HARD_TERMINATED = 'hard-terminated'
}

export interface Screener {
  id: number;

  jobId: number;

  name: string;

  displayName: string;

  visibility: ScreenerVisibility;

  status: ScreenerStatus;

  sentCount?: number;

  responseStatus: ScreenerResponseStatus;

  completedOn: Date;

  responsesReceived?: number;

  questionCount?: number;

  pages?: ScreenerPage[];

  introductionTemplate?: string;

  completionTemplate?: string;

  comments?: string;
}
