




















































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { v4 as uuidv4 } from 'uuid'

import Button from '../../../Button/Button.vue'
import BaseModal from '../../../BaseModal/BaseModal.vue'
import ButtonSelect from '../../../ButtonSelect/ButtonSelect.vue'
import { DragComponent } from '../../../Draggable/index'
import { ScreenerQuestion, ScreenerQuestionOption, ScreenerQuestionOptionType, FileToUpload, ScreenerQuestionType } from '../../models'
import { deepClone, repeatCha } from '../../../../utilities/helpers'
import { formatDroppedAnswerList } from '../../layout-editor.utilities'
import { VuetifyForm } from '../../../index.types'
import { SystemFile } from '../../../../index.types'

import OptionPasteMenu from './OptionPasteMenu.vue'
import OptionImageButton from './OptionImageButton.vue'

interface OptionFieldErrors {
  text: boolean;
}

const rowCodes = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

@Component({
  components: { BaseModal, Button, ButtonSelect, DragComponent, OptionImageButton, OptionPasteMenu }
})
export default class OptionListModal extends Vue {
  @Prop()
  private readonly title!: string

  @Prop({ required: true })
  private readonly question!: ScreenerQuestion;

  @Prop()
  private readonly type?: ScreenerQuestionOptionType

  @Prop()
  private readonly screenerId!: number

  @Prop()
  private readonly pageId!: number

  @Prop({ type: Boolean, default: false })
  private readonly locked!: boolean

  @Prop({ type: Boolean, default: true })
  private readonly allowFiles!: boolean

  @Prop({ type: Boolean, default: true })
  private readonly noFileEdits!: boolean

  private show = false

  private errors: Map<string | number, Partial<OptionFieldErrors>> = new Map()

  items: Partial<ScreenerQuestionOption>[] = []

  private textRules = [
    (value: string) => !!value || 'Field is required'
  ]

  get isMultiResponse () {
    return this.question.type === ScreenerQuestionType.CHECKBOX
  }

  get noFiles () {
    return !this.allowFiles
  }

  get addMoreButtonLabel () {
    if (this.items.length) {
      return 'Add another option'
    } else {
      return 'Add option'
    }
  }

  get optionForms () {
    return this.$refs.optionForm as VuetifyForm[]
  }

  @Watch('items')
  private itemsChanged (value: Partial<ScreenerQuestionOption>[]) {
    value?.forEach((item, index) => {
      if (item.order !== index + 1) {
        this.$set(item, 'order', index + 1)
      }
    })
  }

  open (items: Partial<ScreenerQuestionOption>[]) {
    this.show = true
    this.items = deepClone(items)
  }

  optionErrors (option: Partial<ScreenerQuestionOption>) {
    const identifier = option.uuid || option.id || 0
    return this.errors.get(identifier) || {}
  }

  save () {
    if (this.items.some((item, index) => item.order !== index + 1)) {
      this.items = this.items.map((value, index) => ({ ...value, order: index + 1 }))
    }

    const validOptions = this.optionForms.every(form => form.validate())

    if (validOptions) {
      this.$emit('save', deepClone(this.items))
      this.close()
    }
  }

  close () {
    this.show = false
    this.items = []
  }

  modalVisibilityChanged (value: boolean) {
    if (!value) {
      this.close()
    }
  }

  addItem () {
    const item: Partial<ScreenerQuestionOption> = {
      uuid: uuidv4(),
      text: '',
      order: this.items.length + 1
    }

    if (this.type) {
      item.type = this.type
    }

    if (this.type === ScreenerQuestionOptionType.ROW) {
      const order = (item.order || 1) - 1
      const overflows = Math.floor(order / rowCodes.length)
      const index = order % rowCodes.length
      const prefix = repeatCha('A', overflows)
      item.code = prefix.concat(rowCodes[index])
    }

    this.items.push(item)
  }

  removeOption (item: Partial<ScreenerQuestionOption>) {
    this.items = this.items.filter((option) => option !== item)
  }

  updateText (index: number, text: string) {
    const item = this.items[index]
    this.items.splice(index, 1, { ...item, text })
  }

  updateCode (index: number, code: string) {
    const item = this.items[index]
    this.items.splice(index, 1, { ...item, code })
  }

  addImage (data: { option: ScreenerQuestionOption; file: FileToUpload }) {
    const option = this.items.find(option => option === data.option)
    if (option) {
      this.$set(option, 'image', data.file)
    }
  }

  removeImage (data: { option: ScreenerQuestionOption; file: FileToUpload | SystemFile }) {
    const option = this.items.find(option => option === data.option)
    if (option) {
      this.$set(option, 'image', null)
    }
  }

  answersDragover (event: DragEvent) {
    event.preventDefault()
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = 'copy'
    }
  }

  async answersDropped (event: DragEvent) {
    event.preventDefault()
    if (!event.dataTransfer) {
      return
    }
    const formattedItems = await formatDroppedAnswerList(event.dataTransfer)
    this.addNewItems(formattedItems)
  }

  private answersPasted (text: string) {
    const items = text.split('\n')
    this.addNewItems(items)
  }

  private addNewItems (items: string[]) {
    const prevOptionCount = this.items.length
    const newItems = items.map((itemText, index) => {
      let code, text
      const matches = itemText.match(/(((?<code>[0-9]*?)|[A-Z])(\.| ))?(?<text>.*)$/)
      if (matches) {
        code = matches.groups?.code
        text = matches.groups?.text
      }

      const item: Partial<ScreenerQuestionOption> = {
        uuid: uuidv4(),
        text,
        code,
        order: prevOptionCount + index + 1
      }

      if (this.type) {
        item.type = this.type
      }

      return item
    })
    this.items.push(...newItems)
  }

  updateSR (option: Partial<ScreenerQuestionOption>) {
    this.items.forEach((item, index) => {
      this.$set(this.items, index, { ...item, isSingle: item === option && !option.isSingle })
    })
  }
}
