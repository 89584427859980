

















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import Button from '../../Button/Button.vue'
import BaseModal from '../../BaseModal/BaseModal.vue'
import TextField from '../../TextField/TextField.vue'
import OptionListModal from './OptionListModal/OptionListModal.vue'
import { SliderConfigurationOptions } from '../layout-editor.types'
import { ScreenerQuestionOption, ScreenerQuestion } from '../models'

@Component({
  components: { Button, BaseModal, OptionListModal, TextField }
})
export default class SliderConfigurationOptionsComponent extends Vue {
  @Prop()
  readonly value!: SliderConfigurationOptions

  @Prop({ type: Object, required: true })
  readonly question!: ScreenerQuestion

  @Prop({ type: Boolean, default: false })
  readonly locked!: boolean

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  get modalTitle () {
    return this.locked ? 'View Slider Labels' : 'Edit Slider Labels'
  }

  get optionsModal () {
    return this.$refs.optionsModal as OptionListModal
  }

  get stepCount () {
    if (!this.value.stepCount) {
      this.$emit('input', { ...this.value, stepCount: 10 })
    }

    return this.value.stepCount?.toString()
  }

  set stepCount (value: string) {
    this.$emit('input', { ...this.value, stepCount: +value })
  }

  get hintText () {
    const gapCount = this.question.options.length - 1
    if (gapCount <= 0) {
      return 'It\'s recommended you use a value that divides nicely into the number of gaps you plan to have between the labels'
    }
    return `It's recommended you use a value divisible by ${gapCount} for the labels to display properly`
  }

  openOptionsModal () {
    this.optionsModal.open(this.question.options)
  }

  saveOptions (options: ScreenerQuestionOption[]) {
    this.$emit('input', { ...this.value, options })
  }
}
