





































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import Button from '../../Button/Button.vue'
import BaseModal from '../../BaseModal/BaseModal.vue'
import OptionListModal from './OptionListModal/OptionListModal.vue'
import { ChoiceConfigurationOptions } from '../layout-editor.types'
import { ScreenerQuestion, ScreenerQuestionType, ScreenerQuestionOption, ScreenerQuestionOptionType } from '../models'

@Component({
  components: { Button, BaseModal, OptionListModal }
})
export default class MatrixConfigurationOptionsComponent extends Vue {
  @Prop()
  readonly value!: ChoiceConfigurationOptions

  @Prop({ type: Object, required: true })
  readonly question!: ScreenerQuestion

  @Prop({ type: Boolean, default: false })
  readonly locked!: boolean

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  @Prop({ type: Boolean, default: false })
  readonly loop!: boolean

  @Prop()
  readonly type!: ScreenerQuestionType

  get rowModalTitle () {
    return this.locked ? 'View Matrix Rows' : 'Edit Matrix Rows'
  }

  get columnModalTitle () {
    return this.locked ? 'View Matrix Columns' : 'Edit Matrix Columns'
  }

  get options () {
    return this.value.options
  }

  set options (value: ScreenerQuestionOption[]) {
    this.$emit('input', { ...this.value, options: value })
  }

  get uniqueAnswers () {
    return this.value.uniqueAnswers || false
  }

  set uniqueAnswers (value: boolean) {
    this.$emit('input', { ...this.value, uniqueAnswers: value })
  }

  get isDynamic () {
    return this.type === ScreenerQuestionType.MATRIX_DYNAMIC
  }

  get rowModal () {
    return this.$refs.rowModal as OptionListModal
  }

  get columnModal () {
    return this.$refs.columnModal as OptionListModal
  }

  openRowModal () {
    const rows = this.options.filter(i => i.type === ScreenerQuestionOptionType.ROW)
    this.rowModal.open(rows)
  }

  openColumnModal () {
    const columns = this.options.filter(i => i.type === ScreenerQuestionOptionType.COLUMN)
    this.columnModal.open(columns)
  }

  saveRows (options: ScreenerQuestionOption[]) {
    const columns = this.options.filter(i => i.type === ScreenerQuestionOptionType.COLUMN)
    this.options = columns.concat(...options)
  }

  saveColumns (options: ScreenerQuestionOption[]) {
    const rows = this.options.filter(i => i.type === ScreenerQuestionOptionType.ROW)
    this.options = rows.concat(...options)
  }
}
