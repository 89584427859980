














import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import { Screener, ScreenerPage, ScreenerQuestion, ScreenerQuestionAnswer } from './models'
import { SystemFile } from '../../index.types'
import { deepClone, valueUnchanged } from '../../utilities/helpers'
import ScreenerPageComponent from './ScreenerPageComponent.vue'
import { AnswerManager } from './answer-manager'

interface FileAnswer {
  file: {
    name: string;
    url: string;
  };
}

@Component({
  components: { ScreenerPageComponent }
})
export default class ScreenerUndertakeComponent extends Vue {
  @Prop()
  private readonly screener!: Screener

  @Prop({ type: Boolean, default: false })
  private readonly preview!: boolean

  private visibleQuestionsByPage: Map<number, Set<number>> = new Map()

  private objectURLS: string[] = []

  get allQuestions () {
    if (!this.screener) {
      return []
    }
    const pages = this.screener.pages || []
    return pages.flatMap(page => page.questions)
  }

  get isLastPage () {
    const pages = this.screener?.pages || []
    return this.currentPageIndex + 1 >= pages.length
  }

  get pageActionText () {
    if (this.isLastPage) {
      return 'Complete'
    } else {
      return 'Next'
    }
  }

  currentPageIndex = 0

  get currentPage () {
    const pages = this.screener?.pages
    if (!pages) {
      return null
    }

    return pages[this.currentPageIndex]
  }

  get pageComponent () {
    return this.$refs.pageComponent as ScreenerPageComponent
  }

  @Watch('objectURLS')
  private objectURLSChanged (value: string[], oldValue: string[]) {
    const removed = oldValue.filter(old => value.every(val => val !== old))
    removed.forEach(url => URL.revokeObjectURL(url))
  }

  destroyed () {
    this.objectURLS.forEach(url => URL.revokeObjectURL(url))
  }

  private questionAnswered (question: ScreenerQuestion) {
    const pages = this.screener.pages || []
    const questions = pages[this.currentPageIndex].questions
    this.updateQuestionAnswers(question, pages, questions)
    this.pageComponent.updateVisibility()
  }

  private updateQuestionAnswers (question: ScreenerQuestion, pages: ScreenerPage[], pageQuestions: ScreenerQuestion[]) {
    const index = pageQuestions.findIndex(q => q.id === question.id)
    const page = deepClone(pages[this.currentPageIndex])
    if (!valueUnchanged(page.questions[index].answers, question.answers)) {
      page.questions[index].answers = question.answers
      this.updateDependantQuestions(question, page, pageQuestions)
    }
    this.$set(this.screener.pages || [], this.currentPageIndex, page)
  }

  private updateDependantQuestions (question: ScreenerQuestion, page: ScreenerPage, pageQuestions: ScreenerQuestion[]) {
    const dependantQuestions = pageQuestions.filter(q => q.parentId === question.id)
    dependantQuestions.forEach((q) => {
      const index = page.questions.findIndex(i => i.id === q.id)
      if (q.answers) {
        q.answers.forEach(dependantAnswer => {
          const answer: ScreenerQuestionAnswer | undefined = question.answers?.find(a => a.optionId === dependantAnswer.parentOptionId || a.optionId === dependantAnswer.rowId)
          if (!AnswerManager.validLoopParentAnswer(question, answer)) {
            page.questions[index].answers = q.answers?.filter(i => i !== dependantAnswer)
          }
        })
      }
    })
  }

  private filesUploaded (data: { question: ScreenerQuestion; files: File[] }) {
    if (this.preview) {
      this.fakeFileUpload(data)
    } else {
      this.uploadFiles(data)
    }
  }

  private uploadFiles (data: { question: ScreenerQuestion; files: File[] }) {
    this.$emit('uploadFiles', data)
  }

  private fakeFileUpload (data: { question: ScreenerQuestion; files: File[] }) {
    const questions = this.currentPage?.questions || []
    const index = questions.findIndex(q => q.id === data.question.id)

    const answers: FileAnswer[] = data.files.map(file => {
      const objectURL = URL.createObjectURL(file)
      this.objectURLS = this.objectURLS.concat(objectURL)
      return {
        file: {
          name: file.name,
          url: objectURL
        }
      }
    })

    const oldAnswers = questions[index].answers || []

    this.$set(questions, index, { ...questions[index], answers: [...oldAnswers, ...answers] })
  }

  private fileRemoved (data: { question: ScreenerQuestion; file: SystemFile }) {
    if (this.preview) {
      this.fakeFileRemove(data)
    } else {
      this.removeFile(data)
    }
  }

  private removeFile (data: { question: ScreenerQuestion; file: SystemFile }) {
    this.$emit('removeFile', data)
  }

  private fakeFileRemove (data: { question: ScreenerQuestion; file: SystemFile }) {
    const questions = this.currentPage?.questions || []
    const index = questions.findIndex(q => q.id === data.question.id)
    const answers = questions[index].answers || []
    const newAnswers = answers.filter(i => i.file !== data.file)

    const removedAnswer = answers.find(i => i.file === data.file)
    if (removedAnswer && removedAnswer.file && removedAnswer.file.url) {
      URL.revokeObjectURL(removedAnswer.file.url)
    }

    this.$set(questions, index, { ...questions[index], answers: newAnswers })
  }

  private cancel () {
    this.$emit('onCancel')
  }

  private nextPage (idSet: Set<number>) {
    if (this.currentPage) {
      this.visibleQuestionsByPage.set(this.currentPage.id, idSet)
    }
    if (!this.isLastPage) {
      this.$emit('answer', { pageId: this.currentPage?.id, questions: this.currentPage?.questions })
      this.currentPageIndex++
    } else {
      this.$emit('complete', { pageId: this.currentPage?.id, questions: this.currentPage?.questions })
    }
  }
}
