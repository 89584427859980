









import { Component, Prop } from 'vue-property-decorator'

import ButtonMenu from '../../../../ButtonMenu/ButtonMenu.vue'

import { ScreenerQuestionOption, ScreenerQuestionTermination, TerminationOperator } from '../../../models'

import { TerminationItemError, TerminationItemParamError } from './termination-item.types'
import BaseTerminationItem from './BaseItem.vue'

@Component({
  components: { ButtonMenu }
})
export default class MatrixTerminationItem extends BaseTerminationItem {
  @Prop()
  private readonly value!: ScreenerQuestionTermination

  @Prop({ type: Array, default: () => [] })
  private readonly rows!: ScreenerQuestionOption[]

  @Prop({ type: Array, default: () => [] })
  private readonly columns!: ScreenerQuestionOption[]

  @Prop({ type: Array, default: () => [] })
  private readonly operators!: { text: string; value: TerminationOperator }[]

  @Prop({ type: Object, required: true })
  private readonly error!: TerminationItemError

  @Prop({ type: Boolean, default: false })
  private readonly locked!: boolean

  private get rowItems () {
    return this.rows.map(i => ({ text: i.text, value: i.id || i.uuid }))
  }

  private get columnItems () {
    return this.columns.map(i => ({ text: i.text, value: i.id || i.uuid }))
  }

  private get optionId () {
    return this.value.optionId
  }

  private set optionId (value: string | number | undefined) {
    this.$emit('input', { ...this.value, optionId: value })
  }

  private get operator () {
    return this.value.operator
  }

  private set operator (value: TerminationOperator) {
    this.$emit('input', { ...this.value, operator: value })
  }

  private get rowId () {
    return this.value.rowId
  }

  private set rowId (value: string | number | undefined) {
    this.$emit('input', { ...this.value, rowId: value })
  }

  public validate (): TerminationItemParamError {
    return this.buildParams({
      row: !this.rowId,
      option: !this.optionId,
      operator: !this.operator
    })
  }
}
