

















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import Button from '../../Button/Button.vue'
import BaseModal from '../../BaseModal/BaseModal.vue'
import OptionListModal from './OptionListModal/OptionListModal.vue'
import { ChoiceConfigurationOptions } from '../layout-editor.types'
import { ScreenerQuestionOption, ScreenerQuestion } from '../models'

@Component({
  components: { Button, BaseModal, OptionListModal }
})
export default class SelectConfigurationOptionsComponent extends Vue {
  @Prop()
  readonly value!: ChoiceConfigurationOptions

  @Prop({ type: Object, required: true })
  readonly question!: ScreenerQuestion

  @Prop({ type: Boolean, default: false })
  readonly locked!: boolean

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  @Prop({ type: Boolean, default: false })
  readonly loop!: boolean

  get modalTitle () {
    return this.locked ? 'View Dropdown Options' : 'Edit Dropdown Options'
  }

  get optionsModal () {
    return this.$refs.optionsModal as OptionListModal
  }

  openOptionsModal () {
    this.optionsModal.open(this.value.options)
  }

  saveOptions (options: ScreenerQuestionOption[]) {
    this.$emit('input', { ...this.value, options })
  }
}
