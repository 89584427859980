import { ActionContext, GetterTree } from 'vuex'
import axios from 'axios'

import { ListExportData, StoredListFilter, StoredListSettings, Favourite } from '@focus/components'
import { BaseApiResourceModule, BaseResourceState } from './base.api.utilities'

export interface ListState<T> extends StoredListSettings<T> {
  total: number;
}

export interface ApiResourceState<T> extends BaseResourceState {
  activeItem: T | null;
  listItems: T[];
  listOptions: Partial<ListState<T>>;
}

type ExportQueryParam = {
  value: string;
  pagination: boolean;
}

export type PathParams = any // eslint-disable-line

type PathConstructor = (params?: PathParams) => string

export class ApiResourceModule<T, R> extends BaseApiResourceModule<T, R> {
  private readonly resourcePath: string | PathConstructor

  namespaced = true

  get getters (): GetterTree<ApiResourceState<T>, R> {
    const resourcePath = this.resourcePath
    return {
      entityByIdRoute (_state: ApiResourceState<T>, getters) {
        return (id: number, params?: PathParams): string => {
          return `${getters.entityBaseRoute(params)}/${id}`
        }
      },
      entityBaseRoute () {
        return (params?: PathParams) => {
          if (typeof resourcePath === 'string') {
            return `/${resourcePath}`
          }

          return resourcePath(params)
        }
      },
      activeEntity (state: ApiResourceState<T>) {
        return state.activeItem
      },
      activeListItems (state: ApiResourceState<T>) {
        return state.listItems
      },
      listOptions (state: ApiResourceState<T>) {
        if (!Object.keys(state.listOptions).length) {
          return null
        }
        const settings: StoredListSettings<T> = {
          filters: state.listOptions.filters || [],
          selectedColumns: state.listOptions.selectedColumns || [],
          pagination: state.listOptions.pagination || {},
          name: state.listOptions.name
        }
        return settings
      },
      queryString (state: ApiResourceState<T>) {
        if (!Object.keys(state.listOptions).length) {
          return []
        }
        const parameters: { value: string; pagination: boolean }[] = []

        if (state.listOptions.filters) {
          state.listOptions.filters.forEach((filter) => {
            if (Array.isArray(filter.value)) {
              parameters.push(...filter.value.map(value => ({ value: `${filter.field}[${filter.operator}]=${value}`, pagination: false })))
            } else {
              parameters.push({ value: `${filter.field}[${filter.operator}]=${filter.value}`, pagination: false })
            }
          })
        }

        const count: number = state.listOptions.pagination?.itemsPerPage || 10
        parameters.push({ value: `count=${count}`, pagination: true })
        const page: number = (state.listOptions.pagination?.page || 1) - 1
        parameters.push({ value: `offset=${page * count}`, pagination: true })

        if (state.listOptions.pagination?.sortBy?.length) {
          parameters.push({ value: `orderBy[${state.listOptions?.pagination?.sortDesc?.[0] ? 'DESC' : 'ASC'}]=${state.listOptions.pagination.sortBy[0]}`, pagination: false })
        }
        return parameters
      }
    }
  }

  get mutations () {
    return {
      ...super.mutations,
      setTotal: this.setTotal,
      setActive: this.setActive,
      updateOptions: this.updateOptions,
      updateEntities: this.updateEntities,
      updateActiveItem: this.updateActiveItem
    }
  }

  get actions () {
    return {
      viewSingle: this.viewSingle,
      getSingle: this.getSingle,
      getMany: this.getMany,
      rawGetMany: this.rawGetMany,
      saveFavourite: this.saveFavourite,
      getFavourites: this.getFavourites,
      export: this.export,
      updateListOptions: this.updateListOptions,
      clearList: this.clearList
    }
  }

  get state (): () => ApiResourceState<T> {
    return () => ({
      ...super.state(),
      listOptions: {},
      activeItem: null,
      listItems: []
    })
  }

  constructor (path: string | PathConstructor) {
    super()
    this.resourcePath = path
  }

  private viewSingle = async (context: ActionContext<ApiResourceState<T>, R>, payload: { id: number; params?: PathParams; disableLoading?: boolean }) => {
    await context.dispatch('getSingle', payload)
  }

  private getSingle = async (context: ActionContext<ApiResourceState<T>, R>, payload: { id: number; params?: PathParams; disableLoading?: boolean }) => {
    try {
      if (!payload.disableLoading) {
        context.commit('setActive', null)
      }

      if (!payload.disableLoading) {
        context.commit('setLoading', true)
      }
      const response = await axios.get<T>(context.getters.entityByIdRoute(payload.id, payload.params))
      context.commit('setActive', response.data)
      if (!payload.disableLoading) {
        context.commit('setLoading', false)
      }
      return response.data
    } catch (error) {
      if (!payload.disableLoading) {
        context.commit('setLoading', false)
      }
      throw this.formatApiError(error)
    }
  }

  private getMany = async (context: ActionContext<ApiResourceState<T>, R>, payload: { filters: StoredListFilter<T>[]; params?: PathParams }) => {
    try {
      context.commit('setLoading', true)
      const listParams: ExportQueryParam[] = context.getters.queryString
      const queryParams = [...listParams.map(i => i.value)]
      payload.filters.forEach((filter) => {
        if (Array.isArray(filter.value)) {
          queryParams.push(...filter.value.map(value => `${filter.field}[${filter.operator}]=${value}`))
        } else {
          queryParams.push(`${filter.field}[${filter.operator}]=${filter.value}`)
        }
      })
      const response = await axios.get(`${context.getters.entityBaseRoute(payload.params)}?${queryParams.join('&')}`)
      context.commit('setLoading', false)
      const data = response.data
      context.commit('updateEntities', { entities: data.result })
      context.commit('setTotal', data.total)
    } catch (error) {
      context.commit('setLoading', false)
      throw this.formatApiError(error)
    }
  }

  private rawGetMany = async (context: ActionContext<ApiResourceState<T>, R>, payload: { params?: PathParams; query?: Record<string, string> }) => {
    try {
      context.commit('setLoading', true)
      let path = `${context.getters.entityBaseRoute(payload.params)}`

      if (payload.query) {
        const query = payload.query
        const queryParams = new URLSearchParams()
        Object.keys(query).forEach(key => {
          queryParams.append(key, query[key])
        })
        path += `?${queryParams.toString()}`
      }
      const response = await axios.get(path)
      context.commit('setLoading', false)
      const data = response.data
      return data.result || data
    } catch (error) {
      context.commit('setLoading', false)
      throw this.formatApiError(error)
    }
  }

  private saveFavourite = async (context: ActionContext<ApiResourceState<T>, R>, payload: { favourite: StoredListSettings<T>; params: PathParams }) => {
    try {
      const response = await axios.post<Favourite<T>>(`${context.getters.entityBaseRoute(payload.params)}/favourites`, payload.favourite)
      return response.data
    } catch (error) {
      context.commit('setLoading', false)
      throw this.formatApiError(error)
    }
  }

  private getFavourites = async (context: ActionContext<ApiResourceState<T>, R>, payload: { name: string; params: PathParams }) => {
    try {
      const response = await axios.get<Favourite<T>[]>(`${context.getters.entityBaseRoute(payload.params)}/favourites?resource=${payload.name}`)
      return response.data
    } catch (error) {
      context.commit('setLoading', false)
      throw this.formatApiError(error)
    }
  }

  private clearList = (context: ActionContext<ApiResourceState<T>, R>) => {
    context.commit('updateEntities', { entities: [] })
    context.commit('setTotal', 0)
  }

  private export = async (context: ActionContext<ApiResourceState<T>, R>, payload: { exportDetails: ListExportData; filters: StoredListFilter<T>[]; params?: PathParams }) => {
    const queryParams: string[] = []
    const listParams: ExportQueryParam[] = context.getters.queryString

    if (payload.exportDetails.type === 'all') {
      queryParams.push(...listParams.filter(i => !i.pagination).map(i => i.value))
    } else {
      queryParams.push(...listParams.map(i => i.value))
    }

    payload.filters.forEach((filter) => {
      if (Array.isArray(filter.value)) {
        queryParams.push(...filter.value.map(value => `${filter.field}[${filter.operator}]=${value}`))
      } else {
        queryParams.push(`${filter.field}[${filter.operator}]=${filter.value}`)
      }
    })

    queryParams.push(`exportName=${payload.exportDetails.name}`)
    const anchor = document.createElement('a')
    const baseRoute: string = context.getters.entityBaseRoute(payload.params)
    const basePath = baseRoute.startsWith('/') ? baseRoute.slice(1) : baseRoute
    anchor.href = `${axios.defaults.baseURL}/${basePath}/export?${queryParams.join('&')}`
    anchor.download = payload.exportDetails.name
    anchor.click()
  }

  private updateListOptions = async (context: ActionContext<ApiResourceState<T>, R>, payload: StoredListSettings<T>) => {
    context.commit('updateOptions', payload)
  }

  private setActive = (state: ApiResourceState<T>, payload: T) => {
    state.activeItem = payload
  }

  private updateOptions = (state: ApiResourceState<T>, payload: StoredListSettings<T>) => {
    state.listOptions = { ...state.listOptions, ...payload }
  }

  private setTotal = (state: ApiResourceState<T>, payload: number) => {
    state.listOptions = { ...state.listOptions, total: payload }
  }

  private updateEntities = (state: ApiResourceState<T>, payload: { entities: T[] }) => {
    if (!payload || !payload.entities) {
      return
    }
    state.listItems = payload.entities
  }

  private updateActiveItem (state: ApiResourceState<T>, payload: Partial<T>) {
    state.activeItem = { ...state.activeItem, ...payload } as T
  }
}
