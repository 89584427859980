import Vue from 'vue'
import Vuex, { ActionContext } from 'vuex'
import { ApiError } from '@focus/components'
import { RootState } from './store.types'

import { AuthModule } from '@/domains/auth'
import { ProfileModule } from '@/domains/profile'
import { ScreenerModule } from '@/domains/screeners'
import { GroupsModule } from '@/domains/groups'
import { JobsModule } from '@/domains/jobs'

Vue.use(Vuex)

export default new Vuex.Store<RootState>({
  state: {
    entities: {},
    errors: []
  },
  mutations: {
    addError (state: RootState, payload: ApiError) {
      state.errors = [...state.errors, payload]
    },
    removeError (state: RootState, payload: string) {
      state.errors = state.errors.filter(error => error.id !== payload)
    }
  },
  actions: {
    showError (context: ActionContext<RootState, RootState>, payload: ApiError) {
      context.commit('addError', payload)
      if (payload.timeout > 0) {
        setTimeout(() => {
          context.commit('removeError', payload.id)
        }, payload.timeout)
      }
    },
    showSuccess (context: ActionContext<RootState, RootState>, payload: string) {
      context.commit('setSuccess', { show: true, message: payload })
    }
  },
  modules: {
    auth: AuthModule,
    profile: ProfileModule,
    screeners: ScreenerModule,
    groups: GroupsModule,
    jobs: JobsModule
  }
})

export * from './store.types'
