







import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import CheckboxInput from './InputViews/CheckboxInput.vue'
import MultiTextInput from './InputViews/MultiTextInput.vue'
import SingleTextInput from './InputViews/SingleTextInput.vue'
import SelectInput from './InputViews/SelectInput.vue'
import RadioGroupInput from './InputViews/RadioGroupInput.vue'
import RatingInput from './InputViews/RatingInput.vue'
import RankingInput from './InputViews/RankingInput.vue'
import SliderInput from './InputViews/SliderInput.vue'
import ImagePickerInput from './InputViews/ImagePickerInput.vue'
import FileInput from './InputViews/FileInput.vue'
import MatrixInput from './InputViews/MatrixInput.vue'
import DynamicMatrixInput from './InputViews/DynamicMatrixInput.vue'
import MultiMatrixInput from './InputViews/MultiMatrixInput.vue'
import LoopInput from './InputViews/LoopInput.vue'

import { FormFieldError, SystemFile } from '../../index.types'
import { QuestionComponent, ScreenerBuilderQuestionErrors } from './layout-editor.types'
import {
  ExternalLink,
  FileToUpload,
  ScreenerQuestion,
  ScreenerQuestionAnswer,
  ScreenerQuestionLoopAnswerType,
  ScreenerQuestionOption,
  ScreenerQuestionOptionType,
  ScreenerQuestionType
} from './models'
import { deepClone, fisherYatesShuffle, valueUnchanged } from '../../utilities/helpers'
import { downloadFile } from '../../utilities/file.utilities'
import { AnswerManager } from './answer-manager'
import { stripAnswers } from './layout-editor.utilities'

interface QuestionComponentProps {
  text: string;
  answers?: ScreenerQuestionAnswer[];
  files?: (SystemFile | FileToUpload)[];
  links?: ExternalLink[];
  options?: ScreenerQuestionOption[];
  rows?: ScreenerQuestionOption[];
  columns?: ScreenerQuestionOption[];
  singleSelect?: boolean;
  height?: number;
  width?: number;
  unique?: boolean;
  stepCount?: number;
  parentQuestion?: ScreenerQuestion;
  question?: ScreenerQuestion;
  value?: SystemFile[];
  requiredOptionCount?: number;
  terminationMap?: Map<string, number>;
}

@Component({
  components: { CheckboxInput, DynamicMatrixInput, FileInput, ImagePickerInput, MatrixInput, MultiMatrixInput, MultiTextInput, RadioGroupInput, RatingInput, SingleTextInput, SelectInput, RankingInput, SliderInput }
})
export default class ScreenerQuestionComponent extends Vue {
  @Prop({ type: Object, required: true })
  private readonly question!: ScreenerQuestion

  @Prop({ type: Object, default: () => ({}) })
  private readonly builderError!: ScreenerBuilderQuestionErrors

  @Prop({ type: Array, default: () => [] })
  private readonly questions!: ScreenerQuestion[]

  @Prop({ type: Number, default: 0 })
  public readonly order!: number

  @Prop({ type: Object, default: null })
  private readonly error!: FormFieldError | null

  @Prop({ type: Boolean, default: false })
  private readonly readonly!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly disabled!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly editorView!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly terminatingOverview!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly allowEdit!: boolean

  private get component () {
    switch (this.question.type) {
      case ScreenerQuestionType.TEXTFIELD:
        return SingleTextInput
      case ScreenerQuestionType.TEXTAREA:
        return MultiTextInput
      case ScreenerQuestionType.MATRIX_DYNAMIC:
        return DynamicMatrixInput
      case ScreenerQuestionType.CHECKBOX:
        return CheckboxInput
      case ScreenerQuestionType.RADIO:
        return RadioGroupInput
      case ScreenerQuestionType.SELECT:
        return SelectInput
      case ScreenerQuestionType.RATING:
        return RatingInput
      case ScreenerQuestionType.RANKING:
        return RankingInput
      case ScreenerQuestionType.IMAGE:
        return ImagePickerInput
      case ScreenerQuestionType.IMAGE_SINGLE:
        return ImagePickerInput
      case ScreenerQuestionType.FILE:
        return FileInput
      case ScreenerQuestionType.MATRIX:
        return MatrixInput
      case ScreenerQuestionType.MATRIX_MULTI:
        return MultiMatrixInput
      case ScreenerQuestionType.LOOP:
        return LoopInput
      case ScreenerQuestionType.SLIDER:
        return SliderInput
    }
  }

  private get questionComponent () {
    return this.$refs.component as QuestionComponent
  }

  private options: { initial: ScreenerQuestionOption[]; sorted: ScreenerQuestionOption[] } = { initial: [], sorted: [] }

  private questionProps: QuestionComponentProps = { text: '' }

  private show = true

  @Watch('order')
  private orderUpdated (value: number, oldValue: number) {
    if (!valueUnchanged(value, oldValue)) {
      this.rebuildProps(this.question)
    }
  }

  @Watch('question', { immediate: true, deep: true })
  private questionUpdated (value?: ScreenerQuestion, oldValue?: ScreenerQuestion) {
    if (!valueUnchanged(value?.options, this.options.initial)) {
      this.options.initial = deepClone(value?.options) || []
      this.options.sorted = this.sortOptions(this.options.initial, this.question.randomiseOptions)
    }

    if (!valueUnchanged(value, oldValue)) {
      const nonAnswerNewValue = stripAnswers(value)
      const nonAnswerOldValue = stripAnswers(oldValue)
      const rebuildOnAnswerTypes = [ScreenerQuestionType.CHECKBOX, ScreenerQuestionType.RANKING, ScreenerQuestionType.IMAGE, ScreenerQuestionType.IMAGE_SINGLE]

      const nonAnswerShouldRebuild = !valueUnchanged(nonAnswerNewValue, nonAnswerOldValue)
      const answerShouldRebuild = rebuildOnAnswerTypes.includes(this.question.type)

      if ((nonAnswerShouldRebuild || answerShouldRebuild) && value) {
        this.rebuildProps(value)
      }
    }
  }

  @Watch('questions', { immediate: true, deep: true })
  private questionsUpdated (value?: ScreenerQuestion[], oldValue?: ScreenerQuestion[]) {
    const newQuestions = value?.map(i => stripAnswers(i))
    const oldQuestions = oldValue?.map(i => stripAnswers(i))

    if (this.question.type === ScreenerQuestionType.LOOP && !valueUnchanged(value, oldValue)) {
      this.rebuildProps(this.question, true, value)
    }

    if (!valueUnchanged(newQuestions, oldQuestions)) {
      this.rebuildProps(this.question, true, value)
    }
  }

  @Watch('readonly')
  private readonlyChanged () {
    this.rebuildProps(this.question, true)
  }

  @Watch('editorView')
  private editorViewChanged () {
    this.rebuildProps(this.question, true)
  }

  private rebuildProps (question: ScreenerQuestion, reload = false, questions = this.questions) {
    if (reload) {
      this.show = false
    }
    const props: QuestionComponentProps = {
      text: question.text,
      files: question.downloadFiles,
      links: question.links,
      answers: this.question.answers
    }

    if (this.terminatingOverview) {
      const terminations = this.question.terminations as unknown as Record<string, number>
      const map = new Map<string, number>()
      Object.keys(terminations).forEach((key) => {
        map.set(key, terminations[key])
      })
      props.terminationMap = map
    }

    switch (question.type) {
      case ScreenerQuestionType.RADIO:
      case ScreenerQuestionType.SELECT:
      case ScreenerQuestionType.CHECKBOX:
        props.options = this.options.sorted
        break
      case ScreenerQuestionType.RANKING:
        props.requiredOptionCount = question.requiredOptionCount
        props.options = this.options.sorted
        break
      case ScreenerQuestionType.SLIDER:
        props.stepCount = question.stepCount
        props.options = this.options.sorted
        break
      case ScreenerQuestionType.MATRIX_MULTI:
      case ScreenerQuestionType.MATRIX: {
        const data = this.mapMatrixData(this.options.sorted)
        props.rows = data.rows
        props.columns = data.columns
        break
      }
      case ScreenerQuestionType.IMAGE_SINGLE:
        props.singleSelect = true
      // eslint-disable-next-line no-fallthrough
      case ScreenerQuestionType.IMAGE:
        props.options = question.options
        props.height = question.height
        props.width = question.width
        break
      case ScreenerQuestionType.MATRIX_DYNAMIC: {
        const data = this.mapMatrixData(this.options.sorted)
        props.rows = data.rows
        props.columns = data.columns
        props.unique = question.uniqueAnswers || false
        break
      }
      case ScreenerQuestionType.FILE:
        if (!question.answers) {
          props.value = []
        } else {
          props.value = question.answers.reduce<SystemFile[]>((files, answer) => {
            return answer.file ? files.concat(answer.file) : files
          }, [])
        }
        break
      case ScreenerQuestionType.LOOP:
        props.parentQuestion = deepClone(questions.find(i => i.id === this.question.parentId))
        props.question = question
        break
    }

    this.questionProps = props

    if (reload) {
      this.$nextTick().then(() => {
        this.show = true
      })
    }
  }

  private uploadFiles (files: File[]) {
    this.$emit('upload', files)
  }

  isAnswered () {
    return this.questionComponent.isAnswered()
  }

  private mapMatrixData (data: ScreenerQuestionOption[]) {
    if (!data) {
      return {
        rows: [],
        columns: []
      }
    }

    return {
      rows: data.filter(option => option.type === ScreenerQuestionOptionType.ROW),
      columns: data.filter(option => option.type === ScreenerQuestionOptionType.COLUMN).sort((a, b) => a.order - b.order)
    }
  }

  private sortOptions (options: ScreenerQuestionOption[], randomise?: boolean) {
    if (randomise) {
      const sortableOptions = options.filter(option => !option.isSingle)
      const singleOptions = options.filter(option => option.isSingle)
      return fisherYatesShuffle(sortableOptions).concat(...singleOptions)
    }

    return options.sort((a, b) => a.order - b.order)
  }

  private updateAnswers (data: ScreenerQuestionAnswer[]) {
    if (!valueUnchanged(data, this.question.answers)) {
      this.$emit('answer', data)
    }
  }

  private downloadFile (file: SystemFile | FileToUpload) {
    const path = file.url || this.$store.getters['screeners/getQuestionFilePath']({ screenerId: this.question.screenerId, pageId: this.question.pageId }, file)
    downloadFile(path)
  }

  get parentQuestion () {
    return this.questionProps.parentQuestion
  }

  get parentAnswers () {
    return this.parentQuestion?.answers || []
  }

  public orderUsedCount (): number {
    let count = 1
    if (this.question.type === ScreenerQuestionType.LOOP && this.question.loopAnswerType === ScreenerQuestionLoopAnswerType.QUESTIONS) {
      count = this.parentAnswers.filter(answer => AnswerManager.validLoopParentAnswer(this.parentQuestion, answer)).length || 0
    }
    return count
  }

  lastOrderUsed (): number {
    return this.order + this.orderUsedCount() - 1
  }
}
