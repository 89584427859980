

















import { PersonRole } from '@focus/components'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import GuestConversionButton from './GuestUpgradeButton.vue'

@Component({
  components: { GuestConversionButton }
})
export default class ToolbarComponent extends Vue {
  get isGuest () {
    return this.isLoggedIn && this.$store.state.auth.user?.role === PersonRole.GUEST
  }

  get isConvertingAccount () {
    return this.$route.name === 'guest-conversion'
  }

  get isLoggedIn (): boolean {
    return this.$store.state.auth?.isLoggedIn
  }

  async login () {
    this.$router.push({ name: 'login', query: { ...this.$route.query, redirect: this.$route.path } })
  }
}
