
















































import Vue from 'vue'
import { Prop, Component, Watch } from 'vue-property-decorator'
import { v4 as uuid } from 'uuid'
import { VuetifyMenu } from '../../index.types'

@Component
export default class DateInput extends Vue {
  @Prop()
  readonly value!: string

  @Prop({ default: () => null })
  readonly label!: string

  @Prop()
  readonly placeholder!: string

  @Prop({ type: Boolean, default: false })
  readonly fetching!: boolean

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  @Prop({ type: Number, default: 1 })
  readonly tabIndex!: number

  @Prop({ type: Array, default: () => [] })
  readonly rules!: ((val: string) => string | true)[]

  @Prop({ type: Boolean, default: false })
  private readonly solo!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly hideDetails!: boolean

  open = false

  error = false

  pickerValue: string | null = null

  valueDebounce = 0

  get fieldId () {
    return uuid()
  }

  get menu () {
    return this.$refs.menu as VuetifyMenu<string>
  }

  @Watch('value', { immediate: true })
  valueChanged (value: string) {
    if (!value) {
      this.pickerValue = null
    }
    this.pickerValue = value
  }

  @Watch('pickerValue')
  internalValueChanged (value: string) {
    this.$emit('input', value)
  }

  updateField (value: string) {
    this.error = false
    clearTimeout(this.valueDebounce)
    if (!value) {
      return
    }

    this.valueDebounce = window.setTimeout(() => {
      const formatted = this.formatTime(value)
      if (formatted !== null) {
        this.pickerValue = formatted
        const menu = this.$refs.menu as VuetifyMenu<string>
        menu.save(formatted)
      } else {
        this.error = true
      }
    }, 300)
  }

  formatTime (value: string) {
    const validTime = /^([0-2]?[0-9]:[0-6][0-9])(am|pm)?$/i

    if (validTime.test(value)) {
      let pmTime = /pm/i.test(value)
      const twelveHour = /(am|AM)|(pm|PM)/.test(value.toLowerCase())

      const time = value.replace(/am|pm/i, '')
      const [hours, minutes] = time.split(':').map(p => +p)

      if ((twelveHour && hours > 12) || (!twelveHour && hours > 23)) {
        return null
      }

      if (!twelveHour) {
        return time
      }

      if (hours === 12) {
        pmTime = !pmTime
      }

      const hour = pmTime ? (hours + 12) % 24 : hours
      return `${hour}:${minutes}`
    } else {
      return null
    }
  }
}
