














































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import {
  ScreenerQuestionOptionType,
  ScreenerQuestionType,
  ScreenerQuestionOption,
  ScreenerQuestionParameter,
  ParamOperator,
  ScreenerQuestion
} from '../../models'

import ButtonMenu from '../../../ButtonMenu/ButtonMenu.vue'

type ParamErrors = {
  [key in keyof ScreenerQuestionParameter]: boolean
}

@Component({
  components: { ButtonMenu }
})
export default class PathingParameter extends Vue {
  @Prop({ type: Object, required: true })
  private readonly question!: ScreenerQuestion

  @Prop({ type: Object, required: true })
  private readonly param!: ScreenerQuestionParameter

  @Prop({ type: Boolean, default: false })
  private readonly readonly!: boolean

  private error: ParamErrors = { optionId: false, rowId: false, operator: false, value: false }

  private get isMatrix () {
    return [ScreenerQuestionType.MATRIX_MULTI, ScreenerQuestionType.MATRIX, ScreenerQuestionType.MATRIX_DYNAMIC].includes(this.question?.type as ScreenerQuestionType)
  }

  private get is1DMultiSelect () {
    return [ScreenerQuestionType.CHECKBOX, ScreenerQuestionType.IMAGE].includes(this.question?.type as ScreenerQuestionType)
  }

  private get is1DSingleSelect () {
    return [ScreenerQuestionType.RADIO, ScreenerQuestionType.SELECT, ScreenerQuestionType.IMAGE_SINGLE].includes(this.question?.type as ScreenerQuestionType)
  }

  private get isOptionAndRaw () {
    return [ScreenerQuestionType.RANKING].includes(this.question?.type as ScreenerQuestionType)
  }

  private get isRaw () {
    return [ScreenerQuestionType.TEXTAREA, ScreenerQuestionType.TEXTFIELD, ScreenerQuestionType.RATING].includes(this.question?.type as ScreenerQuestionType)
  }

  private get options () {
    return this.question?.options || []
  }

  private get optionItems () {
    return this.options.slice().sort((a, b) => a.order - b.order).map(o => this.formatOptionItem(o))
  }

  private get rowItems () {
    const rows = this.options.filter(i => i.type === ScreenerQuestionOptionType.ROW)
    return rows.map(o => this.formatOptionItem(o))
  }

  private get columnItems () {
    const columns = this.options.filter(i => i.type === ScreenerQuestionOptionType.COLUMN)
    return columns.map(o => this.formatOptionItem(o))
  }

  private get operators () {
    return [
      { text: 'equal to', value: ParamOperator.EQUAL },
      { text: 'greater than', value: ParamOperator.GREATER_THAN },
      { text: 'greater than or equal to', value: ParamOperator.GREATER_THAN_EQUAL },
      { text: 'less than', value: ParamOperator.LESS_THAN },
      { text: 'less than or equal to', value: ParamOperator.LESS_THAN_EQUAL }
    ]
  }

  private formatOptionItem (item: ScreenerQuestionOption) {
    const text = `${item.code || item.order}.`
    return {
      text,
      value: item.id || item.uuid
    }
  }

  private get selectionOptionItems () {
    return [
      { text: 'selected', value: ParamOperator.EQUAL },
      { text: 'not selected', value: ParamOperator.NOT_EQUAL }
    ]
  }

  private updateItem (data: Partial<ScreenerQuestionParameter>) {
    const updateData = { ...this.param, ...data }
    if (this.question?.type === ScreenerQuestionType.MATRIX_MULTI) {
      updateData.value = 'true'
    }

    const keys = Object.keys(data) as (keyof ScreenerQuestionParameter)[]
    keys.forEach(key => {
      this.error[key] = false
    })
    this.$emit('update:param', updateData)
  }

  validate () {
    this.error = { optionId: false, rowId: false, operator: false, value: false }

    const usesOption = this.isMatrix || this.isOptionAndRaw || this.is1DMultiSelect || this.is1DSingleSelect
    if (usesOption && !this.param.optionId) {
      this.error.optionId = true
    }

    const usesRow = this.isMatrix
    if (usesRow && !this.param.rowId) {
      this.error.rowId = true
    }

    if (!this.param.operator) {
      this.error.operator = true
    }

    const usesValue = this.isOptionAndRaw || this.isRaw
    if (usesValue && !this.param.value) {
      this.error.value = true
    }

    return !this.error.optionId && !this.error.rowId && !this.error.operator && !this.error.value
  }
}
