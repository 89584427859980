













import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import { FormFieldError, SystemFile } from '../../../index.types'
import BaseInput from './BaseInput.vue'
import { getInputName } from '../layout-editor.utilities'
import { AnswerManager } from '../answer-manager'
import { ScreenerQuestionType, ScreenerQuestionAnswer, ScreenerQuestionOption, ScreenerQuestion, ExternalLink } from '../models'
import { ScreenerBuilderQuestionErrors } from '../layout-editor.types'
import { deepClone, valueUnchanged } from '../../../utilities/helpers'
import { overviewTextClass } from './views.constants'

@Component({
  components: { BaseInput }
})
export default class CheckboxInput extends Vue {
  @Prop()
  readonly question!: ScreenerQuestion

  @Prop()
  readonly order!: number

  @Prop()
  readonly text!: string

  @Prop({ type: Array, default: () => [] })
  private readonly files!: SystemFile[]

  @Prop({ type: Array, default: () => [] })
  private readonly links!: ExternalLink[]

  @Prop({ type: Array, default: () => [] })
  readonly options!: ScreenerQuestionOption[]

  @Prop({ type: Array, default: () => [] })
  readonly answers!: ScreenerQuestionAnswer[]

  @Prop({ type: Object, default: null })
  readonly error!: FormFieldError

  @Prop({ type: Object, default: () => ({}) })
  private readonly builderError!: ScreenerBuilderQuestionErrors

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean

  @Prop({ type: Boolean, default: false })
  readonly editorView!: boolean

  @Prop({ type: Boolean, default: false })
  readonly terminatingOverview!: boolean

  @Prop({ type: Map, default: () => new Map() })
  readonly terminationMap!: Map<string, number>

  internalValue = 0

  get hasImages () {
    return this.options.some(o => o.image)
  }

  get stepCount () {
    return this.question.stepCount || 10
  }

  get tickLabels () {
    const tickCount = this.stepCount
    const labels = new Array(tickCount)
    const gapCount = this.options.length - 1
    const spacing = Math.floor(tickCount / gapCount)
    this.options.sort((a, b) => a.order - b.order).forEach((label, index) => {
      labels[index * spacing] = label.text
    })

    return labels
  }

  get overviewTextClass () {
    return overviewTextClass
  }

  get errorMessages () {
    if (!this.error) {
      return []
    }
    return [this.error?.message]
  }

  get inputName () {
    return getInputName(ScreenerQuestionType.SLIDER)
  }

  @Watch('answers', { immediate: true })
  private answersChanged (value: ScreenerQuestionAnswer[]) {
    if (value.length) {
      const answer = value[0]
      this.internalValue = +answer?.value || 0
    }
  }

  @Watch('internalValue', { deep: true })
  private internalValueChanged (value: number) {
    let answers = deepClone(this.answers)

    if (answers.length) {
      answers = [{ ...answers[0], value: value?.toString() }]
    } else {
      answers.push({ value: value?.toString() })
    }

    if (!valueUnchanged(answers, this.answers)) {
      this.$emit('answer', answers)
    }
  }

  isAnswered () {
    return AnswerManager.isSingleSelectAnswered(this.answers)
  }
}
