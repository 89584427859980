import { ScreenerPathingConfigurationItemParameter, ScreenerQuestionAnswer, ScreenerQuestionType } from '../models'
import { BaseDisplayModule } from './modules/base'
import { MatrixDisplayModule } from './modules/matrix'
import { OptionAndRawDisplayModule } from './modules/option-raw'
import { RadioDisplayModule } from './modules/radio'
import { RawDisplayModule } from './modules/raw'

const modules: BaseDisplayModule[] = [
  new MatrixDisplayModule(),
  new OptionAndRawDisplayModule(),
  new RadioDisplayModule(),
  new RawDisplayModule()
]

export class QuestionDisplayModule {
  static show (type: ScreenerQuestionType, param: ScreenerPathingConfigurationItemParameter, answers: ScreenerQuestionAnswer[]) {
    const validModules = modules.filter(m => m.run(type))
    return validModules.every(module => module.valid(type, param, answers))
  }
}
