












import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { DragComponent, DraggableMoveEvent } from '../../../Draggable'
import { ToolboxItem } from '../../layout-editor.types'

@Component({
  components: { DragComponent }
})
export default class NewItemTool extends Vue {
  @Prop()
  readonly item!: ToolboxItem

  @Prop({ type: Boolean, default: false })
  private readonly hasPage!: boolean

  @Prop({ type: Boolean, default: false })
  readonly locked!: boolean

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  checkMove (data: DraggableMoveEvent<ToolboxItem>) {
    return !data.relatedContext.list.includes(data.draggedContext.element) && this.hasPage
  }
}
