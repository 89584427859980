import { ApiError, ErrorDetails } from '@focus/components'
import { AuthState } from '../domains/auth'

export const API_PREFIX = ''

export type EntityCollection = Record<string, unknown>

export type RootState = {
  entities: EntityCollection;
  errors: ApiError[];
  auth?: AuthState;
}

export type FormErrors<T> = {
  [k in keyof T]?: ErrorDetails<T>;
}
