import { ToolboxItem, ToolboxItemGroup } from './layout-editor.types'
import { ScreenerPathingConfigurationItemType, ScreenerQuestion, ScreenerQuestionType } from './models'
import { QuestionDisplayModule } from './QuestionDisplay'
import { AnswerManager } from './answer-manager'

export function getInputDisplayDetails (type: ScreenerQuestionType): { name: string; icon?: string } {
  switch (type) {
    case ScreenerQuestionType.CHECKBOX:
      return { name: 'Checkbox', icon: 'checklist' }
    case ScreenerQuestionType.FILE:
      return { name: 'File', icon: 'attach_file' }
    case ScreenerQuestionType.IMAGE:
      return { name: 'Image Picker (multiple choice)', icon: 'collections' }
    case ScreenerQuestionType.IMAGE_SINGLE:
      return { name: 'Image Picker (single choice)', icon: 'image' }
    case ScreenerQuestionType.MATRIX:
      return { name: 'Matrix (single choice)', icon: 'table_rows' }
    case ScreenerQuestionType.MATRIX_DYNAMIC:
      return { name: 'Matrix (dynamic)', icon: 'table_rows' }
    case ScreenerQuestionType.MATRIX_MULTI:
      return { name: 'Matrix (multiple choice)', icon: 'table_rows' }
    case ScreenerQuestionType.RADIO:
      return { name: 'Radio Group', icon: 'format_list_bulleted' }
    case ScreenerQuestionType.RATING:
      return { name: 'Rating', icon: 'star' }
    case ScreenerQuestionType.SELECT:
      return { name: 'Dropdown', icon: 'reorder' }
    case ScreenerQuestionType.TEXTAREA:
      return { name: 'Textbox', icon: 'notes' }
    case ScreenerQuestionType.TEXTFIELD:
      return { name: 'Textfield', icon: 'notes' }
    case ScreenerQuestionType.LOOP:
      return { name: 'Loop Input', icon: 'cached' }
    case ScreenerQuestionType.RANKING:
      return { name: 'Ranking', icon: 'format_list_numbered' }
    case ScreenerQuestionType.SLIDER:
      return { name: 'Slider', icon: 'linear_scale' }
    default:
      return { name: 'Unknown' }
  }
}

export function getInputName (type: ScreenerQuestionType) {
  return getInputDisplayDetails(type).name
}

export function updateOnPageQuestions (question: ScreenerQuestion, allPageQuestions: ScreenerQuestion[]): ScreenerQuestion[] {
  const answers = question.answers || []
  if (!AnswerManager.isAnswered(question, allPageQuestions)) {
    return []
  }

  const newQuestions: ScreenerQuestion[] = []
  for (const flow of question.pathingFlows) {
    if (flow.params.every(param => QuestionDisplayModule.show(question.type, param, answers))) {
      if (flow.type === ScreenerPathingConfigurationItemType.PAGE) {
        return []
      } else {
        newQuestions.push(...allPageQuestions.filter(q => q.id === flow.targetQuestionId))
      }
    }
  }

  if (!newQuestions.length) {
    const index = allPageQuestions.findIndex(i => i.id === question.id)
    if (index + 1 >= allPageQuestions.length) {
      newQuestions.splice(0, newQuestions.length)
    } else {
      newQuestions.push(allPageQuestions[index + 1])
    }
  }

  return newQuestions
}

export function getDependantQuestions (question: ScreenerQuestion, pageQuestions: ScreenerQuestion[]) {
  return pageQuestions.filter((pageQuestion) => {
    return question.pathingFlows.some(flow => flow.type === ScreenerPathingConfigurationItemType.QUESTION && (flow.targetQuestionId === pageQuestion.id || flow.targetQuestionId === pageQuestion.uuid))
  })
}

export function buildToolboxItem (data: { type: ScreenerQuestionType; isRoot?: boolean }): ToolboxItem {
  const details = getInputDisplayDetails(data.type)
  const item: ToolboxItem = { text: details.name, type: data.type, icon: details.icon, isToolboxItem: true, isGroup: false, model: [] }

  if (data.isRoot) {
    item.model = [item]
  }

  return item
}

export function buildToolboxGroup (data: { name: string; icon?: string; items: { type: ScreenerQuestionType }[] }): ToolboxItemGroup {
  return {
    name: data.name,
    icon: data.icon,
    items: data.items.map(item => buildToolboxItem(item)),
    isActive: false,
    isGroup: true
  }
}

function readItem (item: DataTransferItem): Promise<string> {
  return new Promise((resolve) => {
    item.getAsString(data => resolve(data))
  })
}

export async function formatDroppedAnswerList (dataTransfer: DataTransfer): Promise<string[]> {
  const items = Array.from(dataTransfer.items || [])
  if (items.length > 0) {
    const validItems = items.filter(i => i.kind === 'string' && i.type === 'text/plain')
    const rawItems = await Promise.all(validItems.map((item) => readItem(item)))

    return rawItems.reduce((values: string[], item: string) => {
      if (item.includes('\r\n')) {
        const subItems = item.split('\r\n').filter(str => str !== '')
        return values.concat(...subItems)
      } else if (item.includes('\n')) {
        const subItems = item.split('\n').filter(str => str !== '')
        return values.concat(...subItems)
      }
      return values.concat(item)
    }, [])
  }

  return []
}

export function stripAnswers (question?: ScreenerQuestion): Omit<ScreenerQuestion, 'answers'> | undefined {
  if (!question) {
    return question
  }
  const data = { ...question }
  delete data.answers
  return data
}
