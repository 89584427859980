














import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class SystemSnackbarComponent extends Vue {
  internalValue = false

  @Prop({ type: Boolean, default: false })
  readonly value!: boolean

  @Prop()
  readonly color!: string

  @Prop()
  readonly message!: string

  @Watch('value')
  onInputChange (newValue: boolean) {
    this.internalValue = newValue
  }

  @Watch('internalValue')
  onInternalChange (newValue: boolean) {
    if (this.value !== newValue) {
      this.$emit('input', newValue)
    }
  }

  close () {
    this.$emit('input', false)
  }
}
