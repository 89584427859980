
















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import BaseModal from '../BaseModal/BaseModal.vue'
import Button from '../Button/Button.vue'

@Component({
  components: { Button, BaseModal }
})
export default class Modal extends Vue {
  @Prop({ default: false })
  private readonly value!: boolean

  @Prop({ default: 'white' })
  private readonly color!: string

  @Prop()
  private readonly backgroundColor!: string

  @Prop({ default: null })
  private readonly icon!: string

  @Prop()
  private readonly text!: string

  @Prop()
  private readonly subtext!: string

  @Prop({ default: 'OK' })
  private readonly confirmText!: string

  @Prop({ type: Boolean, default: false })
  private readonly confirmDisabled!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly noCancel!: boolean

  @Prop({ default: 'Cancel' })
  readonly cancelText!: string

  @Prop({ type: Boolean, default: false })
  private readonly whiteText!: boolean
}
