



























import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import { FormFieldError, SystemFile } from '../../../index.types'
import { getInputName } from '../layout-editor.utilities'
import { AnswerManager } from '../answer-manager'
import { ScreenerQuestionType, ScreenerQuestionOption, ScreenerQuestionAnswer, ScreenerQuestion, FileToUpload, ExternalLink } from '../models'
import BaseInput from './BaseInput.vue'
import { ScreenerBuilderQuestionErrors } from '../layout-editor.types'
import { deepClone, valueUnchanged } from '../../../utilities/helpers'
import { overviewTextClass } from './views.constants'
import OptionImageDisplay from '../OptionImageDisplay.vue'

@Component({
  components: { OptionImageDisplay, BaseInput }
})
export default class RadioGroupInput extends Vue {
  @Prop()
  readonly question!: ScreenerQuestion

  @Prop()
  readonly order!: number

  @Prop()
  readonly text!: string

  @Prop({ type: Array, default: () => [] })
  private readonly files!: SystemFile[]

  @Prop({ type: Array, default: () => [] })
  private readonly links!: ExternalLink[]

  @Prop()
  readonly options!: ScreenerQuestionOption[]

  @Prop({ type: Array, default: () => [] })
  readonly answers!: ScreenerQuestionAnswer[]

  @Prop({ type: Object, default: null })
  readonly error!: FormFieldError

  @Prop({ type: Object, default: () => ({}) })
  private readonly builderError!: ScreenerBuilderQuestionErrors

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean

  @Prop({ type: Boolean, default: false })
  readonly editorView!: boolean

  @Prop({ type: Boolean, default: false })
  readonly terminatingOverview!: boolean

  @Prop({ type: Map, default: () => new Map() })
  readonly terminationMap!: Map<string, number>

  get overviewTextClass () {
    return overviewTextClass
  }

  get errorMessages () {
    if (!this.error) {
      return []
    }
    return [this.error?.message]
  }

  get inputName () {
    return getInputName(ScreenerQuestionType.RADIO)
  }

  get hasImages () {
    return this.options.some(o => o.image)
  }

  internalValue: string | null = null

  @Watch('answers', { immediate: true })
  private answersChanged (value: ScreenerQuestionAnswer[]) {
    const answer = value[0]
    this.internalValue = answer ? answer.value : null
  }

  @Watch('internalValue', { deep: true })
  private internalValueChanged (value: string) {
    let answers = deepClone(this.answers)

    if (answers.length) {
      answers = [{ ...answers[0], value }]
    } else {
      answers.push({ value })
    }

    if (!valueUnchanged(answers, this.answers)) {
      this.$emit('answer', answers)
    }
  }

  isAnswered () {
    return AnswerManager.isSingleSelectAnswered(this.answers)
  }

  imageUrl (option: ScreenerQuestionOption) {
    if (!option.image) {
      return null
    }
    const image = option.image as FileToUpload
    if (image.url) {
      return image.url
    }
    return this.$store.getters['screeners/getQuestionFilePath']({ screenerId: this.question.screenerId, pageId: this.question.pageId }, option.image)
  }
}
