






import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

/**
 * CardTitle component used in cards
 */
@Component
export default class CardTitle extends Vue {
  /** Text to render as title */
  @Prop({ default: '' })
  readonly text!: string;

  /** Whether the title should be small */
  @Prop({ type: Boolean, default: false })
  readonly small!: boolean

  /** Uses error format for card title */
  @Prop({ type: Boolean, default: false })
  readonly error!: boolean

  @Prop()
  readonly color!: string
}
