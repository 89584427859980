































import { ScreenerQuestion, ScreenerPathingConfigurationItemType, ScreenerPathingConfigurationItem, ScreenerPathingConfigurationItemParameter } from '../../models'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import BaseModal from '../../../BaseModal/BaseModal.vue'
import Button from '../../../Button/Button.vue'
import PathingConfigurationItem from './PathingConfigurationItem.vue'
import { deepClone } from '../../../../utilities/helpers'

type ScreenerPathingConfigurationItemData = Omit<ScreenerPathingConfigurationItem, 'params'> & { params: Partial<ScreenerPathingConfigurationItemParameter>[] }
type ScreenerQuestionData = Omit<ScreenerQuestion, 'pathingFlows'> & { pathingFlows: ScreenerPathingConfigurationItemData[] }

@Component({
  components: { BaseModal, Button, PathingConfigurationItem }
})
export default class PathingConfigurationModal extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly locked!: boolean

  private question: ScreenerQuestionData | null = null

  private pageQuestions: ScreenerQuestion[] = []

  private show = false

  private get title () {
    return `Pathing Configuration: Q${this.question?.order || 0}`
  }

  private get configItems () {
    return this.question?.pathingFlows || []
  }

  private get configItemComponents () {
    return this.$refs.configItemComponents as PathingConfigurationItem[]
  }

  public open (data: { question: ScreenerQuestion; pageQuestions: ScreenerQuestion[] }) {
    this.question = deepClone(data.question)
    this.pageQuestions = data.pageQuestions
    this.show = true
  }

  public close () {
    this.question = null
    this.pageQuestions = []
    this.show = false
  }

  public save () {
    if (this.validate()) {
      this.$emit('save', this.question?.pathingFlows)
      this.close()
    }
  }

  private addItem () {
    if (!this.question) {
      return
    }

    let itemType = ScreenerPathingConfigurationItemType.QUESTION
    const largestOrder = Math.max(...this.pageQuestions.map(q => q.order))
    if (this.question.order >= largestOrder) {
      itemType = ScreenerPathingConfigurationItemType.PAGE
    }

    const item = {
      type: itemType,
      params: []
    }

    this.$set(this.question, 'pathingFlows', [...this.question.pathingFlows, item])
  }

  private addItemParam (item: ScreenerPathingConfigurationItemData) {
    if (!this.question || !this.question.pathingFlows) {
      return
    }

    const itemIndex = this.question.pathingFlows.findIndex(i => i === item)
    this.question.pathingFlows[itemIndex].params.push({})
  }

  private removeItem (item: ScreenerPathingConfigurationItemData, index: number) {
    if (!this.question || !this.question.pathingFlows) {
      return
    }
    this.question.pathingFlows.splice(index, 1)
  }

  private updateItem (item: ScreenerPathingConfigurationItemData, index: number) {
    if (!this.question || !this.question.pathingFlows) {
      return
    }

    this.question.pathingFlows.splice(index, 1, item)
    this.$nextTick().then(() => this.validate())
  }

  private modalVisibilityUpdated (value: boolean) {
    if (!value && this.question) {
      this.close()
    }
  }

  public validate () {
    const validChecks = this.configItemComponents.map(component => component.validate())
    return validChecks.every(check => check === true)
  }
}
